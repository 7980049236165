import "./featured.scss"
import React, { useEffect, useState } from 'react';
import dataService from "../../redux/services/data.service";
import moment from "moment";
import { toast, ToastContainer } from 'react-toastify';
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { logout } from '../../redux/features/authSlice';
import { Oval } from "react-loader-spinner";

const Featured = () => {
  const { REACT_APP_ENV } = process.env;

  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
  const inTime = rtm_user && rtm_user.inTime

  const [currentDate, setCurrentDate] = useState("")
  const [currentTime, setCurrentTime] = useState("")
  const [userId, setUserId] = useState("")
  const [attendenceId, setAttendenceId] = useState("")

  const [clockOutBtn, setClockOutBtn] = useState(false)
  const [cancelBtn, setCancelBtn] = useState(false)
  const [totalTime, setTotalTime] = useState("")
  const [currenUserStatus, setUserCurrentStatus] = useState("punchin")
  const [userAttendanceData, setUserAttendance] = useState({})

  const [showLoader, setShowLoader] = useState(false)
  const [firstLoggedInTime, setFirstLoggedInTime] = useState("")

  const getCurrentDate = () => {
    var date = new Date().toDateString();
    setCurrentDate(date);
  };

  const getCurrentTime = () => {
    var time = new Date().toLocaleTimeString('en-US', { hour12: true });
    setCurrentTime(time)
  };

  const userAttendenceDetail = async () => {
    setShowLoader(true)
    const response = await dataService.attandance_list_for_dashboard()
      .catch(error => {
        setShowLoader(false)
        return error;
      });
    response && setShowLoader(false);
    const data = response && response.data && response.data.data
    var date = moment().format('YYYY-MM-DD')
    const currentDateArray = [];

    const item = data && data.rows && data.rows[0]
    if (item.is_punchout_enable === true) {
      const date = new Date(item.punchin_date + " " + item.punchin_time)
      const moonLanding = new Date(date);
      localStorage.setItem(`${REACT_APP_ENV}_rtms`, JSON.stringify({
        'token': rtm_user.token,
        'user': rtm_user.user,
        'lastInteractionTime': rtm_user.lastInteractionTime,
        'userAttendance': item,
        'currenStatus': item.current_status === null ? "punchin" : item.current_status,
        'inTime': moonLanding.getTime()
      }))
    }

    if (item.punchin_date === date) {
      currentDateArray.push(item);
    }
    // })

    if (currentDateArray.length > 0) {
      setFirstLoggedInTime(currentDateArray[0].punchin_time)
    }

  }

  useEffect(() => {
    userAttendenceDetail();

    getCurrentDate();
    getCurrentTime();
    setInterval(getCurrentTime, 1000)
    if (rtm_user && rtm_user.inTime) {
      setClockOutBtn(true);
      CalculateTiming()
      // setInterval(CalculateTiming, 1000)
      viewAttendanceDetails()
    } else { setClockOutBtn(false) }
  }, [rtm_user.inTime])

  useEffect(() => {
    if (rtm_user && rtm_user.inTime) {
      setInterval(CalculateTiming, 1000)
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem(`${REACT_APP_ENV}_rtms`)) {
      rtm_user.user && rtm_user.user.data && setUserId(rtm_user.user.data.id)
      if (rtm_user && rtm_user.userAttendance) {
        const userData = rtm_user.userAttendance
        userData && setUserAttendance(userData)
        userData && setUserCurrentStatus(rtm_user.currenStatus);
        // console.log(rtm_user.currenStatus, "userStatus")
      }
    }
    
  }, [rtm_user.currenStatus])

  const punchIn = async () => {
    setShowLoader(true);

    const request = {
      created_by: userId,
      resource: userId,
      button_type: "punchin"
    }

    const response = await dataService.punchInOut(request);

    if (response && response.data && response.data.status === 200) {
      setShowLoader(false);
      const data = response.data.data

      setClockOutBtn(true)
      setCancelBtn(false)
      localStorage.setItem(`${REACT_APP_ENV}_rtms`, JSON.stringify({
        'token': rtm_user.token,
        'user': rtm_user.user,
        'lastInteractionTime': rtm_user.lastInteractionTime,
        'userAttendance': data,
        'currenStatus': "punchin",
        'inTime': new Date().getTime()
      }))

      CalculateTiming()
      // window.location.reload()
    }
    if (response && response.data && response.data.status === 400) {
      setShowLoader(false);
      toast.error(response.data.message)
    }

  }

  const dispatch = useDispatch();

  const logout_user = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const punchOut = async () => {
    setClockOutBtn(false)
    setShowLoader(true);

    const request = {
      resource: userId,
      button_type: "punch_out",
      created_by: userId,
      resource_attendance_id: attendenceId,
    }
    const response = await dataService.punchInOut(request);
    if (response && response.data && response.data.status === 200) {
      localStorage.setItem(`${REACT_APP_ENV}_rtms`, JSON.stringify({
        'token': rtm_user.token,
        'user': rtm_user.user,
        'lastInteractionTime': rtm_user.lastInteractionTime,
      }))
      logout_user();
      setCancelBtn(true)
      setShowLoader(false);
      window.location.reload();
    }
    if (response && response.data && response.data.status === 400) {
      setShowLoader(false);
      toast.error(response.data.message)
    }
  }

  const onBreak = async () => {
    setShowLoader(true)

    if (currenUserStatus === "punchin" || currenUserStatus === "on_seat") {

      const request = {
        resource: userId,
        button_type: "break",
        created_by: userId,
        resource_attendance_id: attendenceId,
      }

      const response = await dataService.punchInOut(request);
      if (response && response.data && response.data.status === 200) {
        setShowLoader(false)
        setCancelBtn(false)

        localStorage.setItem(`${REACT_APP_ENV}_rtms`, JSON.stringify({
          'token': rtm_user.token,
          'user': rtm_user.user,
          'lastInteractionTime': rtm_user.lastInteractionTime,
          'userAttendance': userAttendanceData,
          'currenStatus': "break",
          'inTime': inTime
        }))
        // window.location.reload()
      }
      if (response && response.data && response.data.status === 400) {
        setShowLoader(false)
        toast.error(response.data.message)
      }
    }
  }

  const onBackToWork = async () => {
    setShowLoader(true)

    if (currenUserStatus === "break") {
      const request = {
        resource: userId,
        button_type: "on_seat",
        created_by: userId,
        resource_attendance_id: attendenceId,
      }
      const response = await dataService.punchInOut(request);
      if (response && response.data && response.data.status === 200) {
        setShowLoader(false)

        setCancelBtn(false)
        localStorage.setItem(`${REACT_APP_ENV}_rtms`, JSON.stringify({
          'token': rtm_user.token,
          'user': rtm_user.user,
          'lastInteractionTime': rtm_user.lastInteractionTime,
          'userAttendance': userAttendanceData,
          'currenStatus': "on_seat",
          'inTime': inTime
        }))

        // window.location.reload()
      }
      if (response && response.data && response.data.status === 400) {
        setShowLoader(false)
        toast.error(response.data.message)
      }
    }
  }

  function CalculateTiming() {
    const startTime = rtm_user.inTime
    const endTime = new Date().getTime()
    const diffTime = (endTime - startTime)
    const hours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const min = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
    setTotalTime(hours + "h :" + min + "m")
  }

  const onCancel = () => {
    setCancelBtn(false)
    setClockOutBtn(true)
  }

  const viewAttendanceDetails = () => {
    const attendenceDetails = rtm_user && rtm_user.userAttendance
    attendenceDetails && setAttendenceId(attendenceDetails.id)
  }

  return (
    <div className='featured' style={{ position: "relative" }}>

      {
        //loader 
        showLoader && <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '99999' }}>
          <Oval
            height={70}
            width={70}
            color="#fff"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="rgb(243 237 237 / 35%)"
            strokeWidth={2}
            strokeWidthSecondary={3}
          />
        </div>
      }

      <div className="top">
        <ToastContainer />

        <div className="attendence-col">
          <div className="attendence-col-1">
            <p>{currentDate}</p>
            <h4>{currentTime}</h4>
          </div>
          <div className="attendence-col-2">
            {
              firstLoggedInTime ?
                <p style={{ marginBottom: "10px" }}>First LoggedIn Time : {firstLoggedInTime}</p>
                : ""
            }

            {
              !clockOutBtn &&
              < button className="btn btn-clock-in" onClick={punchIn}>Punch In</button>
            }
            {
              !cancelBtn && clockOutBtn && (currenUserStatus === "punchin" || currenUserStatus === "on_seat") &&
              <>
                <button className="btn btn-clock-out" onClick={() => setCancelBtn(true)}>Punch Out</button> &nbsp;
                <button className="btn btn-clock-break" onClick={onBreak}>Break</button>
                <span className="total-time">{totalTime === NaN + "h :" + NaN + "m" ? "" : totalTime}</span>
              </>
            }
            {
              clockOutBtn && cancelBtn &&
              <>
                <button className="btn btn-clock-out" onClick={punchOut}>Out</button>&nbsp;
                <button className="btn btn-clock-in" onClick={onCancel}>Cancel</button>
                <span className="total-time">{totalTime === NaN + "h :" + NaN + "m" ? "" : totalTime}</span>
              </>
            }
            {
              !cancelBtn && clockOutBtn && currenUserStatus === "break" &&
              <>
                <button className="btn btn-clock-out" onClick={() => setCancelBtn(true)}>Punch Out</button>&nbsp;
                <button className="btn btn-clock-break" onClick={onBackToWork}>Back to work</button>
                <span className="total-time">{totalTime === NaN + "h :" + NaN + "m" ? "" : totalTime}</span>
              </>
            }

          </div>
        </div>
      </div>
    </div>
  )
}

export default Featured
