import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";


export const countryList = createAsyncThunk("country/list", async () => {
        const res = await DataService.country_list();
        return res.data;
    }
);


const countrySlice = createSlice({
    name: "country_list",
    initialState: {
        data: [],
        status: 'idle',
        error:''
    },
    reducers : {},
    extraReducers(builder) {
        builder
            .addCase(countryList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(countryList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.data = action.payload;
            })
            .addCase(countryList.rejected, (state, action)=>{
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

    },
});


export default countrySlice.reducer;