import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";
// import moment from "moment";
// const { REACT_APP_ENV } = process.env;

export const holidayList = createAsyncThunk(
    "get/holidayList",
    async (params) => {
        const res = await DataService.holiday_list(params);
        const resp = res.data;
        // console.log(resp)
        return resp;
    }
);

export const saveHoliday = createAsyncThunk(
    "save/holiday",
    async (data) => {
        const res = await DataService.save_holiday(data);
        const resp = res.data;
        // console.log(resp)
        return resp;
    }
);

export const deleteHoliday = createAsyncThunk(
    "delete/holiday",
    async (id) => {
        const res = await DataService.delete_holiday(id);
        const resp = res.data;
        console.log(resp)
        return resp;
    }
);

// HOLIDAY CALENDER LIST
export const holidayCalenderList = createAsyncThunk(
    "get/holidayCalenderList",
    async (params) => {
        const res = await DataService.holiday_calender_list(params)
        const resp = res.data;
        // console.log(resp)
        return resp;
    }
);

export const saveHolidayDate = createAsyncThunk(
    "save/holidayDate",
    async (data) => {
        const res = await DataService.save_holiday_date(data);
        const resp = res.data;
        // console.log(resp)
        return resp;
    }
);

export const deleteHolidayDate = createAsyncThunk(
    "delete/holidayDate",
    async (id) => {
        const res = await DataService.delete_holiday_date(id);
        const resp = res.data;
        console.log(resp)
        return resp;
    }
);

const holidaySlice = createSlice({
    name: "holiday",
    initialState: {
        holidays: [],
        holidaysCalender:[],
        apiResponse:{},
        count:0,
        row_count:0,
        status: STATUSES.IDLE,
        error: '',
    },
    reducers: {},
    extraReducers(builder) {
        builder
        // GET HOLIDAY LIST
            .addCase(holidayList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                // Extra
                // state.error = this.initialState.error;
                // state.holidays = this.initialState.holidays;
                // state.count = this.initialState.count;
            })
            .addCase(holidayList.fulfilled, (state, action) => {
                // console.log(action.payload.data.count);
                state.status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.error = !action.payload.data ? false : true ;
                state.holidays = !action.payload.data.rows ? [] : action.payload.data.rows;
                state.count = action.payload.data.count;
            })
            .addCase(holidayList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
                // Extra
                // state.holidays = this.initialState.holidays;
                // state.count = this.initialState.count;
            })

        // SAVE HOLIDAY
        .addCase(saveHoliday.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(saveHoliday.fulfilled, (state, action) => {
            // console.log(action.payload.data.count);
            state.status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
            state.error = !action.payload.data ? false : true ;
            console.log(action.payload)
            state.apiResponse = !action.payload ? {} : action.payload;
        })
        .addCase(saveHoliday.rejected, (state, action) => {
            state.status = STATUSES.FAILURE;
            state.error = action.error
        })

        // DELETE HOLIDAY
        .addCase(deleteHoliday.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(deleteHoliday.fulfilled, (state, action) => {
            console.log(action.payload.data.count);
            state.status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
            state.error = !action.payload.data ? false : true ;
            state.apiResponse = !action.payload.data? {} : action.payload.data;
            state.count = action.payload.data.count;
        })
        .addCase(deleteHoliday.rejected, (state, action) => {
            state.status = STATUSES.FAILURE;
            state.error = action.error
        })


        //  //////////////////////////////// 
         // GET HOLIDAY CALENDER LIST
         .addCase(holidayCalenderList.pending, (state, action) => {
            state.status = STATUSES.LOADING;
            // Extra
            // state.error = this.initialState.error;
            // state.holidays = this.initialState.holidays;
            // state.count = this.initialState.count;
        })
        .addCase(holidayCalenderList.fulfilled, (state, action) => {
            // console.log(action.payload.data.count);
            state.status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
            state.error = !action.payload.data ? false : true ;
            state.holidaysCalender = !action.payload.data.rows ? [] : action.payload.data.rows.map(e=>({...e ,id:e.id, date:e.holiday_date}));
            state.row_count = action.payload.data.count;
        })
        .addCase(holidayCalenderList.rejected, (state, action) => {
            state.status = STATUSES.FAILURE;
            state.error = action.error
            // Extra
            // state.holidays = this.initialState.holidays;
            // state.count = this.initialState.count;
        })

        // SAVE HOLIDAY DATE
        .addCase(saveHolidayDate.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(saveHolidayDate.fulfilled, (state, action) => {
            // console.log(action.payload.data.count);
            state.status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
            state.error = !action.payload.data ? false : true ;
            console.log(action.payload)
            state.apiResponse = !action.payload ? {} : action.payload;
        })
        .addCase(saveHolidayDate.rejected, (state, action) => {
            state.status = STATUSES.FAILURE;
            state.error = action.error
        })

        // DELETE HOLIDAY DATE
        .addCase(deleteHolidayDate.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(deleteHolidayDate.fulfilled, (state, action) => {
            console.log(action.payload.data.count);
            state.status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
            state.error = !action.payload.data ? false : true ;
            state.apiResponse = !action.payload.data? {} : action.payload.data;
            state.count = action.payload.data.count;
        })
        .addCase(deleteHolidayDate.rejected, (state, action) => {
            state.status = STATUSES.FAILURE;
            state.error = action.error
        })
    },
});


export default holidaySlice.reducer;