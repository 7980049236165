import "./widget.scss";
import PersonOutlineOutlined from "@mui/icons-material/PersonOutlineOutlined";
import { Link } from "react-router-dom";
import Folder from "@mui/icons-material/Folder";
import CardMembership from "@mui/icons-material/CardMembership";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";

const Widget = ({ type, count }) => {
    // console.log(count)
    let data;

    switch (type) {
        case "resources":
            data = {
                title: "RESOURCES",
                isMoney: false,
                link: "See all resources",
                url: "all-resource",
                icon: (
                    <PersonOutlineOutlined
                        className="icon"
                        style={{
                            color: "crimson",
                            backgroundColor: " rgba(218,165,32,0.2)",
                        }}
                    />
                ),
            };
            break;
        case "projects":
            data = {
                title: "PROJECTS",
                isMoney: false,
                link: "View all projects",
                url: "all-projects",
                icon: (
                    <Folder className="icon"
                        style={{
                            color: "goldenrod",
                            backgroundColor: " rgba(218,165,32,0.2)",
                        }}
                    />
                ),
            };
            break;
        case "shifts":
            data = {
                title: "SHIFTS",
                isMoney: true,
                link: "View all shifts",
                url: "all-shift",
                icon: (
                    <CardMembership className="icon"
                        style={{
                            color: "green",
                            backgroundColor: " rgba(0,128,0,0.2)",
                        }}
                    />
                ),
            };
            break;
        case "reports":
            data = {
                title: "REPORTS",
                isMoney: true,
                link: "See all reports",
                url: "attendance-report",
                icon: (
                    <PictureAsPdf className="icon"
                        style={{
                            color: "purple",
                            backgroundColor: " rgba(128,0,128,0.2)",
                        }}
                    />
                ),
            };
            break;
            case "tasks":
                data = {
                    title: "TASKS",
                    isMoney: true,
                    link: "See all task",
                    url: "my-task",
                    icon: (
                        <PictureAsPdf className="icon"
                            style={{
                                color: "purple",
                                backgroundColor: " rgba(128,0,128,0.2)",
                            }}
                        />
                    ),
                };
                break;
                case "myProjects":
                    data = {
                        title: "PROJECTS",
                        isMoney: false,
                        link: "View all projects",
                        url: "my-projects",
                        icon: (
                            <Folder className="icon"
                                style={{
                                    color: "goldenrod",
                                    backgroundColor: " rgba(218,165,32,0.2)",
                                }}
                            />
                        ),
                    };
                    break;
        default:
            break;
    }

    return (
        <div className="widget">
            <div className="left">
                <span className="title">{data.title}</span>
                <span className="counter">{data.isMoney} {count}</span>
                <span className="link"><Link to={'/' + data.url}>{data.link}</Link></span>
            </div>
            <div className="right">
                <div className="percentage positive">
                    {/* <KeyboardArrowUp /> */}
                    {/* {diff} % */}
                </div>
                {data.icon}
            </div>

        </div>
    );

}

export default Widget
