import { Breadcrumbs, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const TaskBreadCrumb = ({ formValues }) => {
    const navigate = useNavigate();
    return (
        <Breadcrumbs maxItems={4} aria-label="breadcrumb" style={{ textTransform: "uppercase" }}>
            <Typography >Projects</Typography>

            {formValues.project && formValues.project.project_name ?
                <Typography style={{ cursor: "pointer" }} onClick={() => { navigate("/view-project", { state: { id: formValues.project.id } }) }} >
                    <img src={"https://unicodesystems.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/10406?size=medium"} style={{ width: "1rem", paddingRight: "8px" }} />
                    {formValues.project.project_name}</Typography>
                : ''
            }
            {formValues.epic_details && formValues.epic_details.rtms_key ?
                <Typography style={{ cursor: "pointer" }} onClick={() => { navigate(`/epic-task/${formValues.epic_details.id}`) }} >
                    <img src={"https://unicodesystems.atlassian.net/images/icons/issuetypes/epic.svg"} style={{ width: "1rem", paddingRight: "8px" }} />
                    {formValues.epic_details.rtms_key}</Typography>
                : ''
            }

            {formValues.ticket_key
                ? <Typography color="text.primary">
                    <img src={formValues.issue_type.icon_url} style={{ width: "1rem", paddingRight: "3px" }} /> {formValues.ticket_key}
                </Typography>
                : formValues.ticket_jira_key
                    ? <Typography color="text.primary">{formValues.ticket_jira_key}</Typography> : ''
            }

        </Breadcrumbs>
    )
}

export default TaskBreadCrumb