import React, { useState, useEffect } from 'react'
import "./manageshift.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { shiftColumns } from "../../datatablesource";
import { Link, useNavigate } from "react-router-dom";
import api from '../../redux/APIS/api';
import DynamicTable from '../../components/dynamic-table/DynamicTable';
import { toast, ToastContainer } from 'react-toastify';
import { Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
// import LoaderForm from '../../components/common-function/LoaderForm';

const AllShifts = () => {

  const navigate = useNavigate()
  const [shiftRows, setShiftRows] = useState([])

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({})
  const [fieldName, setFieldName] = useState("")
  const [defaultValue, setDefaultValue] = useState({})

  // Load All Shifts

  const loadShifts = async () => {
    setLoading(true);
    const data = { ...params, "skip": skip, "limit": pageSize || 10 }
    const res = await api.get(`/mst/shift?where=${JSON.stringify(data)}`)
    if (res && res.data && res.data.data) {
      setShiftRows(res.data.data.rows.map(e => ({ ...e, status_value: e.status ? "True" : "False" })))
      setRowCount(res.data.data.count)
    }
    setLoading(false);
  }

  // Delete Shift
  const deleteShift = async (id) => {
    const res = await api.delete(`/mst/shift/${id}`)
    if (res && res.data && res.data.data && res.status === 200) {
      toast.success(res.data.message)
      setTimeout(() => {
        loadShifts();
      }, 3000);
    }
  }

  // Edit Shift
  const editShift = (data) => {
    navigate('/add-new-shift', { state: data })
  }

  // View Shift
  const viewShift = (data) => {
    navigate('/view-shift', { state: data })
  }

  useEffect(() => {
    loadShifts();
  }, [skip, pageSize, params])

  return (
    <div className="new">
      <Sidebar />

      <div className="newContainer">
        <Navbar />

        <div className="top-headings">
          <h3>All Shifts</h3>
          
          <Tooltip title="Add New Shift ">
            <Link to="/add-new-shift" className="btn-add-new ">
              <AddIcon />
            </Link>
            </Tooltip>
        </div>
        <ToastContainer />
        {
          // loading ? <LoaderForm /> :
          <>
            {
              <DynamicTable
                moduleName="allShifts"
                tableColumns={shiftColumns}
                tableRows={shiftRows || []}
                currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
                setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
                paginationMode={"server"}
                rowCount={rowCount}
                loading={loading}
                deleteRow={deleteShift}
                onViewDetail={viewShift}
                onEdit={editShift}
                editLink={"/add-new-shift"}
                columnVisibilityModel={{
                  id: false,
                }}
                setFieldName={setFieldName} setDefaultValue={setDefaultValue}
              />
            }
          </>
        }
      </div>
    </div>
  )
}

export default AllShifts