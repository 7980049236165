import React, { useEffect, useState } from "react";
import "./roles.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import dataService from "../../redux/services/data.service";
//import { getRole } from "../../redux/features/roleSlice";
import { ToastContainer, toast } from 'react-toastify';
import LoaderForm from "../../components/common-function/LoaderForm";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function AddRole({ inputs, title }) {

    const { id } = useParams()
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showloader, setShowLoader] = useState(true)

    const [formData, setFormData] = useState({
        role_name: "",
        role_key: "",
        role_description: "",
        status: ""
    })
    const [formErrors, setFormErrors] = useState('');

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setFormData({ ...formData, [name]: value });
    }

    const loadDataById = async () => {
        const response = await dataService.roleById(id)
        response && response.data && setFormData(response.data.data) && setShowLoader(false)
    }

    useEffect(() => {
        id && loadDataById()
    }, [id])

    useEffect(() => {
        setTimeout(() => {
            setShowLoader(false)
        }, 800)
    }, [])

    const onSubmit = async (e) => {
        e.preventDefault();
        const request = { ...formData, created_by: LocalStorageDataService.userDetail().id, };

        if (!formData.role_name) {
            return setFormErrors({ 'nameErr': 'Role name is Required' })
        } else if (!formData.role_key) {
            return setFormErrors({ 'keyErr': 'Role key is Required' })
        } else if (!formData.role_description) {
            return setFormErrors({ 'descErr': 'Description is Required' })
        } else if (formData.status === "") {
            return setFormErrors({ 'statusErr': 'Status is Required' })
        }

        const response = !id ?
            await dataService.addRole(request)
                .catch(error => {
                    const res = error.response.data
                    console.log(res.role_key, "res")
                    setFormErrors({
                        "nameErr": res.role_name,
                        "keyErr": res.role_key,
                        "descErr": res.role_description,
                        "statusErr": res.status,
                    })
                    return error;
                })
            : await dataService.updateRole(id, request);

        if (response && response.data && response.data.data) {
            console.log(response.data)
            toast.success(response.data.message);
            setTimeout(() => {
                // dispatch(getRole('{"skip":0,"limit":10}'))
                navigate('/roles')
            }, 800)
        }
        if (response && response.data.status === 400) {
            const res = response.data.message
            toast.error(res)
            setFormErrors({
                "nameErr": res.role_name,
                "keyErr": res.role_key,
                "descErr": res.role_description,
                "statusErr": res.status,
            })
        }
    }

    return (
        <div className="new">
            <Sidebar />

            <div className="newContainer">
                <Navbar />

                <div className="top-headings">
                    <h3>{title} </h3>
                    <Tooltip title="Back">
                    <Link to={"/roles"} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>
                   
                </div>

                {
                    showloader ? <LoaderForm />
                        : <form onSubmit={onSubmit}>

                            <ToastContainer />
                            <div className="add-new-user-form">

                                {inputs.map((input) => (
                                    <div className="formInput" key={input.id}>
                                        <label>{input.label}</label>
                                        <input
                                            name={input.name}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            value={formData[input.name]}
                                            onChange={(e) => handleChange(e)}
                                        />

                                        <div className="errorMessage">{
                                            formErrors && input.name === "role_name" ?
                                                <p>{formData.role_name === "" ? formErrors.nameErr : ""}</p>
                                                : input.name === "role_key" ?
                                                    <p>{formData.role_key === "" ? formErrors.keyErr : ""}</p>
                                                    : input.name === "role_description" ?
                                                        <p>{formData.role_description === "" ? formErrors.descErr : ""}</p>
                                                        : ""
                                        }
                                        </div>
                                    </div>

                                ))}


                                <div className="formInput">
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="status"
                                            value={formData.status}
                                            onChange={(e) => { handleChange(e) }}
                                        >
                                            <FormControlLabel value={"true"} control={<Radio />} label="Active" />
                                            <FormControlLabel value={"false"} control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                        {formErrors && formErrors.statusErr && <p style={{ color: "#e33417", fontSize: 14 }}>{formData.status === "" ? formErrors.statusErr : ""}</p>}

                                    </FormControl>
                                </div>

                            </div>
                            <button className="btn btn-submit">Submit</button>

                        </form>

                }

            </div >

        </div >
    )
}
export default AddRole