import React, {useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./manageProject.scss";
import LoaderForm from "../../components/common-function/LoaderForm";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import api from "../../redux/APIS/api";
import { projectByIdWithResources, getProjectResourceMapping } from "../../redux/features/projectSlice";
import { useSelector, useDispatch } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
const { REACT_APP_ENV } = process.env;

const ViewProject = () => {

    const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
    // const userId = rtm_user.user.data.id;
    const currentUserRole= rtm_user.user.data && rtm_user.user.data.tms_role && rtm_user.user.data.tms_role.role_key;
    // console.log(currentUserRole,rtm_user);
  const { 
            project,  project_status,  project_error,  projects_res_mapping,  projects_res_mapping_status,  
            projects_res_mapping_error,  projects_res_mappings_count
        } = useSelector(state => state.projects)
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const view_data=useLocation().state ;
    const title= "View Project Details";
    const dataDetails=[
        { id: "1", name: "Project Name", key: "project_name" },
        { id: "2", name: "Project Key", key: "project_key" },
        { id: "3", name: "Project Type", key: "project_type_name" },
        { id: "4", name: "Is Jira Project", key: "is_jira_project", status: true, statusFalse: "No", statusTrue: "Yes", statusTexts: { 0: "Pending", 1: "Active", 2: "Inactive", 3: "Archive" } },
        { id: "8", name: "Is Private", key: "is_private", status: true, statusFalse: "No", statusTrue: "Yes", statusTexts: { 0: "Pending", 1: "Active", 2: "Inactive", 3: "Archive" } },
        { id: "6", name: "Status", key: "status", status: true, statusFalse: "No", statusTrue: "Yes", statusTexts: { 0: "Pending", 1: "Active", 2: "Inactive", 3: "Archive" } },
        { id: "7", name: "Jira Project Id", key: "jira_project_id" },
        { id: "5", name: "Description", key: "description" },
      ];
    const [status] = useState(view_data && view_data.id ? "success": "loading");
    // const [assignedResources, setAssignedResources]=useState([]);

    // Edit Project
    const editProject=(data) => { 
        navigate('/edit-project',{state:data})
    }

    useEffect(() => {
        // view_data && view_data.id && loadProjectResources(view_data.id)
        dispatch(projectByIdWithResources(view_data.id))
    }, [])
    

    return (
        <div className="resource">
            <Sidebar />
            <div className="resourceContainer">
                <Navbar />
                <div className="top-headings">
                    <h3>{title || "View Details"}</h3>
                    <div> 
                    <Tooltip title="Back">
                    <Link to={-1} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>
                     
                        {(currentUserRole==="super_admin" || currentUserRole==="admin")&&
                        <button onClick={() => { editProject(project) }} className="btn btn-add-new">Edit Project</button>
                         }
                    </div>
                </div>
                {project_status!==STATUSES.SUCCESS ?
                    <LoaderForm/>
                    :
                    project_status===STATUSES.SUCCESS ?
                    <div className="view-details-row">
                        {
                            dataDetails.map((data, index)=>{
                                return(
                                    (project && project[data.key]  ) &&
                                    <div className={"detailItem"} key={index} >
                                        <span className="itemKey">{data.name} :</span>
                                        <span className="itemKey2">{project && project[data.key]}</span>
                                    </div> 
                                )
                            })
                        }
                        {/* (project[data.key] ===false) ? project[data.statusFalse]  : (project[data.key] ===true) ? project[data.statusTrue]: project[data.key] :project[data.key] */}
                        <div className="detailItem" >
                            <span className="itemKey">{"Resources"} :</span>
                           {project &&  project.projectsResources  &&  project.projectsResources.length ?
                                <span className="itemKey2" style={{display: "inline-grid",minHeight:"110px"}}>{project.projectsResources.map((element, index)=>{
                                    return(
                                        <span key={index}> {element.name} {(index < project.projectsResources.length-1 ) ? ", "
                                          : ""} </span>
                                    )
                                })}</span>
                                : 
                                <span className="itemKey2">No Resource Allocated.</span>

                            }
                        </div> 
                    </div>
                    :
                    <div>Oops something is wrong.. </div>
                }
            </div>
        </div >
    )

}

export default ViewProject