import "./manageuser.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useEffect, useState } from "react";
import dataService from "../../redux/services/data.service";
//import { getUserType } from "../../redux/features/userTypeSlice";
import { toast, ToastContainer } from "react-toastify";
import LoaderForm from "../../components/common-function/LoaderForm";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AddManageUser = ({ inputs, title }) => {

    const { id } = useParams()
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(true)

    const [formData, setFormData] = useState({
        type_name: "",
        type_key: "",
        status: ""
    })
    const [formErrors, setFormErrors] = useState('');

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setFormData({ ...formData, [name]: value });
    }

    const loadDataById = async () => {
        const response = await dataService.userTypeById(id)
        response && response.data && setFormData(response.data.data) && setShowLoader(false)
    }

    useEffect(() => {
        id && loadDataById()
    }, [id])

    useEffect(() => {
        setTimeout(() => {
            setShowLoader(false)
        }, 800)
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const request = { ...formData, created_by: LocalStorageDataService.userDetail().id, }
        if (!formData.type_name || formData.type_name === " ") {
            return setFormErrors({ 'nameErr': 'Type name is Required' })
        } else if (!formData.type_key || formData.type_key === " ") {
            return setFormErrors({ 'keyErr': 'Type key is Required' })
        } else if (formData.status === "") {
            return setFormErrors({ 'statusErr': 'Status is Required' })
        }

        const response = !id ?
            await dataService.addUserType(request)
            : await dataService.updateUserType(id, request)

        if (response && response.data && response.data.data) {
            toast.success(response.data.message);
            setTimeout(() => {
                // dispatch(getUserType(`{"skip":0, "limit":10}`))
                navigate('/all-user')
            }, 800)
        }
        if (response && response.data && response.data.status === 400) {
            const res = response.data.message
            toast.error(res)
            setFormErrors({
                "nameErr": res.type_name,
                "keyErr": res.type_key,
                "statusErr": res.status,
            })
        }
    }

    return (
        <div className="new">
            <Sidebar />

            <div className="newContainer">
                <Navbar />

                <div className="top-headings">
                    <h3>{title} </h3>
                    <Tooltip title="Back">
                    <Link to={"/all-user"} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>
                </div>
                {
                    showLoader ? <LoaderForm />
                        : <form onSubmit={handleSubmit}>
                            <ToastContainer />
                            <div className="add-new-user-form">

                                {inputs.map((input) => (
                                    <div className="formInput" key={input.id}>
                                        <label>{input.label}</label>
                                        <input
                                            name={input.name}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            value={formData[input.name] || ''}
                                            onChange={(e) => handleChange(e)}

                                        />
                                        <div className="errorMessage">{
                                            formErrors && input.name === "type_name" ?
                                                <p>{formData.type_name === "" ? formErrors.nameErr : ""}</p>
                                                : input.name === "type_key" ?
                                                    <p>{formData.type_key === "" ? formErrors.keyErr : ""}</p>
                                                    : ""
                                        }
                                        </div>
                                    </div>

                                ))}


                                <div className="formInput">
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="status"
                                            value={formData.status}
                                            onChange={(e) => { handleChange(e) }}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                        {formErrors && formErrors.statusErr && <p style={{ color: "#e33417", fontSize: 14 }}>{formData.status === "" ? formErrors.statusErr : ""}</p>}
                                    </FormControl>
                                </div>

                            </div>
                            <button className="btn btn-submit">Submit</button>

                        </form>
                }

            </div >

        </div >
    )
}

export default AddManageUser