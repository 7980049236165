import * as React from 'react';
// import { useTheme } from '@mui/material/styles';
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./task.scss";
import { Link } from "react-router-dom";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { CKEditor } from 'ckeditor4-react';
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import Select from 'react-select'
// import Bg1 from '../../images/bg-01.jpg'

const LabelOption = [
    { value: 'ui', label: 'UI' },
    { value: 'api', label: 'API' },
    { value: 'review', label: 'Review' }
]

const PriorityOption = [
    { value: 'medium', label: 'Medium' },
    { value: 'high', label: 'High' },
    { value: 'low', label: 'Low' },
    { value: 'lowest', label: 'Lowest' }
]

// const assigneeOption = [
//     { value: 'ajay', label: <p><span className="shortname-assignee">A</span>Ajay</p>},
//     { value: 'sakuntla', label: <p><span className="shortname-assignee">S</span>Sakuntla</p> },
//     { value: 'ravi', label: <p><span className="shortname-assignee">R</span>Ravi Kant</p> },
//     { value: 'shiv', label: <p><span className="shortname-assignee">S</span>Shiv Prakash</p> },
//     { value: 'anchal', label: <p><span className="shortname-assignee">A</span>Anchal</p> }
// ]

const ReporterOption = [
    { value: 'ajay', label: <p><span className="shortname-assignee">A</span>Ajay</p>},
    { value: 'sakuntla', label: <p><span className="shortname-assignee">S</span>Sakuntla</p> },
    { value: 'ravi', label: <p><span className="shortname-assignee">R</span>Ravi Kant</p> },
    { value: 'shiv', label: <p><span className="shortname-assignee">S</span>Shiv Prakash</p> },
    { value: 'anchal', label: <p><span className="shortname-assignee">A</span>Anchal</p> }
]




// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };

// const names = [
//     'Oliver Hansen',
//     'Van Henry',
//     'April Tucker',
//     'Ralph Hubbard',
//     'Omar Alexander',
//     'Carlos Abbott',
//     'Miriam Wagner',
//     'Bradley Wilkerson',
//     'Virginia Andrews',
//     'Kelly Snyder',
// ];

// function getStyles(name, personName, theme) {
//     return {
//         fontWeight:
//             personName.indexOf(name) === -1
//                 ? theme.typography.fontWeightRegular
//                 : theme.typography.fontWeightMedium,
//     };
// }

export const Edittask = () => {

    // const theme = useTheme();
    // const [personName, setPersonName] = React.useState([]);

    // const handleChange = (event) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setPersonName(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    // };


    return (
        <div className="task">
            <Sidebar />
            <div className="taskContainer">
                <div className="datatable">
                    <Navbar />
                    <div className="top-headings">
                        <h3>Edit Task</h3>
                        {/* <button className="btn btn-add-new">Back</button> */}
                        <Tooltip title="Back">
                        <button  className="btn-add-new "><ArrowBackIcon /></button>
                    </Tooltip>
                        
                    </div>

                    <div className="edit-details-row">
                        <div className="edit-details-col-left">
                            <h4>Create page for edit task</h4>
                            <ul className="top-title-buttons">
                                <li><Link to={"/"}><AttachFileIcon /> Attach</Link></li>
                                <li><Link to={"/"}><TaskAltIcon />Create subtask</Link></li>
                            </ul>
                            <div className="left-description">
                                <h6>Description</h6>
                                <CKEditor />
                                <button className="save-btn">Save</button>
                                <button className="cancel-btn">Cancel</button>



                                <h6>Comments</h6>
                                <div className="bottom-comments-row">
                                    <div className="bottom-commrnt-col-left">
                                        <span>RK</span>
                                    </div>
                                    <div className="bottom-commrnt-col-right">
                                        <CKEditor />
                                        <textarea className="text-area-comment" placeholder="Add Comment"></textarea>
                                        <button className="save-btn">Save</button>
                                        <button className="cancel-btn">Cancel</button>
                                    </div>
                                </div>

                                <div className="bottom-comments-row">
                                    <div className="bottom-commrnt-col-left">
                                        <span>RK</span>
                                    </div>
                                    <div className="bottom-commrnt-col-right">
                                        <h6>Ravi Kant <span>3 hours ago</span></h6>
                                        <p>Task Name, attchment,  create substask, description, save and cancel button, Comment section only</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="edit-details-col-right">
                            <select className="select-status-ticket">
                                <option>IN DEV</option>
                                <option>CODE REVIEW</option>
                                <option>IN PROGRESS</option>
                                <option>BACKLOG</option>
                                <option>ON HOLD</option>
                            </select>
                            <div className="right-side-details">
                                <h4>Create page for edit task</h4>
                                {/* <div className="right-and-left-name-lable">
                                <h6>Assignee</h6>
                           
                                    
                                    <Select
                                        id="demo-multiple-name"
                                        value={personName}
                                        onChange={handleChange}
                                    
                                        MenuProps={MenuProps}
                                        className="custom-select-name"
                                    >
                                        {names.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                                style={getStyles(name, personName, theme)}
                                            >
                                               <span className="name-shortcut">RK</span> {name}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    
                                
                                </div>

                                <div className="right-and-left-name-lable">

                                <h6>Reporter</h6>
                           
                                    
                                    <Select
                                        id="demo-multiple-name2"
                                        value={personName}
                                        onChange={handleChange}
                                    
                                        MenuProps={MenuProps}
                                        className="custom-select-name"
                                    >
                                        {names.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                                style={getStyles(name, personName, theme)}
                                            >
                                               <span className="name-shortcut">RK</span> {name}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    
                                
                                </div> */}
                                <div className="right-and-left-name-lable">
                                    <h6>Reporter</h6>
                                    <Select
                                        defaultValue={[ReporterOption[3]]}
                                        width='300px'
                                        options={ReporterOption} />
                                </div>

                                <div className="right-and-left-name-lable">
                                    <h6>Label</h6>
                                    <Select
                                        isMulti
                                        defaultValue={[LabelOption[0]]}
                                        width='300px'
                                        options={LabelOption} />
                                </div>

                                <div className="right-and-left-name-lable">
                                    <h6>Story Point</h6>
                                    <input type="text" value="2" />
                                </div>

                                <div className="right-and-left-name-lable">
                                    <h6>Sprint</h6>
                                    <input type="text" value="URTM-1" />
                                </div>

                                <div className="right-and-left-name-lable">
                                    <h6>Priority</h6>
                                    <Select
                                        width='300px'
                                        options={PriorityOption} 
                                        defaultValue={[PriorityOption[0]]}
                                    />
                                </div>


                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}
