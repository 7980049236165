import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./report.scss"
import { useState, useEffect } from "react";
import { attandanceList, attandanceDetailById } from "../../redux/features/calendarSlice"
import { useDispatch, useSelector } from "react-redux"
import { STATUSES } from "../../redux/common-status/constant";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { useLocation, useNavigate } from "react-router-dom";
import LoaderForm from "../../components/common-function/LoaderForm";
import { attendanceDetailsReportColumns, attendanceReportColumns } from "../../components/datatable-coulms/tableCoulmns";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import CONSTANTS from "../../components/constant/constantComponents";
// import moment from "moment";
import UcActionComponent from "../../components/common-function/UcActionComponent";

const ViewAttendaceDetails = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [openToggel, setOpenToogel] = useState(false)
    const [attendaceDetails, setattendaceDetails] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    // const [reload, setReload] = useState(false)
    const { attendanceData, status, error, attendaceDetailsById } = useSelector(state => state.calender)
    const resourceid = location && location.state && location.state.resourceid ? location.state.resourceid : "";
    const filterQuery =`{"filter":{"resource_id":{"value":${resourceid},"operation": "eq"}},"skip":0, "limit":10}`

    useEffect(() => {
        if (resourceid) {
            dispatch(attandanceList(filterQuery))
        }
        if (status === STATUSES.FAILURE && error) {
            errorHandling(error, navigate)
        }

    }, []);

    if (openToggel === true && status === STATUSES.SUCCESS && attendaceDetailsById && attendaceDetailsById.data && attendaceDetails.length === 0) {
        const attenance_details_data = attendaceDetailsById.data && attendaceDetailsById.data.rows;
        setattendaceDetails(attenance_details_data)
    }


    // const QuickSearchToolbar = () => {
    //     return (
    //         <Box
    //             sx={{
    //                 p: 0.5,
    //                 pb: 0
    //             }}
    //         >
    //             <GridToolbarQuickFilter
    //                 quickFilterParser={(searchInput) =>
    //                     searchInput
    //                         .split(",")
    //                         .map((value) => value.trim())
    //                         .filter((value) => value !== "")
    //                 }
    //             />
    //         </Box>
    //     );
    // }

    const showDiv = (ID) => {
        setOpenToogel(true)
        dispatch(attandanceDetailById(ID))
    }

    const handleClose = () => {
        setOpenToogel(false);
        setattendaceDetails([])
    }

    const handlePageChange = (newPage) => {
        // setReload(false)
        setCurrentPage(newPage)
        let pageSize = 10;
        let skip = 10

        if (newPage >= currentPage) {
            if (newPage === 0) {
                skip = (currentPage * 10)
            } else {
                skip = (currentPage * 10) + 10
            }
        } else if (newPage < currentPage) {

            if (skip < 0) {
                skip = 10
            } else {
                skip = currentPage * 10 - 10
            }

        }
        else {
            skip = 0
        }
        // let data = { "skip": skip, "limit": pageSize };
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = skip
        newFilterData['limit'] = pageSize
        dispatch(attandanceList(JSON.stringify(newFilterData)))
    }


    const actionColumn = [
        {
            field: "action",
            headerName: "My-Attendance",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                         <UcActionComponent moduleName="attendance-details" rowid={params.row.id}
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={false}
                            editButton={false}
                            viewButton={()=>showDiv(params.row.id)}
                            deleteButton={false} 
                            addMoreData={params.row.id}
                            />
                        {/* <div className="viewButton" onClick={() => showDiv(params.row.id)}><RemoveRedEyeIcon /></div> */}
                    </div>
                );
            },
        },
    ];

    // const onFilterChange = (filterModel) => {
    //     if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value && filterModel.items[0].value.length > 2) {
    //         let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
    //             filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
    //                 filterModel.items[0].operatorValue === "is" ? 'date' :
    //                     filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
    //         const filterData = [filterModel.items[0].columnField] === 'punchin_date' ?
    //             `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${moment(filterModel.items[0].value).format('YYYY-MM-DD')}","operation": "${operatorValue}"},{"resource_id":{"value":${resourceid},"operation": "eq"}},"skip":0, "limit":10}`
    //             :
    //             `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"},{"resource_id":{"value":${resourceid},"operation": "eq"}},"skip":0, "limit":10}`
    //         setFilterQuery(filterData)
    //         setCurrentPage(0)
    //         dispatch(attandanceList(filterData))
    //     }
    //     if (filterModel.items && filterModel.items.length === 0) {
    //         setCurrentPage(0)
    //         setFilterQuery(`{"filter":{"resource_id":{"value":${resourceid},"operation": "eq"}},"skip":0, "limit":10}`)
    //         dispatch(attandanceList(`{"filter":{"resource_id":{"value":${resourceid},"operation": "eq"}},"skip":0, "limit":10}`))
    //     }
    // }
    return (
        <div className="report">
            <Sidebar />
            <div className="reportContainer">
                <Navbar />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Resource Attendance Report</h3>
                        <button onClick={() => navigate(-1)} className="btn-add-new ">
                            Back
                        </button>
                    </div>

                    {status === STATUSES.SUCCESS || status === STATUSES.FAILURE ?
                        <DataGrid
                            className="datagrid"
                            rows={attendanceData && attendanceData.data && attendanceData.data.rows ? attendanceData.data.rows : []}
                            columns={attendanceReportColumns.concat(actionColumn)}
                            pageSize={10}
                            paginationMode="server"
                            page={currentPage}
                            rowsPerPageOptions={[10]}
                            rowCount={(attendanceData && attendanceData.data && attendanceData.data.count) || 10}
                            onPageChange={(newPage) => handlePageChange(newPage)}
                        // filterMode="server"
                        // onFilterModelChange={onFilterChange}
                        //checkboxSelection
                        //components={{ Toolbar: QuickSearchToolbar }}
                        // components={{  Toolbar:  (props) => (
                        //     <>
                        //         <FilterByDateRange />  
                        //     </> )}}
                        />

                        :
                        <LoaderForm />
                    }
                </div>
                <Dialog
                    fullWidth={true} maxWidth={"md"} open={openToggel} onClose={handleClose}>
                    <DialogTitle>MY-ATTANDANCE</DialogTitle>
                    <DialogContent>
                        <DataGrid
                            autoHeight
                            className="datagrid"
                            rows={attendaceDetails ? attendaceDetails : []}
                            columns={attendanceDetailsReportColumns}
                            pageSize={9}
                            rowsPerPageOptions={[9]}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button className="close-modal" onClick={handleClose}>{CONSTANTS.COMMON_BUTTONS.CLOSE}</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default ViewAttendaceDetails