import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import Widget from "../../components/widget/Widget"
import Featured from "../../components/featured/Featured"
// import Chart from "../../components/chart/Chart"
// import Table from "../../components/table/Table"
import "./dashboardMenu.scss"
import { useDispatch, useSelector } from "react-redux";
import { allProjects, allResources, allShift, allTask, myProjects } from "../../redux/features/dashboardSlice";
const { REACT_APP_ENV } = process.env;
const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))


const DashboardMenu = () => {

    if (window.location == window.location.origin + '/dashboard') {
        // console.log(window.location)
        window.location = '/dashboard?reload=done'
    }
    const dispatch = useDispatch();
    const { resourceList, shiftList, projectList, myProjectList, taskList, } = useSelector(state => state.dashboardCard)
    const tmsRole = rtm_user && rtm_user.user && rtm_user.user.data && rtm_user.user.data.tms_role
    useEffect(() => {
        // console.log(tmsRole.role_key, "role")

        if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) {
            dispatch(allResources('{"skip":0,"limit":10}'))
            dispatch(allShift('{"skip":0,"limit":10}'))
            // dispatch(allReport())
            dispatch(allProjects())
            dispatch(allResources(`{"skip":0, "limit":10}`))
        } else {
            dispatch(allTask())
            dispatch(myProjects())
        }
    }, [])


    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboardContainer">
                <Navbar />

                <div className="widgets">
                    {
                        tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin') ?
                            <>
                                <Widget type="resources" count={(resourceList && resourceList.count) ? resourceList.count : 0} />
                                <Widget type="projects" count={(projectList && projectList.count) ? projectList.count : 0} />
                                <Widget type="shifts" count={(shiftList && shiftList.count) ? shiftList.count : 0} />
                                <Widget type="reports" count={2} />
                            </>
                            : <>
                                <Widget type="tasks" count={(taskList && taskList.count) ? taskList.count : 0} />
                                <Widget type="myProjects" count={(myProjectList && myProjectList.count) ? myProjectList.count : 0} />
                            </>
                    }
                </div>

                <div className="charts">
                    <Featured />

                    {/* <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} /> */}
                </div>
                {/* <div className="listContainer">
                    <div className="listTitle">Latest Transactions</div>
                    <Table />
                </div> */}
            </div>

        </div>
    )
}

export default DashboardMenu