import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";

export const callCreate = createAsyncThunk("phone-call/create", async (data) => {
    const res = await DataService.calling_api(data);
    return res.data;
});

export const getCallLogDetails = createAsyncThunk("call-log/details", async (data) => {
    const res = await DataService.get_call_log(data);
    return res.data;
})
export const callDisconnect = createAsyncThunk("call-disconnect/api", async (id) => {
    const res = await DataService.call_disconnect_api(id);
    return res.data;
})

const callingSlice = createSlice({
    name: "callingSlice",
    initialState: {
        call_Create: [],
        status: STATUSES.IDLE,
        error: '',
        callDetails: [],
        call_disconnect: [],
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //call create
            .addCase(callCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(callCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_call_Create`;
                state.call_Create = action.payload;
            })
            .addCase(callCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //get call details
            .addCase(getCallLogDetails.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getCallLogDetails.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_callDetails`;
                state.callDetails = action.payload
            })
            .addCase(getCallLogDetails.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //call disconnect api
            .addCase(callDisconnect.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(callDisconnect.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_call_disconnect`;
                state.call_disconnect = action.payload;
            })
            .addCase(callDisconnect.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


    },
});

export default callingSlice.reducer;