import axios from "axios";

//console.log('React App API BaseUrl:', process.env);
const { REACT_APP_API_BASEURL, REACT_APP_ENV } = process.env;

// console.log(REACT_APP_ENV,"branch")

const rtms = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
export default axios.create({
    baseURL: REACT_APP_API_BASEURL,
    headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": rtms ? rtms.token : '',
    }
});
