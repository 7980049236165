import { Add } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ProcessSpinner from '../loader/ProcessSpinner'
import React from 'react'
import { Tooltip } from "@mui/material";

const CustomButton = ({ title, onClick, loading, spinnerSize, className, disabled, type, preAddIcon, preBackIcon, customIcon, iconRight, tooltip }) => {
  return (
    tooltip ?
      <Tooltip title={tooltip}>
        <button
          className={className ? className : `btn-with-loader`}
          onClick={onClick}
          disabled={disabled}
          type={type || "button"}
        >
          {loading ? <ProcessSpinner size={spinnerSize || "sm"} /> : ""}
          {preBackIcon && <ArrowBackIcon />}
          {(!iconRight && customIcon) && customIcon}
          {preAddIcon && <Add />}{title || "Submit"}
          {(iconRight && customIcon) && customIcon}
        </button>
      </Tooltip>
      : <button
        className={className ? className : `btn-with-loader`}
        onClick={onClick}
        disabled={disabled}
        type={type || "button"}
      >
        {loading ? <ProcessSpinner size={spinnerSize || "sm"} /> : ""}
        {preBackIcon && <ArrowBackIcon />}
        {(!iconRight && customIcon) && customIcon}
        {preAddIcon && <Add />}{title || "Submit"}
        {(iconRight && customIcon) && customIcon}
      </button>

  )
}

export default CustomButton