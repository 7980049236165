import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { callCreate } from '../../redux/features/callingSlice';
import { STATUSES } from "../../redux/common-status/constant";
import { toast } from "react-toastify";
import { Phone } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

const CallingComponent = ({ data, className, title, setIsCallInitiated, setCallId, setCallStatus, style, setBtnStyle, setPhoneDetails }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [showBtn, setShowBtn] = useState(true);

    const { status, call_Create } = useSelector(state => state.callingSlice)

    if (loading === true && status === `${STATUSES.SUCCESS}_call_Create` && call_Create && call_Create.message) {
        // console.log("calling com,succce",call_Create)
        setLoading(false)
        if (call_Create.status === 200) {
            toast.success(call_Create.message)
            setCallId(call_Create.data.id)
            setShowBtn(false)
        } else {
            setShowBtn(false)
            toast.error(call_Create.message)
            setIsCallInitiated(false)
            setPhoneDetails({ destination_number: "", story_id: "" });
            setBtnStyle({ backgroundColor: "green", border: "1px solid green" })
        }
    }
    const callInitiate = () => {
        setLoading(true)
        setBtnStyle({ display: "none" })
        setIsCallInitiated(true)
        dispatch(callCreate(data))
        setCallStatus(0)
    }
    return <>
        {
            showBtn &&
            <Tooltip title="Call">
                <button onClick={callInitiate} className={className} style={style}>
                    <Phone /> {title}</button>
            </Tooltip>
        }

    </>
}

export default CallingComponent