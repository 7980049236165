import './resourceCredential.scss'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import dataService from '../../redux/services/data.service'
import LoaderForm from '../../components/common-function/LoaderForm'
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function ViewResourceCredential() {
    // const location = useLocation()
    // const id = location && location.state && location.state.id;
    const { id } = useParams()
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false)

    const loadDataById = async () => {
        setLoader(true)
        const response = await dataService.resourceCredentialById(id).catch(error => {
            setLoader(false)
            return error;
        });
        if (response && response.data && response.data.data) {
            setData(response.data.data)
            // console.log(response.data.data)
            setLoader(false)
        } else {
            setLoader(false)
        }
    }

    useEffect(() => {
        loadDataById();
    }, [])


    return (
        <div className="resource">
            <Sidebar />
            <div className="resourceContainer">
                <Navbar />
                {
                    loader ? <LoaderForm /> : <>

                        <div className="top-headings" style={{ margin: '20px' }}>
                            <h3>Credential View</h3>
                            <div>
                                <Tooltip title="Back">
                                    <Link to={-1} className="btn btn-add-new"><ArrowBackIcon /></Link>
                                </Tooltip>
                                {/* <Link to={`/edit-resource-credential/${id}`} className="btn btn-add-new">Edit Credential</Link> */}
                            </div>
                        </div>

                        <form>
                            <div className="add-new-user-form">
                                <div className="formInput">
                                    <span style={{ fontSize: '17px', fontWeight: '600' }}>
                                        Credential Head :
                                    </span>&nbsp;
                                    {data && data.credentails_head && data.credentails_head.head_name}

                                </div>
                                {
                                    data && data.resource &&
                                    <div className="formInput">
                                        <span style={{ fontSize: '17px', fontWeight: '600' }}>
                                            Resource Name :
                                        </span>&nbsp;
                                        {data && data.resource && data.resource.name}

                                    </div>
                                }
                                {
                                    data && data.is_global &&
                                    <div className="formInput">
                                        <span style={{ fontSize: '17px', fontWeight: '600' }}>
                                            Is Global :
                                        </span>&nbsp;
                                        {data && data.is_global ? "True" : "False"}
                                    </div>
                                }

                                <div className="formInput"></div>
                                <div className="formInput"></div>

                            </div>

                            <div className="add-new-user-form">
                                <div className="formInput">
                                    <div className="form-credencial-row">
                                        <h2>Credentials</h2>
                                    </div>
                                </div>
                            </div>

                            <hr style={{ marginBottom: '20px' }} />

                            <div className="add-new-user-form">

                                <div className="formInput">
                                    <span style={{ fontSize: '17px', fontWeight: '600' }}>
                                        Credential Key
                                    </span>
                                </div>

                                <div className='formInput'>
                                    <span style={{ fontSize: '17px', fontWeight: '600' }}>
                                        Credential Value
                                    </span>
                                </div>

                                <div className="formInput">
                                    <span className='head' style={{ fontSize: '17px', fontWeight: '600' }}>
                                        Status
                                    </span>
                                </div>

                                <div className='formInput'></div>
                            </div>
                            {
                                data && data.value && data.value.map((item, i) => {
                                    return (
                                        <div className="add-new-user-form" key={i}>

                                            <div className="formInput">
                                                {item && item.key && item.value ? item.key : 'N/A'}
                                            </div>

                                            <div className="formInput">
                                                {item && item.value && item.value ? item.value : "N/A"}
                                            </div>

                                            <div className="formInput">
                                                {item && item.status && item.status === "true" ? "Active" : "Inactive"}
                                            </div>

                                            <div className='formInput'></div>
                                        </div>
                                    )
                                })
                            }

                        </form>
                    </>
                }
            </div>
        </div >
    )
}
