import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";

export const sendEmail = createAsyncThunk("email-send", async (data) => {
    const res = await DataService.send_email(data);
    return res.data;
});
export const getCategoryListEmail = createAsyncThunk("category-list/email", async (project_id) => {
    const res = await DataService.get_category_by_domain(project_id);
    return res.data;
});
export const getEmailTemplateMaster = createAsyncThunk("email-template-master", async (data) => {
    const res = await DataService.get_email_template_master(data);
    return res.data;
});


const emailSlice = createSlice({
    name: "emailSlice",
    initialState: {
        send_email: [],
        categoryListEmail: [],
        emailTemplateMaster: [],
        status: STATUSES.IDLE,
        error: '',
        body: "",
        subject: "",
    },
    reducers: {
        updateBodyData: (state, action) => {
            state.body = action.payload;
            state.status = STATUSES.SUCCESS;
        },
        updateSubject: (state, action) => {
            state.subject = action.payload;
            state.status = STATUSES.SUCCESS;
        },
    },
    extraReducers(builder) {
        builder
            //send Email
            .addCase(sendEmail.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(sendEmail.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_send_email`;
                state.send_email = action.payload;
            })
            .addCase(sendEmail.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //get  category list
            .addCase(getCategoryListEmail.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getCategoryListEmail.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_categoryListEmail`;
                state.categoryListEmail = action.payload;
            })
            .addCase(getCategoryListEmail.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            //get email template master
            .addCase(getEmailTemplateMaster.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getEmailTemplateMaster.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_emailTemplateMaster`;
                state.emailTemplateMaster = action.payload;
            })
            .addCase(getEmailTemplateMaster.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

    },
});
export const { updateBodyData, updateSubject } = emailSlice.actions
export default emailSlice.reducer;