import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";
const { REACT_APP_ENV } = process.env;



export const errorHandling = (error, navigate) => {
    if (error.message === 'Request failed with status code 401') {
        localStorage.removeItem(`${REACT_APP_ENV}_rtms`);
        if (navigate) {
            navigate('/')
        } else {
            window.location.reload()
        }

    }
    if (error.data === 'Authorization token is expired!') {
        localStorage.removeItem(`${REACT_APP_ENV}_rtms`);
        navigate('/')
    }
}

export const logout = createAsyncThunk("user/logout", async () => {
    localStorage.removeItem('user');
    const res = await DataService.logout();
    return res.data;
}
);



const logoutSlice = createSlice({
    name: "logout",
    initialState: {
        data: [],
        status: STATUSES.IDLE,
        error: ''
    },
    reducers: {
        logoutAll: (state, action) => {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
        }
    },
    extraReducers(builder) {
        builder
            .addCase(logout.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(logout.fulfilled, (state, action) => {
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                state.data = action.payload;
                state.status = STATUSES.SUCCESS;
            })
            .addCase(logout.rejected, (state, action) => {
                state.error = action.error;
                state.status = STATUSES.FAILURE;
            })

    },
});
export const { logoutAll } = logoutSlice.actions

export default logoutSlice.reducer;