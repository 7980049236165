import './zoomCallDetails.scss';
import { ArrowBack } from '@mui/icons-material';
import { TablePagination, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import LoaderForm from '../../components/common-function/LoaderForm';
import { zoomCallDetailsColumn } from '../../components/datatable-coulms/tableCoulmns'
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import TablePaginationActions from '../../components/TablePaginationActions';
import { STATUSES } from '../../redux/common-status/constant';
import { getZoomCallLogDetails } from '../../redux/features/zoomSlice';
import { useNavigate } from 'react-router-dom';

export const ZoomCallDetails = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { zoomCallDetails, status } = useSelector(state => state.zoomSlice)
    const { data: list } = zoomCallDetails

    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(`{"skip":0,"limit":10,"order_by":{"column":"-id"}}`)
    const [pageSize, setPageSize] = useState(10);
    const [limit, setLimit] = useState(10);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    useEffect(() => {
        dispatch(getZoomCallLogDetails(filterQuery))
    }, [])

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        dispatch(getZoomCallLogDetails(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        dispatch(getZoomCallLogDetails(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(list && list.rows && list.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                                filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`

            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getZoomCallLogDetails(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            setPageSize(10)
            setFilterQuery(`{"skip":0,"limit":10,"order_by":{"column":"-id"}}`)
            dispatch(getZoomCallLogDetails(`{"skip":0,"limit":10,"order_by":{"column":"-id"}}`))
        }
    }

    return (
        <div className="zoomCallListing">
            <Sidebar />
            <div className="zoomCallListingContainer">
                <Navbar />
                <div className="datatable">
                    <div className="datatableTitle">
                        <h3>Zoom Call Details</h3>
                        <Tooltip title="Back">
                            <button className='btn-add-new' onClick={() => navigate(-1)}><ArrowBack /></button>
                        </Tooltip>
                    </div>

                    <ToastContainer />
                    {(status === STATUSES.LOADING) && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={list && list.rows ? list.rows : []}
                        columns={zoomCallDetailsColumn}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        components={{
                            Pagination: CustomPagination,
                        }}
                    />
                </div>

            </div>
        </div>
    )
}
