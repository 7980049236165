import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";


export const resourceList = createAsyncThunk("resource/list", async (filterData) => {
    const res = await DataService.resource_list(filterData);
    return res.data;
});

export const resourceListForTaskEdit = createAsyncThunk("resource/list-edit", async () => {
    const res = await DataService.resource_list_for_task();
    return res.data;
}
);

export const resourceViewById = createAsyncThunk("resource/view", async (id) => {
    const res = await DataService.resource_view(id);
    return res.data;
}
);

export const resourceAddNew = createAsyncThunk("resource/add", async (data) => {
    const res = await DataService.resource_add(data);
    return res.data;
}
);

export const resourceEdit = createAsyncThunk("resource/edit", async (id, data) => {
    const res = await DataService.resource_edit(id, data);
    return res.data;
}
);

export const resourceDeleteById = createAsyncThunk("resource/delete", async (id, data) => {
    const res = await DataService.resource_delete(id, data);
    return res.data;
}
);

export const employTypeList = createAsyncThunk("employtype/list", async () => {
    const res = await DataService.employment_type_list();
    return res.data;
}
);

export const departmentTypeList = createAsyncThunk("departmenttype/list", async () => {
    const res = await DataService.department_type_list();
    return res.data;
}
);


export const tmsRoleList = createAsyncThunk("tmsrole/list", async () => {
    const res = await DataService.tms_role_list();
    return res.data;
}
);

export const resourceCreate = createAsyncThunk("resource/create", async (data) => {
    const res = await DataService.create_resources(data);
    return res.data;
}
);

export const resourceUpdateById = createAsyncThunk("resource/update", async (data) => {
    const res = await DataService.update_resources(data.id, data.addvalues);
    return res.data;
}
);

export const resourceSyncByJira = createAsyncThunk("resource/sync", async () => {
    const res = await DataService.sync_resources();
    return res.data;
});

export const allRoleList = createAsyncThunk("role-list/all", async () => {
    const res = await DataService.all_role_list();
    return res.data;
})

export const allDepartmentList = createAsyncThunk("department-list/all", async () => {
    const res = await DataService.all_department_list();
    return res.data;
})
export const allEmpTypeList = createAsyncThunk("emp-list/all", async () => {
    const res = await DataService.all_emp_type_list();
    return res.data;
})
export const allResourcesListEdit = createAsyncThunk("resource-list/edit", async () => {
    const res = await DataService.resource_list_status();
    return res.data;
});

export const allResourcesByManager = createAsyncThunk("resource-list/all", async () => {
    const res = await DataService.all_resources_by_manager();
    return res.data;
});
export const allResourcesList = createAsyncThunk("resource-list-by-manger/all", async () => {
    const res = await DataService.resource_list_status();
    return res.data;
});


const resourceSlice = createSlice({
    name: "resource_list",
    initialState: {
        data: [],
        view_data: [],
        empType: [],
        deptType: [],
        tmsRoles: [],
        createResource: [],
        updateResource: [],
        deleteResource: [],
        editResource: [],
        syncResource: [],
        resourceListForTsk: [],
        roleList: [],
        departmentList: [],
        empTypeList: [],
        status: STATUSES.IDLE,
        dataLoader: STATUSES.IDLE,
        error: '',
        activeResourceList: [],
        activeResourceFilter: [],
        allResourcesByManagerList:[],

    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(resourceList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resourceList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.dataLoader = STATUSES.SUCCESS;
                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let res = result && result.data && result.data.rows
                let count = result && result.data && result.data.count
                res.map(e => {
                    return newArray.push({
                        id: e.id,
                        name: e.name,
                        display_name: e.display_name,
                        email: e.email,
                        department: e.department && e.department.id,
                        department_name: e.department && e.department.name,
                        tms_role_name: e.tms_role && e.tms_role.role_name,
                        tms_role: e.tms_role && e.tms_role.id,
                        shift: e.shift && e.shift.shift_name,
                        shift_start_time: e.shift && e.shift.start_time,
                        shift_end_time: e.shift && e.shift.end_time,
                        employment_type: e.employment_type && e.employment_type.id,
                        employment_type_name: e.employment_type && e.employment_type.employment_type,
                        contact_no: e.contact_no,
                        emp_code: e.emp_code,
                        jira_account: e.jira_account,
                        reporting_manager: e.reporting_manager,
                        reporting_manager_name: e.reporting_manager_name,
                        status: e.status
                    })
                })
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.data = newArray2 && newArray2.data ? newArray2 : action.payload;
            })
            .addCase(resourceList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///resource list for task edit
            .addCase(resourceListForTaskEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resourceListForTaskEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.resourceListForTsk = action.payload;
            })
            .addCase(resourceListForTaskEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///resource View by id

            .addCase(resourceViewById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resourceViewById.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.view_data = action.payload;
            })
            .addCase(resourceViewById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///resource add

            .addCase(resourceAddNew.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resourceAddNew.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.view_data = action.payload;
            })
            .addCase(resourceAddNew.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///resource Edit by id

            .addCase(resourceEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resourceEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.editResource = action.payload;
            })
            .addCase(resourceEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///resource delete by id

            .addCase(resourceDeleteById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resourceDeleteById.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.deleteResource = action.payload;
            })
            .addCase(resourceDeleteById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///get emplayee type list

            .addCase(employTypeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(employTypeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.empType = action.payload;
            })
            .addCase(employTypeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///get department type list

            .addCase(departmentTypeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(departmentTypeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.deptType = action.payload;
            })
            .addCase(departmentTypeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///get TMS Role list

            .addCase(tmsRoleList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(tmsRoleList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.tmsRoles = action.payload;
            })
            .addCase(tmsRoleList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///create resource

            .addCase(resourceCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resourceCreate.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.createResource = action.payload;
            })
            .addCase(resourceCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///update resource

            .addCase(resourceUpdateById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resourceUpdateById.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.updateResource = action.payload;
            })
            .addCase(resourceUpdateById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            ///Syncing resource

            .addCase(resourceSyncByJira.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resourceSyncByJira.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_syncResource`;
                state.syncResource = action.payload;
            })
            .addCase(resourceSyncByJira.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            ///All role list for filter
            .addCase(allRoleList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_roleList`;
                state.roleList = action.payload;
            })

            ///All department list for filter
            .addCase(allDepartmentList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_departmentList`;
                state.departmentList = action.payload;
            })
            ///All Emp. Type list for filter
            .addCase(allEmpTypeList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_empTypeList`;
                state.empTypeList = action.payload;
            })

            //All active resources for add/edit/view
            .addCase(allResourcesListEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(allResourcesListEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.activeResourceList = action.payload;
            })
            .addCase(allResourcesListEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///All active resource list for filter
            .addCase(allResourcesList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_resourceList`;
                state.activeResourceFilter = action.payload;
            })
            ///All active resource list for filter
            .addCase(allResourcesByManager.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_allResourcesByManagerList`;
                state.allResourcesByManagerList = action.payload;
            })



    },
});


export default resourceSlice.reducer;