import React, {useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./manageshift.scss";
import LoaderForm from "../../components/common-function/LoaderForm";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Tooltip } from "@mui/material";

const ViewShift = () => {

    // const navigate=useNavigate()
    const view_data=useLocation().state;
    const [status] = useState(view_data && view_data.id ? "success": "loading")

    return (
        <div className="resource">
            <Sidebar />
            <div className="resourceContainer">
                <Navbar />
                <div className="top-headings">
                    <h3>Shift View</h3>
                 
                    <Tooltip title="Back">
                            <Link to={-1} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>
                </div>
                {status === 'loading' ?
                    <LoaderForm/>
                    :
                    status === 'success' ?
                    <div className="view-details-row">
                        <div className="detailItem">
                            <span className="itemKey">Shift Name :</span>
                            <span className="itemKey2">{view_data && view_data.shift_name}</span>
                        </div>

                         <div className="detailItem">
                            <span className="itemKey">Shift Key :</span>
                            <span className="itemKey2">{view_data && view_data.shift_key}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Start Time :</span>
                            <span className="itemKey2">{view_data && view_data.start_time}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">End Time :</span>
                            <span className="itemKey2">{view_data && view_data.end_time}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Shift Id :</span>
                            <span className="itemKey2">{view_data && view_data.id}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Status :</span>
                            <span className="itemKey2">{view_data && view_data.status ? "Active": "Inactive"}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Timing Display :</span>
                            <span className="itemKey2">{view_data && view_data.duration ?  parseInt(view_data.duration/60) ? parseInt(view_data.duration/60) +" Hours " 
                                // + view_data.duration%60 + "Minutes" 
                                :""  :"" }
                                {(view_data && view_data.duration &&  parseInt(view_data.duration%60)) ? parseInt(view_data.duration%60) +" Minutes " :""}
                                </span>
                        </div>
                        
                    </div>
                    :
                    <div>Oops something is wrong.. </div>

                }
            </div>
        </div >
    )

}

export default ViewShift