// import { Tooltip } from '@mui/material';
// import moment from 'moment'
export const userColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "user",
    headerName: "User",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.img} alt="avatar" />
          {params.row.username}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "age",
    headerName: "Age",
    width: 100,
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status}
        </div>
      );
    },
  },
];

//temporary data
export const userRows = [
  {
    id: 1,
    username: "Snow",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    status: "active",
    email: "1snow@gmail.com",
    age: 35,
  },
  {
    id: 2,
    username: "Jamie Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "2snow@gmail.com",
    status: "passive",
    age: 42,
  },
  {
    id: 3,
    username: "Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "3snow@gmail.com",
    status: "pending",
    age: 45,
  },
  {
    id: 4,
    username: "Stark",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "4snow@gmail.com",
    status: "active",
    age: 16,
  },
  {
    id: 5,
    username: "Targaryen",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "5snow@gmail.com",
    status: "passive",
    age: 22,
  },
  {
    id: 6,
    username: "Melisandre",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "6snow@gmail.com",
    status: "active",
    age: 15,
  },
  {
    id: 7,
    username: "Clifford",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "7snow@gmail.com",
    status: "passive",
    age: 44,
  },
  {
    id: 8,
    username: "Frances",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "8snow@gmail.com",
    status: "active",
    age: 36,
  },
  {
    id: 9,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "pending",
    age: 65,
  },
  {
    id: 10,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "active",
    age: 65,
  },
];

export const shiftColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  {
    field: "shift_name",
    headerName: "Shift",
    width: 150,
    // renderCell: (params) => {
    //   return (
    //     <div className="cellWithImg">
    //       <img className="cellImg" src={params.row.img} alt="avatar" />
    //       {params.row.shiftName}
    //     </div>
    //   );
    // },

//     created_by: 1
// ​​​
// duration: 2
// ​​​
// id: 1
// ​​​
// shift_key: "1"
// ​​​
// shift_name: "Shift 1"
// ​​​
// start_time: "2022-09-21T05:38:24Z"
// ​​​
// status: true
// ​​​
// timing_display: "2hrs"
  },
  // {
  //   field: "description",
  //   headerName: "Description",
  //   width: 150,
  // },
  {
    field: "start_time",
    headerName: "From Time",
    width: 150,
  },
  {
    field: "end_time",
    headerName: "To Time",
    width: 150,
  },
  // {
  //   field: "timeZone",
  //   headerName: "Time Zone",
  //   width: 150,
  // },
  {
    field: "status_value",
    headerName: "Status",

    type: 'singleSelect',
    valueOptions: [{label:"Active", value:"True"},{label:"Inactive", value:"False"}],
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {/* {params.row.status === true ? "Active" : "Inactive"} */}
          {params.row.status === 3 ? "Archive" :params.row.status === true ? "Active" : "Inactive"}
        </div>
      );
    },
  },
];
// export const resourcesColumns = [
//   { field: "id", headerName: "ID", width: 70 },
//   {
//     field: "name",
//     headerName: "Name",
//     width: 150,
//   },
//   {
//     field: "email",
//     headerName: "Email",
//     width: 150,
//   },
//   {
//     field: "contact_no",
//     headerName: "Contact No",
//     width: 150,
//   },
//   {
//     field: "jira_account",
//     headerName: "Jira Account",
//     width: 150,
//   },
//   // {
//   //   field: "department_id",
//   //   headerName: "Dept Id",
//   //   width: 150,
//   // },
//   {
//     field: "department_id",
//     headerName: "Department",
    
//     width: 150,
//   },
//   // {
//   //   field: "tms_role_id",
//   //   headerName: "Role Id",
//   //   width: 150,
//   // },
//   {
//     field: "role_name",
//     headerName: "Role",
//     width: 150,
//   },
//   {
//     field: "assigned_shifts",
//     headerName: "Shift",
//     width: 280,
//     renderCell: (params) => {
//         return (
//             <div className="cellWithImg">
//               {/* <p style={{padding:"5px"}}> {params.row.assigned_shifts} </p>   */}
//               {
//                 params.row.assigned_shifts.map((shift, index)=>{
//                   return(
//                     <p key={index} style={{padding:"5px"}}> {shift} ( {moment("10/10/2022 "+params.row.assigned_shifts_array[0].start_time).format('LT')}<span> - {moment("10/10/2022 "+params.row.assigned_shifts_array[0].end_time ).format('LT')} )</span>  </p>
//                   )
//                 })
//               }
//             </div>
//           );
//         },
//   },
//   // {
//   //   field: "assigned_shifts_array", 
//   //   headerName: "Timing",
//   //   width: 250,
//   //   renderCell: (params) => {
//   //       return (
//   //           <div className="cellWithImg">
//   //             {/* <p style={{padding:"5px"}}> {params.row.assigned_shifts} </p>   */}
//   //             {
//   //               params.row.assigned_shifts_array.map((shift, index)=>{
//   //                 return(
//   //                   <>
//   //                     {/* <p key={index} style={{padding:"5px"}}>  {shift.shift_name} </p> */}
//   //                     {/* <p>{shift.start_time}<span> - { shift.end_time }</span> </p>  */}
//   //                     <p>{moment("10/10/2022 "+shift.start_time).format('LT')}<span> - {moment("10/10/2022 "+shift.end_time ).format('LT')}</span> </p> 
//   //                   </>
//   //                 )
//   //               })
//   //             }
//   //           </div>
//   //         );
//   //       },
//   // },
//   {
//     field: "status",
//     headerName: "Status",
//     type: 'singleSelect',
//     valueOptions: [{label:"Active", value:"True"},{label:"Inactive", value:"False"}],
//     width: 160,
//     renderCell: (params) => {
//       return (
//         <div className={`cellWithStatus ${params.row.status}`}>
//           {params.row.status === true ? "Active" : "Inactive"}
//         </div>
//       );
//     },
//   },
// ];
//temporary data
export const shiftRows = [
  {
    id: 1,
    shiftName: "PartTime",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    fromTime:"12:00 PM",
    toTime:"05:00 AM",
    timeZone:"ist",
    status: "active",
    description: "A Short Description About the Shift.",
    age: 35,
  },
  {
    id: 2,
    shiftName: "Regular Full Time",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    description: "A Short Description About the Shift.",
    fromTime:"10:00 AM",
    toTime:"06:00 PM",
    timeZone:"ist",
    status: "passive",
    age: 42,
  },
  {
    id: 3,
    shiftName: "With OverTime",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    description: "A Short Description About the Shift.",
    fromTime:"10:00 AM",
    toTime:"08:00 PM",
    timeZone:"ist",
    status: "pending",
    age: 45,
  },
];

// export const projectsColumns = [
//   { field: "id", headerName: "ID", width: 70 },
//   {
//     field: "project_name",
//     headerName: "Project",
//     width: 200,
//       renderCell: (params) => {
//         return (
//           <div className="cellWithImg">
//             {/* <img className="cellImg" src={params.row.avatar_urls["24x24"]|| "https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg"} alt="avatar" /> */}
//             {params.row.project_name}
//           </div>
//           );
//         },
//   },
//   {
//     field: "project_key",
//     headerName: "Project Key",
//     width: 150,
//   },

//   {
//     field: "is_jira_project_text",
//     headerName: "Is Jira Project",
//     width: 150,
//   },

//   {
//     field: "is_jira_project",
//     headerName: "is_jira_project",
//     width: 0,
//   },

//   {
//     field: "status",
//     headerName: "status",
//     width: 130,
//     // renderCell: (params) => {
//     //   return (
//     //     <div className={`cellWithStatus ${params.row.is_jira_project}`}>
//     //       {params.row.is_jira_project === true ? "Yes" : "No"}
//     //     </div>
//     //   );
//     // },
//   },
//   // {
//   //   field: "description",
//   //   headerName: "Description",
//   //   width: 150,
//   // },
  
//   // {
//   //   field: "projectsResources",
//   //   headerName: "Resources",
//   //   width: 280,
//   //     renderCell: (params) => {
        
//   //       return (
//   //         <div className="cellWithImg">            
//   //             { params.row.projectsResources && params.row.projectsResources.length ?
//   //               params.row.projectsResources.map((resource, index)=>{
//   //                 return(
//   //                     <p key={index} style={{}}> {(index<=1 && index <= params.row.projectsResources.length-1 ) ?  (index < params.row.projectsResources.length-1) ? ` ${resource.resource.name}  , ` :  resource.resource.name
//   //                       : " "} </p> 
//   //                 )
//   //               })
//   //               :""
//   //             }
//   //             {(params.row.projectsResources && params.row.projectsResources.length) >2 ? `..[+${params.row.projectsResources.length-2}]` : ""} 

//   //         </div>
//   //         );
//   //       },
//   // },

//   {
//     field: "resource",
//     headerName: "resource",
//     width: 0,
//   },
//   // {
//   //   field: "projectsResourcesNames",
//   //   headerName: "Asignees",
//   //   width: 380,
//   //   type: 'singleSelect',
//   //   // valueOptions: props.optionsObj.projectsResourcesNames,
//   //     renderCell: (params) => {
        
//   //       return (
//   //         <div className="cellWithImg">   
//   //         {/* <p> { params.row.projectsResourcesNames && params.row.projectsResourcesNames.length && params.row.projectsResourcesNames[0]} </p>          */}
//   //             { params.row.projectsResourcesNames && params.row.projectsResourcesNames.length ?
//   //               params.row.projectsResourcesNames.map((resource, index)=>{
//   //                 return(
//   //                     <p key={index} style={{}}> 
//   //                       {index ===0 && resource} {index ===0 && params.row.projectsResourcesNames.length >1 && ","}
//   //                       {params.row.projectsResourcesNames.length >1 && index ===1 && resource} {index ===1 && params.row.projectsResourcesNames.length >2 && ","}
//   //                     </p> 
//   //                 )
//   //               })
//   //               :""
//   //             }
//   //             {(params.row.projectsResourcesNames && params.row.projectsResourcesNames.length >2 )? `..[+${params.row.projectsResources.length-2}]` : ""} 

//   //         </div>
//   //         );
//   //       },
//   // },
//   {
//     field: "created_on",
//     headerName: "Created On",
//     width: 120,
//     renderCell: (params) => {
//       return (
//         <div className="cellWithImg">
//           {moment(params.row.created_on).format('DD-MM-YYYY')}
//         </div>
//         );
//       },
//   },
//   {
//     field: "status_text",
//     headerName: "Status",
//     width: 100,
//     // renderCell: (params) => {
//     //   return (
//     //     <div className={`cellWithStatus ${params.row.status}`}>
//     //       {params.row.status === 3 ? "Archive" :params.row.status === true ? "Active" : "Inactive"}
//     //     </div>
//     //   );
//     // },
//   },
// ];