import React from 'react'
import './master.scss'
import { useNavigate } from 'react-router-dom'
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { toast, ToastContainer } from 'react-toastify';
import { useEffect, useState } from "react"
import DynamicTable from "../../components/dynamic-table/DynamicTable"
import dataService from "../../redux/services/data.service"
import RegularizeModal from "../../components/common-function/RegularizeModal"
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';

import {
    domainList,
    // saveDomain 
} from "../../redux/features/domainSlice"
import { useDispatch, useSelector } from "react-redux"
import { STATUS, STATUSES } from "../../redux/common-status/constant"
const { REACT_APP_ENV } = process.env;

const Domain = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
    const userId = rtm_user.user.data.id;

    const { domains, status,
        // error, apiResponse, 
        count } = useSelector(state => state.domain)
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [skip, setSkip] = useState(0);
    const [params, setParams] = useState({})

    // Modal Related data
    const [open, setOpen] = useState(false)
    const [formLoading, setFormLoading] = useState(false)
    const [updating, setUpdating] = useState(false);
    const [editable, setEditable] = useState(false);

    const whereClause = { ...params, "skip": skip, "limit": pageSize || 10 }

    const [domainsData, setDomainsData] = useState({ })

    const modalDetail = {
        title: "Domain Details",
        editModalTitle: "Edit Domain",
        addModalTitle: "Add Domain",
        formInputs: [
            { id: 3, name: "name", label: "Domain Name", placeholder: "Enter Name", type: "text", required: true, disabled: false },
            { id: 3, name: "code", label: "Domain Code", placeholder: "Enter Code", type: "text", required: true, disabled: false },
            {
                id: 2, name: "status", label: "Status", placeholder: "Enter For Date", type: "select",
                options: [
                    { id: 1, label: "Active", value: true },
                    { id: 2, label: "Inactive", value: false },
                ],
                required: true, disabled: false
            },

            { id: 2, name: "description", label: "Descriptions", placeholder: "Enter Descriptions", type: "textarea", required: false, disabled: false },
        ],
    }

    const domainsColumns = [
        { field: "id", headerName: "ID", width: 70 },
        {
            field: "name",
            headerName: "Name",
            width: 400,
        },
        {
            field: "code",
            headerCode: "Code",
            width: 300,
        },

        {
            field: "status",
            headerName: "Status",
            width: 300,

            type: 'singleSelect',
            valueOptions: [
                { label: "Active", value: true },
                { label: "Inactive", value: false },
            ],
            renderCell: (params) => {
                return (
                    <div className={
                        STATUS[params.row.status.toString()]
                        + " " + "Active"
                    }
                        style={
                            params.row.status === true ? { color: '#0000FF' } :
                                params.row.status === false ? { color: '#ff0000' } :
                                    { color: '#000' }
                        }
                    >
                        <p
                        // className={STATUS[params.row.status]}
                        >
                            {STATUS[params.row.status.toString()]}

                        </p>
                    </div>
                );
            },
        },
    ];

    // console.log(domains);
    const onSubmit = async (data) => {
        const finalData = domainsData.id ? { ...domainsData, ...data, updated_by: userId } : { ...data, created_by: userId }
        const res = await dataService.save_domain(finalData);
        if (res.data && res.status === 200) {
            toast.success(res.data.message)
            dispatch(domainList(whereClause))
            onCloseModal();
        }
        else {
            toast.error(res.data.message)
        }
    }

    const onView = (data) => {
        setDomainsData({ ...data });
        setEditable(false);
        setOpen(true);

    }

    // Edit Domain
    const handleEdit = (data) => {
        setDomainsData({ ...domainsData, ...data, updatedBy: 1 });
        setUpdating(true)
        setEditable(true);
        setOpen(true);
    }

    // Delete Domain
    const deleteDomain = async (id) => {
        const res = await dataService.delete_domain(id)
        if (res && res.data && res.data.status === 200) {
            toast.success(res.data.message)
            dispatch(domainList(whereClause))
        } else {
            toast.error(res.data.message)
        }
    }

    //  Open Modal
    const onOpenModal = () => {
        setOpen(true)
    }

    //  AddDomain Modal
    const onAddDomain = () => {
        setOpen(true)
        setEditable(true);
    }

    //  Close Modal
    const onCloseModal = () => {
        setOpen(false)
        setUpdating(false);
        setEditable(false);
        setDomainsData({});
    }

    useEffect(() => {
        dispatch(domainList(whereClause))
    }, [skip, params])

    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />

                <div className="top-headings">
                    <h3 >Domains </h3>
                    <div>
                    <Tooltip title="Add Domain">
                    <button onClick={onAddDomain} className="btn-add-new ">
                    <AddIcon />
                    </button>
                    </Tooltip>
                        <Tooltip title="Back">
                        <button onClick={() => navigate(-1)} className="btn-add-new "><ArrowBackIcon /></button>
                        </Tooltip>
                        
                        
                    </div>
                </div>

                <ToastContainer />
                <>
                    {
                        <DynamicTable
                            moduleName="domains"
                            tableColumns={domainsColumns}
                            tableRows={domains || []}
                            currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
                            setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
                            paginationMode={"server"}
                            rowCount={count}
                            loading={status === STATUSES.LOADING}
                            columnVisibilityModel={{
                                id: false,
                            }}
                            onViewDetail={onView}
                            onEdit={handleEdit}
                            deleteRow={deleteDomain}
                        />
                    }
                </>
                {
                    // domainsData && domainsData.id &&
                    <RegularizeModal
                        modalDetail={modalDetail}
                        open={open}
                        onOpen={onOpenModal}
                        onClose={onCloseModal}
                        // onCancel={onCancel}
                        onSubmit={onSubmit}
                        // hideBackdrop
                        fullWidth
                        maxWidth
                        updating={updating}
                        editData={domainsData}
                        editable={editable}
                        formLoading={formLoading}
                        setFormLoading={setFormLoading}
                        additionalActions={modalDetail.additionalActions}
                        onAdditionalAction={() => {console.log("")}}
                    />
                }
            </div>
        </div>
    )
}

export default Domain