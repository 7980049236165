import "./loaderpickers.scss";
import React from 'react'
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { CirclesWithBar } from 'react-loader-spinner'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Loader = () => {
    const [open, setOpen] = React.useState(false);
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const success = () => toast.success("Wow so easy !");
    const info = () => toast.info("Wow so easy !");
    const warning = () => toast.warning("Wow so easy !");
    const error = () => toast.error("Wow so easy !");


    return (
        <div className="new">
            <Sidebar />

            <div className="newContainer">
                <Navbar />

                <div className="top-headings">
                    <h3>Page Loader</h3>
                </div>
                <div className="loader-pickers-col">
                    <CirclesWithBar
                        height="100"
                        width="100"
                        color="#0052cc"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        outerCircleColor=""
                        innerCircleColor=""
                        barColor=""
                        ariaLabel='circles-with-bar-loading'
                    />
                </div>

                <div className="loader-pickers-col">
                    <button className="modal-btn" variant="outlined" onClick={handleClickOpen}>
                        Message Confirm for delete
                    </button>

                    <button className="toast" onClick={success}>Success !</button>
                    <button className="toast" onClick={info}>Info !</button>
                    <button className="toast" onClick={warning}>Warning !</button>
                    <button className="toast" onClick={error}>Erorr !</button>
                    <ToastContainer />

                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >

                        <DialogContent>
                            <div className="popup-confirming">
                                <h3>Delete ?</h3>
                                <p>Let Google help apps determine location. This means sending anonymous
                                    location data to Google, even when no apps are running.</p>
                                <button className="yes" >Agree</button>
                                <button className="no" onClick={handleClose} autoFocus>
                                    Disagree
                                </button>
                            </div>

                        </DialogContent>

                    </Dialog>
                </div>




            </div>
        </div>
    )
}

export default Loader