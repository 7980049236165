import './manage-credential-heads/manageCredential.scss'
import React, { useState, useEffect, useMemo } from 'react'
import dataService from '../../redux/services/data.service';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination } from "@mui/material";
import LoaderForm from '../../components/common-function/LoaderForm';
import UcActionComponent from '../../components/common-function/UcActionComponent';
import { useDispatch, useSelector } from 'react-redux';
import { allResources, allcredentialHeads, getAllCredentials } from '../../redux/features/cedentialHeadSlice';
import Filter from '../../components/Filter';
import TablePaginationActions from '../../components/TablePaginationActions';
import { Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { STATUSES } from "../../redux/common-status/constant";
import { Add } from '@mui/icons-material';

export default function AllResourceCredential() {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { allCredentialsList, resourceList, credentialHeadList, status } = useSelector(state => state.credentialHead)
  const { data: list } = allCredentialsList;

  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null)
  const [showLoader, setShowLoader] = useState(false)

  //pagination 
  const [currentPage, setCurrentPage] = useState(0);
  const [filterQuery, setFilterQuery] = useState(`{"skip":0, "limit":10,"order_by":{"column":"-id"}}`)
  const [pageSize, setPageSize] = useState(10)
  const [limit, setLimit] = useState(10)

  const [filterResourceList, setFilterResourceList] = useState([]);
  const [filterHeadList, setFilterHeadList] = useState([]);

  // custom filter
  const [fieldName, setFieldName] = useState("")
  const [options, setOptions] = useState({})
  const [defaultValue, setDefaultValue] = useState({})

  useEffect(() => {
    dispatch(getAllCredentials(filterQuery));
    dispatch(allResources())
    dispatch(allcredentialHeads())
  }, [])

  if (status === `${STATUSES.SUCCESS}_resourceList` && filterResourceList && filterResourceList.length === 0 && resourceList && resourceList.data) {
    setShowLoader(false)
    let array = []
    resourceList.data && resourceList.data.rows.map(e => { return array.push({ value: e.id, label: e.name + "(" + e.email + ")" }) })
    array.length > 0 && setFilterResourceList(array)
    array.length > 0 && setOptions({ ...options, resource_id: array })
  }

  if (status === `${STATUSES.SUCCESS}_credentialHeadList` && filterHeadList && filterHeadList.length === 0 && credentialHeadList && credentialHeadList.data) {
    setShowLoader(false)
    let array = []
    credentialHeadList.data && credentialHeadList.data.rows.map(e => { return array.push({ value: e.id, label: e.head_name }) })
    array.length > 0 && setFilterHeadList(array)
    array.length > 0 && setOptions({ ...options, credentails_head_id: array })
  }

  const handleDelete = async (id) => {
    const request = { status: 3 }
    setOpen(true)
    if (open === true) {
      const response = await dataService.deleteResourceCredential(id, request)
      if (response && response.data) {
        toast.success(response.data.message);
        setOpen(false)
        dispatch(getAllCredentials(filterQuery));
      }
    }
  }

  const handleClickOpen = (type, id) => {
    setDeleteId(id)
    if (type === true) {
      handleDelete(id);
    }
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const onEdit = async (id) => {
    navigate(`/edit-resource-credential/${id}`, { state: "resource" })
  }

  const onAddBtn = () => {
    navigate('/add-resource-credential', { state: "resource" })
  }

  const actionColumn = [
    { field: "id", headerName: "ID", width: 70, filterable: false, },
    {
      field: "credentails_head_id",
      headerName: "Credentail Head",
      type: 'singleSelect',
      width: 280,
      valueOptions: filterHeadList,
      renderCell: (params) => {
        return (
          <div>
            {params.row.credentails_head}
          </div>
        );
      },
    },
    {
      field: "resource_id",
      headerName: "Resource Name",
      type: 'singleSelect',
      width: 280,
      valueOptions: filterResourceList,
      renderCell: (params) => {
        return (
          <div>
            {params.row.resource ? params.row.resource : "-"}
          </div>
        );
      },
    },
    {
      field: "is_global",
      headerName: "Is Global",
      width: 120,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.is_global}`}>
            {params.row.is_global === true ? 'true' : 'false'}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status === true ? 'Active' : 'Inactive'}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: "120",
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent moduleName="resource-credential" rowid={params.row.id} editLinkUrl="" viewLinkUrl={`/view-credential/${params.row.id}`} isDelete={true} editButton={onEdit} viewButton={false} deleteButton={handleClickOpen} />
          </div>
        );
      },
    },
  ];


  //custom pagination
  const handlePagination = (data) => {
    setCurrentPage(data)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = data * pageSize
    newFilterData['limit'] = limit
    dispatch(getAllCredentials(JSON.stringify(newFilterData)))
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.target.value)
    setCurrentPage(0)
    setLimit(newPageSize.target.value)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0
    newFilterData['limit'] = newPageSize.target.value
    dispatch(getAllCredentials(JSON.stringify(newFilterData)))
  }

  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={(list && list.rows && list.count) || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) =>
          <TablePaginationActions {...subProps}
            handlePagination={handlePagination}
          />}
      />
    );
  }

  // filter
  const onFilterChange = (filterModel) => {
    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
      setFieldName(filterModel.items[0].columnField)
    }
    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
      let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
        filterModel.items[0].operatorValue === "equals" ? 'ieq' :
          filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
              filterModel.items[0].operatorValue === "is" ? 'eq' :
                filterModel.items[0].operatorValue;
      let field = filterModel.items[0].columnField === 'resource' ? "resource_id" :
        filterModel.items[0].columnField === 'credentails_head' ? "credentails_head_id" :
          filterModel.items[0].columnField;

      const filterData = `{"filter":{"${[field]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
      setFilterQuery(filterData);
      setCurrentPage(0);
      dispatch(getAllCredentials(filterData));

    }
    if (filterModel.items && filterModel.items.length === 0) {
      setCurrentPage(0);
      setPageSize(10)
      setFilterQuery(`{"skip":0, "limit":10,"order_by":{"column":"-id"}}`);
      dispatch(getAllCredentials(`{"skip":0, "limit":10,"order_by":{"column":"-id"}}`));
      setDefaultValue("")
    }
  }

  // Custom filter
  function CustomFilterInputValue(props) {
    const { item, applyValue } = props;
    const handleFilterChange = (evt) => {
      if (evt) {
        setDefaultValue(evt)
        const { value } = evt;
        // console.log(value, "value")
        applyValue({ ...item, value: value });
      }
    };
    return (
      <Filter options={options[fieldName]} onChange={handleFilterChange} defaultValue={defaultValue} />
    );
  }

  const columns = useMemo(() =>
    actionColumn.map((col) => {
      if (col.field === "status" || col.field === "is_global") {
        return col;
      }
      return {
        ...col,
        filterOperators: getGridSingleSelectOperators()
          .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
          .map((operator) => ({
            ...operator,
            InputComponent: operator.InputComponent
              ? CustomFilterInputValue
              : undefined
          }))
      };
    }),
    [actionColumn]
  );

  return (
    <div className="listing">
      <Sidebar />
      <div className="listingContainer">
        <Navbar />

        <div className="datatable">
          <div className="datatableTitle">
            <h3>All Credential</h3>
            {/* <Link to="/add-resource-credential" className="btn-add-new ">
                  Add New Resource Credential
                </Link> */}
           
            <Tooltip title="Add New Resource Credential">
            <button onClick={onAddBtn} className="btn-add-new ">
              <AddIcon />
            </button>
            </Tooltip>

          </div>

          <ToastContainer />
          {(status === STATUSES.LOADING || showLoader) && <LoaderForm />}
          <DataGrid
            className="datagrid"
            columnVisibilityModel={{
              id: false
            }}
            rows={list && list.rows ? list.rows : []}
            columns={columns}
            pageSize={pageSize}
            style={{ fontSize: 16 }}
            onFilterModelChange={onFilterChange}
            components={{
              Pagination: CustomPagination,
            }}
          />
        </div>

        {/* for delete popup */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">

          <DialogContent>
            <div className="popup-confirming">
              <h3>Delete ?</h3>
              <p>Are you sure to remove this credential</p>
              <button className="yes" onClick={() => handleClickOpen(true, deleteId)}>Yes</button>
              <button className="no" onClick={() => setOpen(false)} autoFocus>
                Cancel
              </button>
            </div>
          </DialogContent>

        </Dialog>

      </div>

    </div >
  )
}
