const { REACT_APP_ENV } = process.env;
class LocalStorageDataService {

    userDetail() {
        return localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
    }

    userToken() {
        return localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).token : "";
    }

    priority_list() {
        return ["High", "Medeium", "Normal"]
    }
    labels_list() {
        return ["API", "UI", "UX"]
    }
    lastInteractionTime() {
        return localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).lastInteractionTime : "";
    }
    regularize_type() {
        return [{ value: '-1', label: "select" }, { value: 1, label: "Regularize Punch In Time" }, { value: 2, label: "Regularize Punch Out Time" }, { value: 0, label: "Regularize Both" }]
    }
    regularize_status_type() {
        return [{ value: 4, label: "PENDING" }, { value: 7, label: "REJECTED" }, { value: 5, label: "APPROVED" }, { value: 6, label: "CANCELLED" }]
    }
    get_default_column() {
        let user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
        return localStorage.getItem(`${REACT_APP_ENV}_${user.id}_task`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_${user.id}_task`)) : "";
    }
    get_default_work_mode() {
        return ["Work From Home", "Work From Office", "Hybrid"]
    }
    get_default_month_year() {
        return ["Months", "Years"]
    }

}
export default new LocalStorageDataService();