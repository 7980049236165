import { useState, useEffect } from 'react'
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./task.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Dropzone from 'react-dropzone'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { getCommentId, getFileUploadList, getMYTaskList, getTaskHistory, postCommentCreate, resourceProjectList, taskAssigneeUpdateById, taskStatusUpdate, taskViewById, taskWorkFlowStatusList, postTeamMemberCreate, getTeamMemberList } from '../../redux/features/taskSlice';
import {Tab, Tabs, Avatar, Button, Autocomplete, TextField, Box, TablePagination } from '@mui/material';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import LoaderForm from "../../components/common-function/LoaderForm";
import { STATUSES } from '../../redux/common-status/constant';
import moment from 'moment';
import { postUploadResume } from '../../redux/features/masterSlice';
import { resourceListForTaskEdit } from "../../redux/features/resourceSlice";
import { CKEditor } from 'ckeditor4-react';
import dataService from '../../redux/services/data.service';
import { toast, ToastContainer } from 'react-toastify';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import TaskBreadCrumb from '../../components/breadcrumb/TaskBreadCrumb';
import { UploadFile, FormatAlignJustify, ArrowBack } from '@mui/icons-material';
// import RichTextEditor from './RichTextEditor';
import TablePaginationActions from "../../components/TablePaginationActions";


function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}
function firstLetterWord(str) {
    let result = "";

    // Traverse the string.
    let v = true;
    for (let i = 0; i < str.length; i++) {
        // If it is space, set v as true.
        if (str[i] === ' ') {
            v = true;
        }

        // Else check if v is true or not.
        // If true, copy character in output
        // string and set v as false.
        else if (str[i] !== ' ' && v === true) {
            result += (str[i]);
            v = false;
        }
    }
    return result;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: firstLetterWord(name),
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`horizontal-tabpanel-${index}`}
            aria-labelledby={`horizontal-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}
const columnsFile = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
        field: 'file_name',
        headerName: 'File Name',
        width: 300,
    },
    {
        field: 'status',
        headerName: 'File Type',
        width: 200,
        renderCell: (params) => {
            return (
                <div className="cellWithStatus">
                    {params.row.status === 0 ? "Pending" : params.row.status === 1 ? " File Inprogress" : params.row.status === 2 ? "Parsed" : params.row.status === 3 ? "Processing Done" : params.row.status === 4 ? "Invalid File" : "Unable to Process"}
                </div>
            )
        }

    },
];

export const EpicTask = () => {
    const { epicId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [value, setValue] = useState(0)
    const [apiLoading, setApiLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState(null);
    const [formValues, setFormValues] = useState({})
    const [selected, setSelected] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [memberLoader, setMemberLoader] = useState(true)
    const [member_List, setMember_List] = useState([]);
    const [selectedMember, setSelectedMember] = useState([]);
    const [selectedMemberLoader, setSelectedMemberLoader] = useState(false);
    const [assignee_name_default, setAssignee_name_default] = useState("")
    const [teamMembers, setTeamMembers] = useState([])
    const [teamMembersStaus, setTeamMembersStatus] = useState(false)
    const [commentActive, setCommentActive] = useState(false)
    const [comment, setComment] = useState("")
    const [fileErr, setFileErr] = useState("")
    const [activeTab, setActiveTab] = useState(0)
    const [activeMultiEpic, setActiveMultiEpic] = useState(false)
    const [issueSelectedAssignee, setIssueSelectedAssignee] = useState({})
    const [mytaskliststatus, setmytaskliststatus] = useState(false)
    const [multiStatusList, setMultiStatusList] = useState([])
    const [updateTeamMemberListForEpic, setUpdateTeamMemberListForEpic] = useState(false)
    const { status, teamMemberStatus, taskData, myTaskList, memberList, commentById, resourceProject_list, taskHistoryList, teamMemberList, taskAssigneeUpdate, uploadFileList } = useSelector(state => state.task)
    const { createUploadResume } = useSelector(state => state.master)
    const { resourceListForTsk } = useSelector(state => state.resource)
    const [fileButtonActive, setFileButtonActive] = useState(false)
    const [relatedLoader, setRelatedLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [limit, setLimit] = useState(10);
    const [fieldName, setFieldName] = useState("")
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            setActiveTab(0)
            setMultiStatusList([])
            setmytaskliststatus(true)
            dispatch(getMYTaskList(`{"filter":{"project_id":{"value":${formValues.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"skip":0, "limit":10}`))
        }
        if (newValue === 1) {

        }
        if (newValue === 2) {
            setActiveTab(2)
            dispatch(getFileUploadList(`{"filter":{"epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`))
        }

        if (newValue === 3) {
            setActiveTab(3)
            dispatch(getCommentId(epicId))
        }
        if (newValue === 4) {
            setActiveTab(4)
            dispatch(getTaskHistory(`{"filter":{"rtms_issue_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`))

        }
    }

    const bindMultiStatus = async (id) => {
        let array = []
        let object
        const request = await dataService.get_task_By_id(id)
        const result = request.data

        if (result.status === 200) {
            let statusList = result.data.status_data ? result.data.status_data : []
            object = { issue_id: id, status_data: statusList, status_id: result.data.status_id }
            array.push({ issue_id: id, status_data: statusList, status_id: result.data.status_id })
            //array.push({issue_id: id, assignee: `${id}_${result.data.assignee_id}`})
            setIssueSelectedAssignee({ ...issueSelectedAssignee, issue_id: id, assignee: `${id}_${result.data.assignee_id}` })
            setMultiStatusList(oldArray => [...oldArray, object]);           
        }


    }


    if (activeTab === 0 && myTaskList && myTaskList.data) {
        setActiveTab(false)
    }
    if (activeTab === 2 && uploadFileList && uploadFileList.data) {
        setActiveTab(false)
    }
    if (activeTab === 3 && commentById && commentById.data) {
        setActiveTab(false)
    }
    if (activeTab === 4 && taskHistoryList && taskHistoryList.data) {
        setActiveTab(false)
    }

    const assignToMulti = async (e, item, type) => {
        let userId = LocalStorageDataService.userDetail().id
        setActiveMultiEpic(true)
        if (type === 'unassign') {
            setIssueSelectedAssignee({ ...issueSelectedAssignee, issue_id: item.id, assignee: `${item.id}_0` })
            setMultiStatusList([])
            setRelatedLoader(item.id)
            dispatch(taskAssigneeUpdateById({ issue_id: item.id, resource_id: 0 }))

        }
        if (type === 'assign') {
            setRelatedLoader(item.id)
            const request = await dataService.get_task_By_id(item.id)
            const result = request.data
            if (result.status === 200) {
                if (!result.data.assignee_id) {
                    setIssueSelectedAssignee({ ...issueSelectedAssignee, issue_id: item.id, assignee: `${item.id}_${userId}` })
                    setMultiStatusList([])
                    dispatch(taskAssigneeUpdateById({ issue_id: item.id, resource_id: userId }))
                }
                else {
                    setMultiStatusList([])
                    setTimeout(() => {
                        setRelatedLoader(false)
                    }, 5000);
                    setActiveMultiEpic(false)
                    toast.warning("You Can't changed assignee already assigned another member!")
                }
            } else {
                if (result.message) {
                    setTimeout(() => {
                        setRelatedLoader(false)
                    }, 5000);
                    //setMultiStatusList([])
                    setActiveMultiEpic(false)
                    toast.error(result.message)
                }
            }
        }

    }


    if (activeMultiEpic === true && taskAssigneeUpdate && taskAssigneeUpdate.data) {
        setActiveMultiEpic(false)
        setApiLoading(true)
        setTimeout(() => {
            setRelatedLoader(false)
        }, 5000);
        dispatch(taskViewById(epicId))
        //setActiveTab(0)
        // setmytaskliststatus(true)
        // dispatch(getMYTaskList(`{"filter":{"project_id":{"value":${formValues.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"no_limit":True}`))
    }

    const assignToMe = (e, item) => {
        e.preventDefault()
        let userId = LocalStorageDataService.userDetail().id
        //assignee_name_default = { label: LocalStorageDataService.userDetail().display_name, value: userId }

        setLoading(true)
        if (item === 'unassign') {
            setAssignee_name_default("")
            setMultiStatusList([])
            dispatch(taskAssigneeUpdateById({ issue_id: epicId, resource_id: 0 }))

        } if (item === 'assign') {
            setAssignee_name_default({ key: userId, label: LocalStorageDataService.userDetail().display_name, value: userId, name: 'assignee' })
            setMultiStatusList([])
            dispatch(taskAssigneeUpdateById({ issue_id: epicId, resource_id: userId }))
        } else {
            //setAssignee_name_default({ key: userId, label: LocalStorageDataService.userDetail().display_name, value: userId, name: 'assignee' })
            setMultiStatusList([])
            dispatch(taskAssigneeUpdateById({ issue_id: item.id, resource_id: userId }))

        }
        //setAssignee_name_default({ key: userId, label: LocalStorageDataService.userDetail().display_name + "(" + LocalStorageDataService.userDetail().email + ")", value: userId, name: 'assignee' })
    }

    const checkAssignedfield = () => {
        let status = true
        if (teamMembers.length > 0) {
            teamMembers.map(e => {
                if (e.value === LocalStorageDataService.userDetail().id) {
                    status = false
                }
                return status
            })
        }
        // if (formValues.created_by === LocalStorageDataService.userDetail().id) {
        //     status = false
        // }
        return status
    }
    const changeRelatedStatus = (evt, task_id) => {
        if (evt) {
            const { name, value } = evt.target || evt;
            if (name === "status_name") {
                setLoading(true)
                setRelatedLoader(task_id)
                setMultiStatusList([])
                const transition = statusData.filter(x => (x.status_id).toString() === value ? x : "")
                dispatch(taskStatusUpdate({ issue_id: task_id, from_status_id: formValues.t_status ? formValues.t_status : value, to_status_id: value, transition_id: transition.length > 0 ? transition[0].transition_id : null }))
            }
        }
    }


    const changeMultiStatus = (evt, item) => {
        if (evt) {
            const { name, value } = evt.target || evt;
            setLoading(true)
            setMultiStatusList([])
            setRelatedLoader(item.id)
            dispatch(taskAssigneeUpdateById({ issue_id: item.id, resource_id: value }))
        }
    }

    const changeStatus = (evt) => {
        if (evt) {
            const { name, value } = evt.target || evt;

            if (name === "status_name") {
                setLoading(true)
                const transition = statusData.filter(x => (x.status_id).toString() === value ? x : "")
                // const status_name_find = statusData.filter(x => x.status_id === value)
                // setSelectedStatus({label:status_name_find[0].status_name, value:value})
                setLoading(true)
                dispatch(taskStatusUpdate({ issue_id: epicId, from_status_id: formValues.t_status ? formValues.t_status : value, to_status_id: value, transition_id: transition.length > 0 ? transition[0].transition_id : null }))
            }
            if (name === 'assignee') {
                setLoading(true)
                // if (typeof value === 'string') {
                //     let value1 = value.replace('unassign_', '')
                //     dispatch(taskAssigneeUpdateById({ issue_id: value1, resource_id: 0 }))
                // } else {
                setMultiStatusList([])
                dispatch(taskAssigneeUpdateById({ issue_id: epicId, resource_id: value }))

                //}
            }
            if (name === "lables") {
                setSelected(value)
                setFormValues({ ...formValues, labels: value })
            }
            // if (evt.length > 0) {
            //     setSelectedMember(evt)
            //     setFormValues({ ...formValues, team_members: evt })
            // }
        }
    }
    const updateTeamMember = (members) => {
        // if (multiStatusList && multiStatusList.length === 0) {

        //setUpdateTeamMemberStatus(true)
        //let uniqueObjArray = [...new Map(members.map((item) => [item["id"], item.id])).values()];
        let array = []
        members.map(e => {
            array.push(e.value)
        })
        let teamMemberData = {
            "jira_rtms_issue": epicId,
            "resource": array,
            "status": 1,
            "created_by": LocalStorageDataService.userDetail().id
        }
        // if (myTaskList && myTaskList.data && myTaskList.data.rows && myTaskList.data.rows.length > 0) {
        //     myTaskList.data.rows.map(item => {
        //         let teamMemberDataForStory = {
        //             "jira_rtms_issue": item.id,
        //             "resource": array,
        //             "status": 1,
        //             "created_by": LocalStorageDataService.userDetail().id
        //         }
        //         dispatch(postTeamMemberCreate(teamMemberDataForStory))
        //     })
        // }

        dispatch(postTeamMemberCreate(teamMemberData))
        setSelectedMember(members);
    }


    // if(updateTeamMemberStatus === true && createTeamMember && createTeamMember.status === 200){
    //     setUpdateTeamMemberStatus(false)
    //     setApiLoading(true)
    //     dispatch(taskViewById(epicId))
    // }

    useEffect(() => {
        if (epicId) {
            setApiLoading(true)
            dispatch(taskViewById(epicId))
            dispatch(resourceListForTaskEdit())
            dispatch(getTeamMemberList(`{"filter":{"jira_rtms_issue_id":{"value":${epicId}, "operation":"eq"},"status":{"value":True, "operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`))
            }
        setTimeout(() => {
            setSelectedMemberLoader(true)
        }, 500);
        setTimeout(() => {
            setApiLoading(false)
            setActiveTab(false)
            setRelatedLoader(false)
        }, 2000);

    }, [])

    if (memberLoader === true && memberList && memberList.data) {
        let array = []
        memberList.data.rows.map(e => {
            array.push({ display_name: e.resource.display_name, assignee_id: e.resource_id, jira_rtms_issue_id: e.jira_rtms_issue_id, actual_id: e.id })
        })
        setMember_List(array)
        setMemberLoader(false)
    }


    if (loading === true && file && file[0].name) {
        setFile(null)
        setLoading(false)
        setApiLoading(true)
        dispatch(taskViewById(epicId))
    }
    if (loading === 'comment_true' && status === `${STATUSES.SUCCESS}_createComment`) {
        setComment("")
        setLoading(false)
        setActiveTab(3)
        dispatch(getCommentId(epicId))
        // setApiLoading(true)
        // dispatch(taskViewById(epicId))
    }

    if (loading === 'upload_true' && createUploadResume && createUploadResume.status) {
        if (createUploadResume.status === 200) {
            toast.success(createUploadResume.message)
        } else {
            toast.error(createUploadResume.message)
        }
        setFile("")
        setFileButtonActive(false)
        setLoading(false)
        setActiveTab(2)
        dispatch(getFileUploadList(`{"filter":{"epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`))

        // setApiLoading(true)
        // dispatch(taskViewById(epicId))
    }

    if (loading === true && status === `${STATUSES.SUCCESS}_taskStatusData`) {
        setLoading(false)
        setApiLoading(true)
        setTimeout(() => {
            setRelatedLoader(false)
        }, 5000);

        dispatch(taskViewById(epicId))
    }
    if (loading === true && status === `${STATUSES.SUCCESS}_taskAssigneeUpdate`) {
        setLoading(false)
        setApiLoading(true)
        dispatch(taskViewById(epicId))
    }
    if (apiLoading === true && status === `${STATUSES.SUCCESS}_taskHistoryList`) {
        setApiLoading(false)
    }
    if (apiLoading === true && status === `${STATUSES.SUCCESS}_commentById`) {
        setApiLoading(false)
    }
    if (updateTeamMemberListForEpic !== false && teamMemberList && teamMemberList.data) {
        if (teamMemberList && teamMemberList.data && teamMemberList.data.rows && teamMemberList.data.rows.length === 0) {
            setSelectedMember(updateTeamMemberListForEpic)
            updateTeamMember(updateTeamMemberListForEpic)
        }
        setUpdateTeamMemberListForEpic(false)
    }
    if (selectedMemberLoader === true && teamMemberStatus === `${STATUSES.SUCCESS}_teamMemberList`) {
        let array = []
        if (teamMemberList && teamMemberList.data && teamMemberList.data.rows && teamMemberList.data.rows.length > 0) {
            teamMemberList.data.rows.map(e => {
                if (e.status === true) {
                    array.push({
                        label: e.resource.display_name,
                        value: e.resource.id
                    })
                }
                return array
            })
        }
        setSelectedMemberLoader(false)
        setSelectedMember(array)
    }

    if (mytaskliststatus === true && myTaskList && myTaskList.data) {
        let array
        let array2 = []
        if (myTaskList.data.rows && myTaskList.data.rows.length > 0) {
            myTaskList.data.rows.map(e => {
                bindMultiStatus(e.id, array, array2)
            })
        }
        // setIssueSelectedAssignee(array)
        // setMultiStatusList(array2)

        setmytaskliststatus(false)
    }



    // useEffect(() => {
    if (apiLoading === true && status === `${STATUSES.SUCCESS}_taskData`) {

        let data = taskData.data
        if (taskData.status === 400) {
            toast.error(taskData.message)

        }
        setApiLoading(false)
        setFormValues(data)
        if (data && data.status_data) {
            let arrayForEpicStatus = []
            if (data.status_data.length > 0) {
                data.status_data.map(e => {
                    if ((e.status_name).match("backlog") || (e.status_name).match("closed") || (e.status_name).match("process") || (e.status_name).match("progress")) {
                        arrayForEpicStatus.push(e)
                    }
                })
            }
            setStatusData(arrayForEpicStatus)
        }
        if (data && data.assignee) {
            setAssignee_name_default({ label: data.assignee.display_name, value: data.assignee.id })
            if (data.assignee_id) {
                let team_member = [{ label: data.assignee.display_name, value: data.assignee.id }]
                //setSelectedMember(team_member)
                //updateTeamMember(team_member)
                setUpdateTeamMemberListForEpic(team_member)
            }

        }

        if (data && data.project) {
            setTeamMembersStatus(true)
             dispatch(resourceProjectList(`{"filter":{"project":{"value":${data.project.id},"operation":"eq"}}, "no_limit":True}`))
            setmytaskliststatus(true)
            dispatch(getMYTaskList(`{"filter":{"project_id":{"value":${data.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"skip":0, "limit":10}`))
        }

    }
    if (teamMembersStaus === true && status === `${STATUSES.SUCCESS}_resourceProject_list` && resourceProject_list) {
        setTeamMembersStatus(false)
        let array = []

        if (resourceProject_list && resourceProject_list.length > 0) {
            resourceProject_list.map(e => {
                return array.push({ label: e.display_name, value: e.id })
            })
        }
        setTeamMembers(array)
    }
    const inputHandler = (evt) => {
        var newContent = evt.editor.getData();
        setComment(newContent)
        // Define your onSubmit function here
        // ...
        // for example, setData() here

    }


    //}, [apiLoading, taskData])
    const uploadFile = () => {
        const formData = new FormData();
        formData.append("file", file[0]);
        formData.append("project", `${formValues.project.id}`);
        formData.append("epic", `${epicId}`);
        setLoading('upload_true')
        dispatch(postUploadResume(formData))
    }

    const editCommentChanges = (name) => {

        const parser = new DOMParser();
        const doc = parser.parseFromString(comment, 'text/html');

        // might be a more efficient way to do this. just querying for anchors
        // and filtering out the ones that don't have a <sup> child
        const anchors = [...doc.querySelectorAll('a')]
        setCommentActive(false)
        let data = {
            "jira_issue_id": formValues.ticket_jira_key,
            "rtms_issue": epicId,
            "comment_url": anchors && anchors.length > 0 ? anchors[0].innerText : "",
            "is_jira_attachment": false,
            "body": comment,
            "created_resource": LocalStorageDataService.userDetail().id,
            "status": true,
            "created_by": LocalStorageDataService.userDetail().id

        }
        setLoading('comment_true')
        dispatch(postCommentCreate(data))
        //dispatch(taskUpdateById({ id: taskId, addvalues: { [name]: value } }))
    }

    const calculateDays = (startDate) => {
        if (new Date() === new Date(startDate)) {
            return 'Toady'
        }
        const diffInMs = new Date() - new Date(startDate)
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        return parseInt(diffInDays) + "  " + "days ago";
    }

    const disabledAssigne = () => {
        let statusIs = false;
        if (selectedMember && selectedMember.length > 0) {
            selectedMember.map(e => {
                if (e.value === LocalStorageDataService.userDetail().id) {
                    statusIs = true
                }
                return statusIs
            })
        }
        return statusIs

    }

    const onFileChange = (event) => {
        let file = event[0]
        if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === "application/pdf" || file.type === "application/zip") {
            setFileErr("")
            setFile(event);

        } else {
            setFile("");
            setFileErr("You can only upload pdf, docx, doc and zip files")
        }
    }
    const columnsRelatedStory = [
        {
            field: 'ticket_key',
            headerName: 'RTMS Key',
            width: 300,
            renderCell: (params) => {
                return (
                    <div className="related-storys-colum1">
                        <Link to={`/story-task/${params.row.id}`}>{params.row.ticket_key}</Link>
                    </div>
                )
            }
        },
        {
            field: 'summary',
            headerName: 'Summary',
            width: 400,
            renderCell: (params) => {
                return (
                    <div className="related-storys-colum1">
                        <Link to={`/story-task/${params.row.id}`}>{params.row.summary}</Link>
                    </div>
                )
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 500,
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="related-storys-colum3 custom-loader-tabs2">
                        {relatedLoader === params.row.id && <LoaderForm/>}
                        {params.row.assignee && params.row.assignee !== LocalStorageDataService.userDetail().id ?
                            <button disabled className='btn'>{params.row && params.row.assignee_name}</button>
                            :
                            params.row.assignee === LocalStorageDataService.userDetail().id ?
                                // <li className="custom-loader-tabs" style={relatedLoader !== false ? { pointerEvents: 'none' } : {}}>
                                <>
                                    <select disabled={relatedLoader === params.row.id ? true : false} name='assignee' value={params.row.assignee} onChange={(evt) => changeMultiStatus(evt, params.row)} className="select-status-ticket">
                                        {params.row.assignee === LocalStorageDataService.userDetail().id && <option value={params.row.assignee} >{params.row.assignee_name}</option>}
                                        {selectedMember && selectedMember.length > 0 &&
                                            selectedMember.map((items, index) => {
                                                return (
                                                    <option key={index} value={items.value}>{items.label}</option>
                                                )
                                            })}
                                    </select>
                                    <button style={relatedLoader === params.row.id ?{pointerEvents:'none', backgroundColor:'#ddd', border:'1px solid #ddd', color:'#000'}: {}} onClick={(e) => assignToMulti(e, params.row, 'unassign')} className='btn assign-btn' >{'Unassign'}</button>
                                </>
                                :
                                checkAssignedfield() === false ?
                                    // <li className="custom-loader-tabs" style={relatedLoader !== false ? { pointerEvents: 'none' } : {}}>

                                    <button style={relatedLoader === params.row.id ?{pointerEvents:'none', backgroundColor:'#ddd', border:'1px solid #ddd', color:'#000'}: {}} onClick={(e) => assignToMulti(e, params.row, 'assign')} className='btn assign-btn'>{'Assign to me'}</button>

                                    :

                                    <button disabled className='btn' >{'Select'}</button>

                        }
                        {/* <li style={relatedLoader !== false ? { pointerEvents: 'none' } : {}}> */}
                        {params.row.assignee === LocalStorageDataService.userDetail().id ?
                            multiStatusList && multiStatusList.length > 0 &&
                            multiStatusList.map((items, i) => {
                                return items && items.issue_id === params.row.id &&
                                    <select disabled={relatedLoader === params.row.id ? true : false} name='status_name' value={items.status_id} onChange={(evt) => changeRelatedStatus(evt, params.row.id)} className="select-status-ticket">
                                        {items.status_data && items.status_data.length > 0 &&
                                            items.status_data.map((itm, index) => {
                                                return (
                                                    <option key={index} value={itm.status_id}>{itm.status_name}</option>
                                                )
                                            })}
                                    </select>
                            })
                            :
                            (params.row.assignee !== LocalStorageDataService.userDetail().id) ?
                                <button disabled style={{ marginLeft: '5px' }} className='btn' >{params.row && params.row.status_name ? params.row.status_name : 'select'}</button>
                                :
                                (checkAssignedfield() === false && !params.row.assignee) ?
                                    statusData && statusData.length > 0 ?
                                        <select name='status_name' value={params.row.status} onChange={(evt) => changeRelatedStatus(evt, params.row.id)} className="select-status-ticket">

                                            {statusData.map((itm, index) => {
                                                return (
                                                    <option key={index} value={itm.status_id}>{itm.status_name}</option>
                                                )
                                            })}
                                        </select>
                                        :
                                        <button style={{ marginLeft: '5px' }} disabled className='btn' >{'Workflow not assigned'}</button>
                                    :
                                    <button style={{ marginLeft: '5px' }} disabled className='btn' >{params.row && params.row.status_name ? params.row.status_name : 'select'}</button>

                        }
                        {/* </li> */}
                    </div>
                )
            }
        }
    ]

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let filterQuery = `{"filter":{"project_id":{"value":${formValues.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"skip":0, "limit":10}`
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setActiveTab(0)
        setMultiStatusList([])
        setmytaskliststatus(true)
        dispatch(getMYTaskList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        let filterQuery = `{"filter":{"project_id":{"value":${formValues.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"skip":0, "limit":10}`
        setPageSize(newPageSize.target.value)
        setActiveTab(0)
        setMultiStatusList([])
        setmytaskliststatus(true)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        dispatch(getMYTaskList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(myTaskList && myTaskList.data && myTaskList.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }
    const [show, setShow] = useState(false)
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
            setFieldName(filterModel.items[0].columnField)
        }
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "onOrAfter" ? 'date_gte' :
                            filterModel.items[0].operatorValue === "after" ? 'date_gt' :
                                filterModel.items[0].operatorValue === "before" ? 'date_lt' :
                                    filterModel.items[0].operatorValue === "onOrBefore" ? 'date_lte' :
                                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue;
            const filterData = `{"filter":{"project_id":{"value":${formValues.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":10}`
            setCurrentPage(0)
            dispatch(getMYTaskList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            dispatch(getMYTaskList(`{"filter":{"project_id":{"value":${formValues.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"skip":0, "limit":10}`))
        }
    }
    return (
        <div className="task">
            <Sidebar />
            <div className="taskContainer">
                <Navbar />
                <div className="datatable">

                    <div className="top-headings">
                        {/* <h3>Epic Task</h3> */}
                        <div>
                            {
                                formValues && formValues.project && formValues.project.project_name 
                                ?
                                    <TaskBreadCrumb formValues={formValues} />
                                :
                                    <p>Project</p>
                            }
                            <button style={{ borderWidth: 0, backgroundColor: '#fff' }} ><h3  className='story-epic-summary'>{formValues && formValues.summary}</h3></button>

                        </div>

                        <Tooltip title="Back">
                            <button onClick={() => navigate(-1)} className="btn-add-new"><ArrowBack /></button>
                        </Tooltip>
                    </div>
                    <ToastContainer />
                    {(apiLoading === true || loading === true) && <LoaderForm />}
                    <div className="edit-details-row">
                        <div className={`edit-details-col-left ${show ? 'show-hide-left-side-colum1' : ''}`}>
                            <div className="left-description">
                                <h6>Description
                                    <Tooltip title="Open Right Side Details">
                                        <span style={{ float: "right" }}>
                                            <IconButton onClick={() => { setShow(!show) }} aria-label="delete">
                                                <FormatAlignJustify />
                                            </IconButton>

                                        </span>
                                    </Tooltip>
                                </h6>
                                <p style={{ marginBottom: 20 }} > <span dangerouslySetInnerHTML={{ __html: formValues && formValues.description }} /></p>
                                {/* {formValues?.description ? <RichTextEditor data={JSON.parse(formValues.description )} /> : ""} */}
                            </div>


                            <h4>Activity:</h4>

                            <div className="left-description">
                                <div className="epik-tabs">
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        variant="scrollable"
                                        scrollButtons={false}
                                        aria-label="scrollable prevent tabs example"
                                    >
                                        <Tab label="Related Stories" />
                                        <Tab label="Suggested Stories" />
                                        <Tab label="Upload Resumes" />
                                        <Tab label="Comments" />
                                        <Tab label="History" />
                                    </Tabs>


                                    <TabPanel value={value} index={0} className="custom-loader-tabs1">
                                        {(activeTab === 0) && <LoaderForm />}
                                        <p className="related-stories-head">Relates to stories</p>
                                        <DataGrid
                                             columnVisibilityModel={columnVisibilityModel}
                                             onColumnVisibilityModelChange={(newModel) =>
                                                 setColumnVisibilityModel(newModel)
                                             }
                                            //style={{ marginTop: "20px", height: "unset" }}
                                            rows={myTaskList && myTaskList.data && myTaskList.data.rows && myTaskList.data.rows || []}
                                            columns={columnsRelatedStory}
                                            pageSize={pageSize}
                                            //loading={relatedLoader === false ? false : true}
                                            //rowsPerPageOptions={[5]}
                                            //checkboxSelection
                                            disableSelectionOnClick
                                            experimentalFeatures={{ newEditingApi: true }}
                                            components={{
                                                Pagination: CustomPagination,
                                            }}
                                            onPageSizeChange={(pageSize) => handlePageSizeChange(pageSize)}
                                            onFilterModelChange={onFilterChange}
                                           
                                        />
                                    </TabPanel>
                                    <TabPanel value={value} index={1} className="custom-loader-tabs">
                                        <p className="related-stories-head">Suggested stories</p>
                                        <div className="related-stories-row">
                                            <div className="related-storys-colum4">
                                                <ul>
                                                    <li><Link to={"/"} title="Anchal Gupta"><span className="short-name">AG</span> Anchal Gupta</Link></li>

                                                </ul>
                                            </div>
                                            <div className="related-storys-colum5">
                                                <p>00000000</p>
                                            </div>

                                            <div className="related-storys-colum6">
                                                <p className="match-color">89% Match</p>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={2} className="custom-loader-tabs">
                                        {(loading === 'upload_true' || activeTab === 2) && <LoaderForm />}
                                        {formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id && <Button style={{ marginTop: 10, backgroundColor: '#0052cc', color: '#fff' }} endIcon={<UploadFile />} onClick={() => setFileButtonActive(!fileButtonActive)} >Upload New File</Button>}                                        {formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id ?
                                            <div>
                                                {fileButtonActive === true && <p className="related-stories-head"> Upload Resume(PDF/ZIP/Docx/Doc)</p>}
                                                {fileButtonActive === true && <div className="file-dropzone-tabs">
                                                    <Dropzone onDrop={acceptedFiles => onFileChange(acceptedFiles)}>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <section>
                                                                <div {...getRootProps()}>
                                                                    <input {...getInputProps()} />
                                                                    <p>{file ? file[0].name : "Drag 'n' drop some files here, or click to select files"}</p>
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                    {fileErr !== "" && <p style={{ color: "#96332c", fontSize: 10 }}>{fileErr}</p>}
                                                    {fileErr == "" && loading === false && file && file[0].name && <Button onClick={() => uploadFile()}>submit</Button>}
                                                </div>}
                                                <DataGrid
                                                    columnVisibilityModel={{
                                                        id: false
                                                    }}
                                                    style={{ marginTop: "20px", height: "unset" }}
                                                    rows={uploadFileList && uploadFileList.data && uploadFileList.data.rows || []}
                                                    columns={columnsFile}
                                                    pageSize={10}
                                                    rowsPerPageOptions={[5]}
                                                    //checkboxSelection
                                                    disableSelectionOnClick
                                                    experimentalFeatures={{ newEditingApi: true }}
                                                />
                                            </div>

                                            :
                                            <div>
                                                <p>Not Allowed!</p>
                                            </div>
                                        }
                                    </TabPanel>
                                    <TabPanel value={value} index={3} className="custom-loader-tabs">
                                        {(activeTab === 3) && <LoaderForm />}
                                        <div className="bottom-comments-row">
                                            <div className="bottom-commrnt-col-left">
                                                <span style={{ textTransform: 'capitalize' }}><Avatar {...stringAvatar(LocalStorageDataService.userDetail().display_name)} /></span>
                                            </div>
                                            {commentActive === false &&
                                                <div className="bottom-commrnt-col-right">
                                                    <div className="formInput" style={{ flexBasic: "100%" }}>
                                                        <input type="text" className="comment-single-text-box" placeholder="Add a comment..." onClick={() => setCommentActive(true)} />
                                                    </div>
                                                    {/* <p style={{ marginBottom: 20 }} ><span dangerouslySetInnerHTML={{ __html: formValues.comment }} /></p> */}
                                                </div>
                                            }
                                            {commentActive === true &&
                                                <div className="bottom-commrnt-col-right">
                                                    <CKEditor
                                                        name="comment"
                                                        initData={comment}
                                                        onChange={(e) => inputHandler(e)}
                                                    />
                                                    <button onClick={() => editCommentChanges('comment')} className="save-btn">Save</button>
                                                    <button onClick={() => { setCommentActive(false); setComment("") }} className="cancel-btn">Cancel</button>
                                                </div>
                                            }
                                        </div>


                                        {commentById && commentById.data && commentById.data.rows && commentById.data.rows.length > 0 && commentById.data.rows.map((item, index) => {
                                            return (
                                                <div className="bottom-comments-row">
                                                    <div className="bottom-commrnt-col-left">
                                                        {/* {item.rtms_issue && item.rtms_issue && item.rtms_issue.assignee && item.rtms_issue.assignee &&
                                                            <span style={{ textTransform: 'capitalize' }}><Avatar {...stringAvatar(item.rtms_issue.assignee.display_name)} /></span>
                                                        } */}
                                                        {item.created_resource && item.created_resource.display_name &&
                                                            <span style={{ textTransform: 'capitalize' }}><Avatar {...stringAvatar(item.created_resource.display_name)} /></span>
                                                        }
                                                    </div>
                                                    <div className="bottom-commrnt-col-right">
                                                        {item.created_resource && item.created_resource.display_name &&
                                                            <h6 style={{ textTransform: 'capitalize' }}>{item.created_resource.display_name}
                                                                {/* {item.rtms_issue && item.rtms_issue && item.rtms_issue.assignee && item.rtms_issue.assignee &&
                                                                    <span>{item.rtms_issue.assignee && calculateDays(item.rtms_issue.assignee.created_on)}</span>
                                                                } */}
                                                            </h6>
                                                        }
                                                        {/* <a href={item.comment_url}>Commit Link : {item.comment_url}</a> */}
                                                        <p><span dangerouslySetInnerHTML={{ __html: item.body && item.body }} /></p>
                                                        <p>Comment Date : {item.rtms_issue && moment(item.rtms_issue.created_on).format('YYYY-MM-DD')}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </TabPanel>
                                    <TabPanel value={value} index={4} className="custom-loader-tabs">
                                        {(activeTab === 4) && <LoaderForm />}
                                        {taskHistoryList && taskHistoryList.data && taskHistoryList.data.rows &&
                                            taskHistoryList.data.rows.map((item, index) => {
                                                return (
                                                    <div key={index} className="bottom-comments-row">
                                                        <div className="bottom-commrnt-col-left">
                                                            {item.rtms_issue && item.rtms_issue.assignee && item.rtms_issue.assignee.display_name &&
                                                                <span><Avatar {...stringAvatar(item.rtms_issue.assignee.display_name)} /></span>
                                                            }
                                                        </div>
                                                        <div className="bottom-commrnt-col-right">
                                                            {item.rtms_issue && item.rtms_issue.assignee && item.rtms_issue.assignee.display_name &&
                                                                <h6>{item.rtms_issue.assignee.display_name} <span>{item.updated_on && calculateDays(moment(item.updated_on).format('YYYY-MM-DD'))}</span></h6>
                                                            }
                                                            <p>Changed : {item.new_value ? `Description - ${item.new_value}` :
                                                                item.new_assignee ? `Assignee - ${item.new_assignee}` :
                                                                    item.new_status ? `Status - ${item.new_status}` :
                                                                        `${item.new_data}`}
                                                            </p>
                                                            <div className="row-assignnee-history">
                                                                {/* <div className="col-assignee-history-1">
                                                                    <h5 className="assignee">Ravi Kant</h5>
                                                                </div> */}

                                                                <div className="col-assignee-history-2">
                                                                    <ArrowRightAltIcon />
                                                                </div>

                                                                <div className="col-assignee-history-1">
                                                                    {item.updated_by && item.updated_by && item.updated_by.display_name &&
                                                                        <h5 className="assign">{item.updated_by.display_name}</h5>
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                )
                                            })}

                                    </TabPanel>
                                </div>


                            </div>
                        </div>
                        <div className={`edit-details-col-right ${show ? 'show-hide-right-side-colum1' : ''}`}>
                            <select name="status_name" value={formValues && formValues.status_id} onChange={changeStatus} className="select-status-ticket">
                                <option>SELECT</option>
                                {statusData && statusData.length > 0 && statusData.map((item, index) => {
                                    return (
                                        <option key={index} value={item.status_id}>{item.status_name}</option>
                                    )
                                })}
                            </select>
                            <div className="right-side-details">
                                <h4>Create page for edit task</h4>
                                <div className="right-and-left-name-lable-first">
                                    <h6 style={{ fontSize: "16px", color: "#42526e" }}>Assignee</h6>
                                    <div>
                                        <Autocomplete
                                            disabled={formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id ? false : (formValues && !formValues.assignee_id && disabledAssigne() === true) ? false : true}
                                            className="custom-select-name"
                                            sx={{ width: 300 }}
                                            autoHighlight
                                            value={assignee_name_default}
                                            renderOption={(props, option) => (
                                                <Box key={props.id} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    <span className="name-and-short">
                                                        <Avatar style={{ marginRight: "10px", textTransform: "uppercase", height: "30px", width: "30px", fontSize: "16px" }} {...stringAvatar(option.label)} />{"   "} <span className="short-cut-full-name" style={{ fontSize: "16px", textTransform: "capitalize", }}>{option.label}</span>
                                                    </span>
                                                </Box>
                                            )}
                                            onChange={(event, newValue) => {
                                                changeStatus(newValue);
                                            }}
                                            options={
                                                selectedMember && selectedMember.map((option) => {
                                                    return { key: option.value, value: option.value, label: option.label, name: "assignee" }
                                                })
                                            
                                            }
                                            renderInput={(params) => <TextField {...params} label={"Select Assignee"} />}
                                        />
                                        {formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id && <p style={{ color: 'blue' }} onClick={(e) => assignToMe(e, 'unassign')} ><u>Unassigned</u></p>}
                                        {formValues && !formValues.assignee_id && disabledAssigne() === true && <p style={{ color: 'blue' }} onClick={(e) => assignToMe(e, 'assign')} ><u>Assign to me</u></p>}
                                        {/* {
                                            formValues.assignee_id === LocalStorageDataService.userDetail().id ? "" :
                                                checkAssignedfield() === false && <button onClick={(e) => assignToMe(e)} className="btn btn-submit">assign to me</button>
                                                } */}
                                    </div>
                                </div>
                                <div className="right-and-left-name-lable">
                                    <h6>Reporter</h6>
                                    <input type="text" value={formValues && formValues.reported_by && formValues.reported_by.display_name} disabled={true} />
                                </div>

                                <div className="right-and-left-name-lable">
                                    <h6>Label</h6>
                                    <Select
                                        isMulti
                                        defaultValue={selected}
                                        width='300px'
                                        options={LocalStorageDataService.labels_list().map(e => {
                                            return { label: e, value: e, name: 'lables' }
                                        })} />
                                </div>
                                <div className="right-and-left-name-lable">
                                    <h6>Story Point</h6>
                                    <input type="text" value={formValues && formValues.story_point} />
                                </div>

                                <div className="right-and-left-name-lable">
                                    <h6>Sprint</h6>
                                    <input type="text" value={formValues && formValues.sprint} disabled={true} />
                                </div>

                                <div className="right-and-left-name-lable">
                                    <h6>Priority</h6>
                                    <Select
                                        defaultValue={formValues && formValues.priority}
                                        onChange={changeStatus}
                                        width='300px'
                                        options={LocalStorageDataService.priority_list().map(e => {
                                            return { label: e, value: e, name: 'priority' }
                                        })} />
                                </div>
                                <div className="right-and-left-name-lable">
                                    <h6>Team Members</h6>
                                    {resourceProject_list && resourceProject_list.length > 0 ?
                                        <Select
                                            isMulti
                                            isDisabled={formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id ? false : true}
                                            value={selectedMember}
                                            onChange={(evt) => updateTeamMember(evt)}
                                            width='300px'
                                            options={resourceProject_list && resourceProject_list.length > 0 &&
                                                resourceProject_list.map((item, index) => {
                                                    return { key: index, label: item.display_name, value: item.id, name: 'team_members' }
                                                })} />
                                        :
                                        <p>Please assign member for this project "{formValues && formValues.project && formValues.project.project_name}"</p>
                                    }
                                </div>

                            </div>


                        </div>
                    </div>
                </div>

            </div >
        </div >

    )
}

