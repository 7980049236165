import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";
// import moment from "moment";
// const { REACT_APP_ENV } = process.env;

export const compoffList = createAsyncThunk(
    "get/compoffList",
    async (data) => {
        const res = await DataService.compoff_list(data);
        const resp = res.data;
        return resp;
    }
);



const compoffSlice = createSlice({
    name: "auth",
    initialState: {
        compOffs: [],
        status: STATUSES.IDLE,
        error: '',
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(compoffList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(compoffList.fulfilled, (state, action) => {
                state.status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.error = !action.payload.data ? action.payload : ""
                state.data = !action.payload.data ? "" : action.payload;
                //state.authLoggedIn = true
            })
            .addCase(compoffList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })


    },
});


export default compoffSlice.reducer;