import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

export const getDepartment = createAsyncThunk(
    "get/department",
    async (data) => {
        const res = await dataService.departmentList(data);
        const resp = res.data;
        return resp;
    }
);

export const getDepartmentList = createAsyncThunk(
    "get/departmentList",
    async (data) => {
        const res = await dataService.department_List(data);
        const resp = res.data;
        return resp;
    }
);

export const getDepartmentDomains = createAsyncThunk(
    "get/departmentDomains",
    async (data) => {
        const res = await dataService.departmentDomainList(data);
        const resp = res.data;
        return resp;
    }
);

const departmentSlice = createSlice({
    name: "department",
    initialState: {
        data: [],
        status: 'idle',
        error: '',
        departmentList: [],
        departmentDomainsList: [],
        count: 0,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get 
            .addCase(getDepartment.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getDepartment.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.data = action.payload;
                state.error = action.error

            })
            .addCase(getDepartment.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })

            //get DepartmentList
            .addCase(getDepartmentList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getDepartmentList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.departmentList = action.payload.data.rows;
                state.count = action.payload.data.count;
                state.error = action.error

            })
            .addCase(getDepartmentList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })
            // Department-Domains
            .addCase(getDepartmentDomains.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getDepartmentDomains.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.departmentDomainsList = !action.payload.data.rows ? [] : action.payload.data.rows;
                // state.count = action.payload.data.count;
                state.error = action.error

            })
            .addCase(getDepartmentDomains.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })
    },
});


export default departmentSlice.reducer;