import http from "../APIS/api";
import formdata_api from "../APIS/formdata_api";
import LocalStorageDataService from '../../components/common-function/GetLocalstorage'

class DataService {
  getAll() {
    return http.get("/users");
  }

  get(id) {
    return http.get(`/users/${id}`);
  }

  create(data) {
    return http.post("/login", data);
  }

  userLogin(token) {
    return http.get("/user", {
      headers: {
        'Authorization': token,
        //'Cookie':`token=${token}`
      }
    });
  }

  update(id, data) {
    return http.put(`/users/${id}`, data);
  }

  delete(id) {
    return http.delete(`/users/${id}`);
  }

  deleteAll() {
    return http.delete(`/users`);
  }

  findByTitle(title) {
    return http.get(`/users?title=${title}`);
  }

  logout() {
    return http.post("/logout");
  }

  //Department api
  departmentList(filterQuery) {
    return http.get(`/mst/departments?where=${filterQuery}`);
  }

  department_List(filterQuery) {
    return filterQuery ? http.get(`/mst/departments?where=${JSON.stringify(filterQuery)}`)
      :
      // http.get('/mst/departments?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"name"} ,"no_limit":"True"}')
      http.get('/mst/departments?where={"filter":{"status":{"value":"True","operation":"eq"}} ,"no_limit":"True"}')

  }

  departmentDomainList(filterQuery) {
    return filterQuery ? http.get(`/mst/department-domain?where=${JSON.stringify(filterQuery)}`)
      :
      // http.get('/mst/department-domain?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"name"} ,"no_limit":"True"}')
      http.get('/mst/department-domain?where={"filter":{"status":{"value":"True","operation":"eq"}} ,"no_limit":"True"}')

  }

  assign_department_domain(data) {
    return data.id ? http.put(`/mst/department-domain/${data.id}`, data)
      :
      http.post('/mst/department-domain', data)
  }

  addDepartment(data) {
    return http.post("/mst/departments", data);
  }

  departmentById(id) {
    return http.get(`/mst/departments/${id}`);
  }

  updateDepartment(id, data) {
    return http.put(`/mst/departments/${id}`, data);
  }

  deleteDepartment(id, data) {
    return http.delete(`/mst/departments/${id}`, data);
  }


  //role api
  roleList(filterQuery) {
    return http.get(`/mst/tms-roles?where=${filterQuery}`);
  }

  addRole(data) {
    return http.post("/mst/tms-roles", data);
  }

  roleById(id) {
    return http.get(`/mst/tms-roles/${id}`);
  }

  updateRole(id, data) {
    return http.put(`/mst/tms-roles/${id}`, data);
  }

  deleteRole(id, data) {
    return http.delete(`/mst/tms-roles/${id}`, data);
  }

  //user Type api
  userTypeList(filterQuery) {
    return http.get(`mst/user-type?where=${filterQuery}`);
  }

  addUserType(data) {
    return http.post("/mst/user-type ", data);
  }

  userTypeById(id) {
    return http.get(`/mst/user-type/${id}`);
  }

  updateUserType(id, data) {
    return http.put(`/mst/user-type/${id}`, data);
  }

  deleteUserType(id, data) {
    return http.delete(`/mst/user-type/${id}`, data);
  }

  country_list() {
    return http.get('mst/country')
  }

  ///resource list

  resource_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/resources/main?where=${filterQuery}`)
    } else {
      let user = LocalStorageDataService.userDetail();
      let tmsRole = user && user.tms_role
      if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) {
        return http.get('/resources/main?where={"order_by":{"column":"name"},"no_limit":True}')
      } else {
        return http.get(`/resources/main?where={"filter":{"id":{"value":${user.id},"operation": "eq"}}, "order_by":{"column":"name"},"no_limit":True}`)
      }
    }
  }

  resource_list_for_task() {
    return http.get('/resources/main?where={"filter":{"status":{"value":"True","operation": "eq"}},"no_limit":True}')
  }

  ///resource view by id

  resource_view(id) {
    return http.get(`/resources/main/${id}`);
  }

  ///resource add

  resource_add(data) {
    return http.post("/resources/main", data);
  }

  ///resource edit
  resource_edit(id, data) {
    return http.put(`/resources/main/${id}`, data);
  }

  ///resource delete

  resource_delete(id, data) {
    return http.delete(`/resources/main/${id}`, data);
  }
  /// resource sync by jira api
  sync_resources() {
    return http.get(`/jira/get-users/0/24`);
  }

  //all  role lis 
  all_role_list() {
    return http.get(`/mst/tms-roles?where={"no_limit":True}`);
  }

  employment_type_list(id) {
    return http.get(`/mst/employment-type`);
  }
  // all depattment list

  all_department_list() {
    return http.get(`/mst/departments?where={"no_limit":True}`);
  }
  department_type_list() {
    return http.get(`/mst/departments?where={"no_limit":True}`);
  }
  // all emp. type list
  all_emp_type_list() {
    return http.get(`/mst/employment-type?where={"no_limit":True}`);
  }

  tms_role_list(id) {
    return http.get(`/mst/tms-roles?where={"no_limit":True}`);
  }


  create_resources(data) {
    return http.post(`/resources/main`, data);
  }

  update_resources(id, data) {
    return http.put(`/resources/main/${id}`, data);
  }

  //Credential head api
  credentialHeadList(filterQuery) {
    return http.get(`mst/credentials-head?where=${filterQuery}`);
  }

  addCredentialHead(data) {
    return http.post("/mst/credentials-head", data);
  }

  credentialHeadById(id) {
    return http.get(`/mst/credentials-head/${id}`);
  }

  updateCredentialHead(id, data) {
    return http.put(`/mst/credentials-head/${id}`, data);
  }

  deleteCredentialHead(id, data) {
    return http.delete(`/mst/credentials-head/${id}`, data);
  }

  //Resource Credential api
  resourceCredentialList(filterQuery) {
    let user = LocalStorageDataService.userDetail();
    let tmsRole = user && user.tms_role
    if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) {
      return http.get(`/resources/credentials?where=${filterQuery}`);
    } else {
      return http.get(`/resources/credentials?where={"filter":{"resource_id":{"value":${user.id},"operation":"eq"}},"no_limit":True}`)
    }
  }

  checkResourceCredentialById() {
    let user = LocalStorageDataService.userDetail();
    return http.get(`/resources/credentials?where={"filter":{"resource_id":{"value":${user.id},"operation":"eq"}},"no_limit":True}`)
  }

  addResourceCredential(data) {
    return http.post("/resources/credentials", data);
  }

  resourceCredentialById(id) {
    return http.get(`/resources/credentials/${id}`);
  }

  updateResourceCredential(id, data) {
    return http.put(`/resources/credentials/${id}`, data);
  }

  deleteResourceCredential(id, data) {
    return http.delete(`/resources/credentials/${id}`, data);
  }

  ///attendance list

  attandance_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/resources/attendance?where=${filterQuery}`);
    } else {
      let user = LocalStorageDataService.userDetail();
      let data = {
        "filter": {
          "resource_id": {
            "value": user.id,
            "operation": "eq"
          }
        },
        "order_by": { "column": "-punchin_date" },
        "no_limit": "True"
      }
      return http.get(`/resources/attendance?where=${JSON.stringify(data)}`);
    }
  }

  attandance_detail_by_id(id) {
    let data = {
      "resource_attendance_id": {
        "value": id,
        "operation": "eq"
      }
    }
    return http.get(`/resources/attendance-detail?where={ "filter": ${JSON.stringify(data)}, "no_limit":True}`);
  }

  attandance_report_list(filterQuery) {
    return http.get(`/report/attendance?where=${filterQuery}`);
  }
  attandance_regulization_by_date(data) {
    let user = LocalStorageDataService.userDetail();
    let data0 = {
      "filter": {
        "resource_attendance_id": {
          "value": data.id,
          "operation": "eq"
        },
        "resource_id": {
          "value": data.resource_id ? data.resource_id : user.id,
          "operation": "eq"
        },
        // "punchin_date":{
        //   "value": data.current_date,
        //   "operation": "eq"
        // }
      }
    }
    return http.get(`/resources/regularization-requests?where=${JSON.stringify(data0)} `);
  }

  attandance_regulization_create(data) {
    return http.post(`/resources/regularization-requests`, data);
  }
  attandance_regulization_update(id, data) {
    return http.put(`/resources/regularization-request/${id}`, data);
  }

  attandance_regulization_by_id(id) {
    return http.get(`/resources/regularization-request/${id}`);
  }
  team_regular_list(filterQuery) {
    return http.get(`/resources/regularization-requests?where=${filterQuery}`);
  }
  get_consolidate_monthly_list(filterQuery) {
    return http.get(`/report/attendance/consolidate-monthly?where=${filterQuery}`)
  }

  project_jira_list() {
    let user = LocalStorageDataService.userDetail();
    let tmsRole = user && user.tms_role
    if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) {
      return http.get(`/resources/resource-project?where={"order_by":{"column":"-id"},"no_limit":True}`);
    } else {
      let data = {
        "filter": {
          "resource_id": {
            "value": user.id,
            "operation": "eq"
          }
        },
        "no_limit": "True"
      }
      return http.get(`resources/resource-project?where=${JSON.stringify(data)}`);
      //return http.get(`/projects/main?where={"no_limit":True}`);
    }
  }

  resource_project_list(filter) {
    return http.get(`resources/resource-project?where=${filter}`);
  }

  project_list_for_task() {
    let user = LocalStorageDataService.userDetail();
    let tmsRole = user && user.tms_role
    if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) {
      return http.get(`resources/resource-project?where={"order_by":{"column":"-id"},"no_limit": "True"}`);
    } else {
      let data = {
        "filter": {
          "resource_id": {
            "value": user.id,
            "operation": "eq"
          }
        },
        "no_limit": "True"
      }
      return http.get(`resources/resource-project?where=${JSON.stringify(data)}`);
    }
  }



  project_list(id) {
    return http.get(`/projects/main?where={ "order_by":{"column":"-id"},"no_limit":True}`);
  }

  jira_status(filter) {
    // let data ={"filter":{"status":{"value":true,"operation":"eq"},"no_limit":True}}
    //?where=${JSON.stringify(data)}
    if (filter) {
      return http.get(`/jira-rtms/workflow-status?where=${filter}`);
    }
    return http.get(`/jira-rtms/workflow-status?where={"order_by":{"column":"-id"},"no_limit":True}`);
  }
  punchInOut(data) {
    return http.post(`resources/attendance`, data);
  }

  jira_status_list(data) {
    //${JSON.stringify(data)}
    if (data) {
      return http.get(`/jira-rtms/workflow-status?where=${data}`);
    }
    return http.get(`/jira-rtms/workflow-status?where={"order_by":{"column":"-id"},"no_limit":True}`);
  }

  jira_status_create(data) {
    return http.post(`/jira-rtms/workflow-status`, data);
  }

  jira_status_edit(id) {
    return http.get(`/jira-rtms/workflow-status/${id}`);
  }

  jira_status_update(id, data) {
    return http.put(`/jira-rtms/workflow-status/${id}`, data);
  }

  jira_status_archive(id) {
    return http.put(`/jira-rtms/workflow-status/${id}`, { status: 3 });
  }
  /// jira status syncing 
  jira_status_sync() {
    return http.get(`/jira/get-workflows/0/20`);
  }

  sprint_list() {
    return http.get(`/jira-rtms/sprints?where={"order_by":{"column":"-id"},"no_limit":True}`);
  }
  issue_type_list() {
    return http.get(`/jira-rtms/issue-types?where={"order_by":{"column":"-id"},"no_limit":True}`);
  }

  sprint_sync(project_id) {
    /// /api/jira/get-sprints/23/0/5 Where 23 is projct_id (in RTMS), 0 -> Skip, 5 -> Limit
    return http.get(`/jira/get-sprints/${project_id}/0/5`);
  }


  get_task_type_list(project_id) {
    return http.get(`/jira-rtms/issue-types?where={"filter":{"project_id":{"value":${project_id},"operation": "eq"}},"columns":["id", "issue_name"],"no_limit":True}`);
  }

  work_flow_status_list(filterData) {
    return http.get(`/projects/workflow-status-mapping?where=${filterData}`);
  }
  get_default_work_flow_status_list(filterData) {
    return http.get(`/projects/workflow?where=${filterData}`);

  }
  get_zoom_meeting(filterData) {
    return http.get(`/zoom/zoom-meeting-details?where=${filterData}`);

  }
  get_call_log(filterData) {
    return http.get(`/tts/call_details?where=${filterData}`);

  }
  get_my_job_list(filterData) {
    return http.get(`/jira-rtms/issues/epic-lists?where=${filterData}`);
  }

  get_upload_file_list(filterData){
    return http.get(`/recruitment/upload-resume-file-status?where=${filterData}`);
  }
  
  post_comment(data) {
    return http.post(`/jira-rtms/comments`, data);

  }
  get_history_list(filterData) {
    return http.get(`/jira-rtms/issue-history-logs?where=${filterData}`);
  }

  get_my_task_list(filterData) {
    if (filterData) {
      return http.get(`/jira-rtms/issues?where=${filterData}`);
    } else {
      let user = LocalStorageDataService.userDetail();
      const roleWiseFilter = `{"or":{"assignee":{"value":${user.id},"operation": "eq"},"reported_by":{"value":${user.id},"operation": "eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`
      return http.get(`/jira-rtms/issues?where=${roleWiseFilter}`);
    }

  }
  task_view(id) {
    return http.get(`/jira-rtms/issues/${id}`);
  }

  get_task_list(filterData) {
    if (filterData) {
      return http.get(`/jira-rtms/issues?where=${filterData}`);
    } else {
      let user = LocalStorageDataService.userDetail();
      const tmsRole = user && user.tms_role
      const roleWiseFilter = tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin') ? `{"order_by":{"column":"-id"}, "no_limit":True}` : `{"or":{"assignee":{"value":${user.id},"operation": "eq"},"reported_by":{"value":${user.id},"operation": "eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`
      return http.get(`/jira-rtms/issues?where=${roleWiseFilter}`);
    }
  }
  get_sprint_list() {
    return http.get(`/jira-rtms/sprints?where={"no_limit":True}`)
  }

  //---------------task ----------------//
  create_task(data) {
    return http.post(`/jira-rtms/issues`, data);

  }
  create_team_member(data) {
    return http.post(`/jira-rtms/epic-team-members`, data);
  }
  team_member_list(filterData) {
    return http.get(`/jira-rtms/epic-team-members?where=${filterData}`);

  }

  update_team_member(id, data) {
    return http.put(`/jira-rtms/epic-team-members/${id}`, data);
  }
  create_task_file(data) {
    return formdata_api.post(`/recruitment/upload-resume`, data)
  }

  get_member_list(filter) {
    return http.get(`/jira-rtms/epic-team-members?where=${filter}`)
  }

  sync_task_project(project_id) {
    return http.get(`/jira/get-issues/0/20?query=project=${project_id}`);
  }
  sync_task_sprint(sprint_id) {
    return http.get(`/jira/get-issues/0/20?query=sprint=${sprint_id}`);
  }

  task_archive(id) {
    return http.put(`/jira-rtms/issue/${id}`, { status: 3 });
  }

  task_sync_by_jira_id(id) {
    return http.get(`/jira/get-issue/${id}`);
  }

  get_task_By_id(id) {
    return http.get(`/jira-rtms/issue/${id}`);
  }

  update_task(id, data) {
    return http.put(`/jira-rtms/issue/${id}`, data);
  }
  //////-------------end task---------------//

  //api for shift 
  shiftList(filterQuery) {
    return http.get(`/mst/shift?where=${filterQuery}`);
  }

  // api for myProject list
  myProjects_list(rtm_user) {
    let resource_id = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id;
    let data = {
      "filter": {
        "status": { "value": "True", "operation": "eq" },
        "resource_id": {
          "value": resource_id,
          "operation": "eq"
        }
      },
    }
    return http.get(`resources/resource-project?where=${JSON.stringify(data)}`);
  }

  // api for task list
  tasksList(rtm_user) {
    let resource_id = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id;
    let data = {
      "or": {
        "assignee": {
          "value": resource_id,
          "operation": "eq"
        },
        "reported_by": {
          "value": resource_id,
          "operation": "eq"
        }
      },

    }
    return http.get(`jira-rtms/issues?where=${JSON.stringify(data)}`);
  }

  //My Resource Credential api
  myResourceCredentialList(filterQuery) {
    // let user = LocalStorageDataService.userDetail();
    return http.get(`/resources/credentials?where=${filterQuery}`)
  }

  issue_assignee_change() {
    return http.get(`jira-rtms/issue-assignee-change?where={"no_limit":True}`)
  }

  issue_assignee_change_update(id, data) {
    return http.put(`/jira-rtms/issue-assignee-change/${id}`, data)
  }

  issue_status_change_update(id, data) {
    return http.get(`/jira-rtms/issue-status-change/${id}`, data)
  }

  issue_status_change() {
    return http.put(`jira-rtms/issue-status-change?where={"no_limit":True}`)
  }

  jira_comments() {
    return http.get(`/jira-rtms/comments?where={"no_limit":True}`)
  }

  jira_comments_by_id(id) {
    //https://dev-tm.mightcode.com/api/jira-rtms/comments?where={"filter":{"rtms_issue_id":{"value":136,"operation": "eq"}}}
    return http.get(`jira-rtms/comments?where={"filter":{"rtms_issue_id":{"value":${id},"operation": "eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`)
  }

  jira_comments_update_by_id(id, data) {
    return http.put(`/jira-rtms/comment/${id}`, data)
  }

  task_assignee_update(issue_id, resource_id) {
    return http.put(`/jira/task-assignee/${issue_id}/${resource_id}`)
  }

  task_status_update(id, data) {
    return http.put(`/jira/issue-status/${id}`, data)
  }

  // resource list with active status 
  resource_list_status() {
    let user = LocalStorageDataService.userDetail();
    let tmsRole = user && user.tms_role
    if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) {
      return http.get('/resources/main?where={"filter":{"status":{"value":"True","operation": "eq"}}, "no_limit":True}')
    } else {
      return http.get(`/resources/main?where={"filter":{"id":{"value":${user.id},"operation": "eq"}, "status":{"value":"True","operation": "eq"}}, "no_limit":True}`)
    }
  }

  // credentail head list with active status
  credential_list_status() {
    return http.get(`mst/credentials-head?where={"filter":{"status":{"value":"True","operation": "eq"}}, "no_limit":True}`);
  }

  forgotPassword(data) {
    return http.post(`/forgot-password`, data)
  }

  resetPassword(data) {
    return http.post(`/reset-password`, data)
  }

  // Resources Options for DropDown
  resources_dropDown(filterQuery = null) {
    return filterQuery ? http.get(`/resources/main?where=${JSON.stringify(filterQuery)}`) :
      http.get('/resources/main?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"name"} ,"no_limit":True}')
  }

  // Departments Options for DropDown
  departments_dropDown(filterQuery = null) {
    return filterQuery ? http.get(`/mst/departments?where=${JSON.stringify(filterQuery)}`) :
      http.get('/mst/departments?where={"filter":{"status":{"value":"True","operation":"eq"}},"order_by":{"column":"name"} , "no_limit":True}')
  }
  // Roles Options for DropDown
  roles_dropDown(filterQuery = null) {
    return filterQuery ? http.get(`/mst/tms-roles?where=${JSON.stringify(filterQuery)}`)
      :
      http.get('/mst/tms-roles?where={"filter":{"status":{"value":"True","operation":"eq"}},"order_by":{"column":"role_name"} , "no_limit":True}')
  }
  // Shifts Options for DropDown
  shifts_dropDown(filterQuery = null) {
    return filterQuery ? http.get(`/mst/shift?where=${JSON.stringify(filterQuery)}`) :
      http.get('/mst/shift?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"shift_name"} , "no_limit":True}')
  }
  attandance_list_for_dashboard() {
    let user = LocalStorageDataService.userDetail();
    let data = {
      "filter": {
        "resource_id": {
          "value": user.id,
          "operation": "eq"
        }
      },
      "order_by": { "column": "-id" },
    }
    return http.get(`/resources/attendance?where=${JSON.stringify(data)}`);
  }
  //month wise attendace report api
  monthly_attandance_report_list(filterQuery) {
    return http.get(`/report/attendance/monthly?where=${filterQuery}`);
  }

  // COMP-OFF MODULE //
  compoff_list(filterQuery) {
    return filterQuery ? http.get(`/resources/compoff?where=${JSON.stringify(filterQuery)}`)
      :
      http.get('/resources/compoff?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"-created_on"} ,"no_limit":"True"}')
  }

  // Save / Update comp-off Data
  compoff_save(data) {
    return data.id ? http.put(`/resources/compoff/${data.id}`, data)
      :
      http.post('/resources/compoff', data)
  }

  //team attendance list api
  team_attendance_list(filterQuery) {
    let user = LocalStorageDataService.userDetail();
    return http.get(`/resources/attendance/team/${user.id}?where=${filterQuery}`);
  }

  //all resources by reporting manager for filtering
  all_resources_by_manager() {
    let user = LocalStorageDataService.userDetail();
    return http.get(`/resources/main?where={"filter":{"reporting_manager":{"value":${user.id},"operation": "eq"}}, "no_limit":True}`)
  }

  // HOLIDAYS MODULE //
  // Holidays List
  holiday_list(filterQuery) {
    return filterQuery ? http.get(`/mst/holiday-list?where=${JSON.stringify(filterQuery)}`)
      :
      http.get('/mst/holiday-list?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"name"} ,"no_limit":"True"}')
  }
  // Save / Update Holiday Data
  save_holiday(data) {
    return data.id ? http.put(`/mst/holiday-list/${data.id}`, data)
      :
      http.post('/mst/holiday-list', data)
  }
  // Delete Holiday
  delete_holiday(id) {
    return http.delete(`/mst/holiday-list/${id}`);
  }
  // mst/holiday-calendar-list
  holiday_calender_list(filterQuery) {
    return filterQuery ? http.get(`/mst/holiday-calendar-list?where=${JSON.stringify(filterQuery)}`)
      :
      http.get('/mst/holiday-calendar-list?where={"filter":{}, "order_by":{"column":"-created_on"} ,"no_limit":"True"}')
  }
  // Save / Update Holiday Data
  save_holiday_date(data) {
    return data.id ? http.put(`/mst/holiday-calendar-list/${data.id}`, data)
      :
      http.post('/mst/holiday-calendar-list', data)
  }
  // Delete Holiday
  delete_holiday_date(id) {
    return http.delete(`/mst/holiday-calendar-list/${id}`);
  }

  ///get menu list
  menuList(filterQuery) {
    return http.get(`/base/menu?where=${filterQuery}`);
  }
  all_sprint_list(filterQuery) {
    return http.get(`/jira-rtms/sprints?where=${filterQuery}`);
  }

  //post menu create
  postmenu(data) {
    if (data.id) {
      return http.put(`/base/menu/${data.id}`, data)
    } else {
      return http.post('/base/menu', data)
    }
  }
  getRoleMenu(filterQuery) {
    return http.get(`/base/menu-role?where=${filterQuery}`);
  }

  postSingleBaseMenu(data) {
    if (data.id) {
      return http.put(`/base/menu-role/${data.id}`, data)
    } else {
      return http.post('/base/menu-role', data)
    }
  }
  postAssignMenuRole(data) {
    return http.post('/base/menu-role/assign', data)
  }

  // ________third Party Start_______________//
  all_third_party_list(filterQuery) {
    return http.get(`/mst/third-party?where=${filterQuery}`);
  }
  third_party_list(filterQuery) {
    return http.get(`/mst/third-party?where=${filterQuery}`);
  }
  third_party_create(data) {
    return http.post(`/mst/third-party`, data);
  }
  third_party_edit(id) {
    return http.get(`/mst/third-party/${id}`);
  }
  third_party_update(id, data) {
    return http.put(`/mst/third-party/${id}`, data);
  }
  third_party_archive(id, data) {
    return http.delete(`/mst/third-party/${id}`, data);
  }
  // ________third Part End_________________//

  // ____________DOMAIN_______________ //
  // Domain List
  domain_list(filterQuery) {
    return filterQuery ? http.get(`/mst/domains?where=${JSON.stringify(filterQuery)}`)
      :
      http.get('/mst/domains?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"name"} ,"no_limit":"True"}')
  }
  // Save / Update Domain Data
  save_domain(data) {
    return data.id ? http.put(`/mst/domain/${data.id}`, data)
      :
      http.post('/mst/domains', data)
  }
  // Delete Domain
  delete_domain(id) {
    return http.delete(`/mst/domain/${id}`);
  }

  // Projects List
  projects_list(filterQuery) {
    return filterQuery ? http.get(`/projects/main?where=${JSON.stringify(filterQuery)}`)
      :
      http.get('/projects/main?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"name"} ,"no_limit":"True"}')
  }


  // Projects Resource Mapping
  project_resource_mapping_list() {
    return http.get(`/resources/resource-project?where={ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": "True" }`)
  }

  // Project By Id
  project_by_id(id) {
    return http.get(`/projects/main/${id}`)
  }
  // Save / Update Domain Data
  assign_project_domain(data) {
    return http.post('/projects/project-domains/assign', data)
  }
  // ____________DOMAIN End_______________ //
  //-----------upload resume start----------//
  postResume(data) {
    return formdata_api.post('/recruitment/upload-resume', data)
  }
  //-----------end--------------------------//

  // assign domain third party  List
  third_party_domain(filterQuery) {
    return filterQuery ? http.get(`/mst/third-party?where=${(filterQuery)}`)
      :
      http.get('/mst/third-party?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"name"} ,"no_limit":"True"}')
  }
  // Save / Update assign Domain Data
  assign_domain_thirdparty(data) {
    return http.post('/mst/domain-third/assign', data)
  }
  // ____________WORKFLOWS_______________ //
  // workflow List
  workflow_list(filterQuery) {
    return filterQuery ? http.get(`/projects/workflow?where=${JSON.stringify(filterQuery)}`)
      :
      http.get('/projects/workflow?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"name"} ,"no_limit":"True"}')
  }
  // Save / Update workflow Data
  save_workflow(data) {
    return data.id ? http.put(`/projects/workflow/${data.id}`, data)
      :
      http.post('/projects/workflow', data)
  }
  // Delete workflow
  delete_workflow(id) {
    return http.delete(`/projects/workflow/${id}`);
  }

  // Status List
  status_list(filterQuery) {
    return filterQuery ? http.get(`/jira-rtms/workflow-status?where=${JSON.stringify(filterQuery)}`)
      :
      http.get('/jira-rtms/workflow-status?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"name"} ,"no_limit":"True"}')
  }

  // Project workflow Status mapping List
  workflow_status_mapping_list(filterQuery) {
    return filterQuery ? http.get(`/projects/workflow-status-mapping?where=${JSON.stringify(filterQuery)}`)
      :
      http.get('/projects/workflow-status-mapping?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"name"} ,"no_limit":"True"}')
  }
  // Assign workflow Data
  assign_project_workflow(data) {
    return data.id ? http.put(`/projects/workflow-status-mapping/${data.id}`, data)
      :
      http.post('/projects/workflow-status-mapping', data)
  }
  // ____________WORKFLOWS End_______________ //

  //__________ThirdParty Credential Start_________//

  //get
  third_party_credential(filterQuery) {
    return http.get(`/mst/third-credential?where=${(filterQuery)}`)
  }
  // Save
  save_third_party_credential(data) {
    return http.post('/mst/third-credential', data)
  }
  //update
  update_third_party_credential(id, data) {
    return http.put(`/mst/third-credential/${id}`, data)
  }
  //__________ThirdParty Credential Edd//


  // ________jobpost Start_______________//
  all_job_post_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/job-post?where=${filterQuery}`);
    } else {
      return http.get(`/mst/job-post?where={"no_limit":True}`);
    }
  }
  job_post_list(filterQuery) {
    return http.get(`/recruitment/job?where=${filterQuery}`);
  }
  job_post_create(data) {
    return http.post(`/recruitment/job`, data);
  }
  job_post_edit(id) {
    return http.get(`/recruitment/job/${id}`);
  }
  job_post_update(id, data) {
    return http.put(`/recruitment/job/${id}`, data);
  }
  job_post_archive(id, data) {
    return http.delete(`/recruitment/job/${id}`, data);
  }
  // ________jobpost End_________________//

  // ________skill Start_______________//
  all_skill_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/skills?where=${filterQuery}`);
    } else {
      return http.get(`/mst/skills?where={"no_limit":True}`);
    }

  }
  skill_list(filterQuery) {
    return http.get(`/mst/skills?where=${filterQuery}`);
  }
  skill_create(data) {
    return http.post(`/mst/skills`, data);
  }
  skill_edit(id) {
    return http.get(`/mst/skill/${id}`);
  }
  skill_update(id, data) {
    return http.put(`/mst/skill/${id}`, data);
  }
  skill_archive(id, data) {
    return http.delete(`/mst/skill/${id}`, data);
  }
  // ________skill End_________________//

  // ________industry Start_______________//
  all_industry_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/industry?where=${filterQuery}`);
    } else {
      return http.get(`/mst/industry?where={"no_limit":True}`);
    }

  }
  industry_list(filterQuery) {
    return http.get(`/mst/industry?where=${filterQuery}`);
  }
  industry_create(data) {
    return http.post(`/mst/industry`, data);
  }
  industry_edit(id) {
    return http.get(`/mst/industry/${id}`);
  }
  industry_update(id, data) {
    return http.put(`/mst/industry/${id}`, data);
  }
  industry_archive(id, data) {
    return http.delete(`/mst/industry/${id}`, data);
  }
  // ________industry End_________________//

  // _________Qualification Start_______________//
  all_qualification_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/education-qualification?where=${filterQuery}`);
    } else {
      return http.get(`/mst/education-qualification?where={"no_limit":True}`);
    }

  }
  qualification_list(filterQuery) {
    return http.get(`/mst/education-qualification?where=${filterQuery}`);
  }
  qualification_create(data) {
    return http.post(`/mst/education-qualification`, data);
  }
  qualification_edit(id) {
    return http.get(`/mst/education-qualification/${id}`);
  }
  qualification_update(id, data) {
    return http.put(`/mst/education-qualification/${id}`, data);
  }
  qualification_archive(id, data) {
    return http.delete(`/mst/education-qualification/${id}`, data);
  }
  // ________qualification End_________________//

  // calling api 
  calling_api(data) {
    return http.post('/tts/click_to_call', data)
  }
  call_disconnect_api(id) {
    return http.get(`/tts/disconnect/${id}`)
  }

  // epic list for upload resume
  get_epic_list_for_upload_resume(project_id, epic_id) {
    return http.get(`/jira-rtms/issues?where={"filter":{"project_id":{"value":${project_id},"operation": "eq"},"issue_type":{"value":${epic_id},"operation": "eq"}},"no_limit":"True"} `);
  }

  // get category for email
  get_category_by_domain(id) {
    return http.get(`emails/domain-template/${id}`)

  }
  // get emails/template-masters
  get_email_template_master(id) {
    return http.get(`/emails/templates?where={"filter":{"template_type_master_id":{"value":${id},"operation":"eq"}} ,"no_limit":"True"}`)
  }

  //send email api 
  send_email(data) {
 http.post('/emails/send-email', data)
    return http.post('/emails/send-email', data)

  }

  // zoom api 
  get_mst_zoom_account() {
    return http.get('/mst/zoom-account')
  }
  //create zoom api 
  create_zoom_meeting(id, data) {
    return http.post(`/zoom/create-meeting/${id}`, data)
  }
   // attachment api 
   create_attachment(data) {
    return http.post(`/file/upload`, data)
  }

}


export default new DataService();