import { Tooltip } from "@mui/material";
import moment from "moment";

export const countryColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "name",
    headerName: "Country Name",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.name}
        </div>
      );
    },
  },
  { field: "code", headerName: "Code", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];

export const resourceColumns = [
  // { field: "id", headerName: "ID", width: 80,  },
  { field: "emp_code", headerName: "Emp. Code", width: 150 },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "display_name",
    headerName: "Display Name",
    width: 150,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.display_name}
        </div>
      );
    },
  },
  { field: "email", headerName: "Email", width: 300 },
  // {
  //   field: "department",
  //   headerName: "Department",
  //   width: 150,
  //   renderCell: (params) => {
  //     return (
  //       <div className="cellWithStatus">
  //         {params.row.department && params.row.department.name ? params.row.department.name : params.row.department}
  //       </div>
  //     );
  //   },
  // },
  // {
  //   field: "tms_role",
  //   headerName: "Role",
  //   width: 150,
  //   renderCell: (params) => {
  //     return (
  //       <div className="cellWithStatus">
  //         {params.row.tms_role && params.row.tms_role.role_name ? params.row.tms_role.role_name : params.row.tms_role}
  //       </div>
  //     );
  //   },
  // },
  //{ field: "tms_role", headerName: "Role", width: 150 },
  {
    field: "shift",
    headerName: "Shift",
    width: 300,
    renderCell: (params) => {
      // console.log(params)
      return (
        <div className="cellWithStatus">
          {params.row.shift && params.row.shift.shift_name ? params.row.shift.shift_name : params.row.shift} &nbsp;
          {
            params.row.shift ?
              (params.row.shift_start_time ? "(" + moment("10/10/2022 " + params.row.shift_start_time).format('LT') : "") + " - " + (params.row.shift_end_time ? moment("10/10/2022 " + params.row.shift_end_time).format('LT') : "") + ")" : ""
          }
        </div>
      );
    },
  },
  // {
  //   field: "employment_type",
  //   headerName: "Employment Type",
  //   width: 250,
  //   renderCell: (params) => {
  //     return (
  //       <div className="cellWithStatus">
  //         {params.row.employment_type && params.row.employment_type.employment_type ? params.row.employment_type.employment_type : params.row.employment_type}
  //       </div>
  //     );
  //   },
  // },
  // { field: "date_of_birth", headerName: "DOB", width: 150 },
  // { field: "doj", headerName: "DOJ", width: 150 },
  { field: "contact_no", headerName: "Contact No.", width: 150 },
  // { field: "emp_code", headerName: "Emp. Code", width: 150 },
  { field: "jira_account", headerName: "Jira Account", width: 200 },
];

//columns heading for department
export const departmentColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "name", headerName: "Department Name", width: 230 },
  { field: "code", headerName: "Code", width: 230 },
  { field: "description", headerName: "Description", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];

//columns heading for role
export const roleColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "role_name", headerName: "Role Name", width: 230 },
  { field: "role_key", headerName: "Role Key", width: 230 },
  { field: "role_description", headerName: "Description", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];
/// attendance report status column 
export const attendanceReportStatusColumns = [
  //{ field: "id", headerName: "ID", width: 70 },
  { field: "emp_code", headerName: "Employee Number", width: 230 },
  { field: "display_name", headerName: "Employee Name", width: 230 },
  //Total Days, WFH, Pending WFH,On Duty,Pending On Duty,WOH,Weekly Offs,Holidays,Absent Days,Present Days,Missing Swipe Days
  //Late Arrival Days,Penalized Paid Leave,Paid Leave Taken,Pending Paid Leave Taken,Total Paid Leave,Penalized Unpaid Leave
  //Unpaid Leave Taken,Pending Unpaid Leave Taken,Total Unpaid Leave
  { field: "total_day", headerName: "Total Days", width: 230 },
  { field: "actual_working_day", headerName: "Actual Working Day", width: 230 },
  { field: "present_days", headerName: "Present Days", width: 230 },
  { field: "total_holiday", headerName: "Total Holidays", width: 230 },
  { field: "total_weekend", headerName: "Total Weekend", width: 230 },

  // { field: "wfh", headerName: "WFH", width: 230 },
  // { field: "on_duty", headerName: "On Duty", width: 230 },
  // { field: "pending_on_duty", headerName: "Pending On Duty", width: 230 },
  // { field: "woh", headerName: "WOH", width: 230 },
  // { field: "weekly_offs", headerName: "Weekly Offs", width: 230 },
  // { field: "holidays", headerName: "Holidays", width: 230 },
  // { field: "absent_days", headerName: "Absent Days", width: 230 },
  // { field: "present_days", headerName: "Present Days", width: 230 },
  // { field: "missing_swipe_days", headerName: "Missing Swipe Days", width: 230 },
  // { field: "late_arrival_days", headerName: "Late Arrival Days", width: 230 },
  // { field: "penalized_paid_leave", headerName: "Penalized Paid Leave", width: 230 },
  // { field: "paid_leave_taken", headerName: "Paid Leave Taken", width: 230 },
  // { field: "pending_paid_leave_taken", headerName: "Pending Paid Leave Taken", width: 230 },
  // { field: "total_paid_leave", headerName: "Total Paid Leave", width: 230 },
  // { field: "penalized_unpaid_leave", headerName: "Penalized Unpaid Leave", width: 230 },
  // { field: "unpaid_leave_taken", headerName: "Unpaid Leave Taken", width: 230 },
  // { field: "pending_unpaid_leave Taken", headerName: "Pending Unpaid Leave Takene", width: 230 },
  // { field: "total_unpaid_leave", headerName: "Total Unpaid Leave", width: 230 },
];


//columns heading for user type
export const userTypeColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "type_name", headerName: "User Type Name", width: 230 },
  { field: "type_key", headerName: "User Type Key", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];

//columns heading for credential Heads
export const credentialHeadColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "head_name", headerName: "Head Name", width: 250 },
  { field: "description", headerName: "Description", width: 330 },
  {
    field: "status",
    headerName: "Status",
    width: 120,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];

//columns heading for resource credential
export const resourceCredentialColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  {
    field: "credentails_head",
    headerName: "Credentail Head",
    width: 230,
    renderCell: (params) => {
      return (
        <div>
          {params.row.credentails_head}
        </div>
      );
    },
  },
  {
    field: "resource",
    headerName: "Resource Name",
    width: 230,
    renderCell: (params) => {
      return (
        <div>
          {params.row.resource}
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];

//columns heading for user type
export const jiraStatusTypeColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "name", headerName: "Name", width: 230 },
  {
    field: "category", headerName: "Category", width: 230,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.category}`}>
          {params.row.category && params.row.category.name ? params.row.category.name : params.row.category}
        </div>
      );
    },
  },
  { field: "description", headerName: "Description", width: 300 },

  {
    field: "is_jira_status",
    headerName: "Is Jira Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.is_jira_status}`}>
          {params.row.is_jira_status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];

//columns heading for credential Heads
export const sprintColumn = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "sprint_name", headerName: "Sprint Name", width: 230 },
  { field: "description", headerName: "Description", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
  {
    field: "is_jira_status",
    headerName: "Is Jira Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.is_jira_status}`}>
          {params.row.is_jira_status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];

export const resourceReportColumns = [
  {
    field: "name",
    headerName: "Name",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.name}
        </div>
      );
    },
  },
  { field: "email", headerName: "Email", width: 300 },
  { field: "emp_code", headerName: "Employee Code", width: 300 }
];

export const attendanceReportColumns = [
  {
    field: "punchin_date",
    headerName: "Date",
    type: 'date',
    width: 200
  },
  { field: "punchin_time", headerName: "Punch In Time", width: 200 },
  { field: "punch_out_time", headerName: "Punch Out Time", width: 200 }
];

export const attendanceDetailsReportColumns = [
  {
    field: "created_on", headerName: "Date", type: 'date', width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {moment(params.row.created_on).format('YYYY-MM-DD')}
        </div>
      );
    },
  },
  { field: "durations", headerName: "Duration", width: 200 },
  { field: "break", headerName: "Break Time", width: 200 },
  { field: "punchin_time", headerName: "Punch In Time", width: 200 },
  { field: "punch_out_time", headerName: "Punch Out Time", width: 200 }
];

export const taskListColumns = [

  {
    field: "issue_type_icon", headerName: "Issue Type", width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.issue_type && params.row.issue_type.icon_url ?
            <img src={params.row.issue_type.icon_url} alt="" />
            :
            params.row.issue_type_icon ?
              <img src={params.row.issue_type_icon} alt="" />
              :
              <div></div>
          }
          {"  "}{params.row.issue_type && params.row.issue_type.issue_name ? params.row.issue_type.issue_name : params.row.issue_type}
        </div>
      );
    }
  },
  {
    field: "issue_type", headerName: "Issue Name", width: 200, hideable: true,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.issue_type && params.row.issue_type.issue_name ? params.row.issue_type.issue_name : params.row.issue_type}
        </div>
      );
    }
  },
  // {
  //   field: "project", headerName: "Project", width: 200,
  //   renderCell: (params) => {
  //     return (
  //       <div className="cellWithStatus">
  //         {params.row.project && params.row.project.project_name ? params.row.project.project_name : params.row.project}
  //       </div>
  //     );
  //   }
  // },
  { field: "ticket_key", headerName: "RTMS Key", width: 200 },
  { field: "ticket_jira_key", headerName: "JIRA Key", width: 200 },
  // { field: "status", headerName: "Status", type:'singleSelect', width: 200,  valueOptions: [{ value: '16', label: "in Dev" }] },
  // { field: "assignee", headerName: "Assignee", width: 200 },
  { field: "summary", headerName: "summary", width: 300 },
  { field: "story_point", headerName: "Story Point", width: 300 },
  { field: "sprint", headerName: "Sprint", width: 300 },
  // { field: "reported_by", headerName: "Reported By", width: 300 },
  // {
  //   field: "reported_by", headerName: "Reported By", width: 200,
  //   renderCell: (params) => {
  //     return (
  //       <div className="cellWithStatus">
  //         {params.row.reported_by && params.row.reported_by.display_name ? params.row.reported_by.display_name : params.row.reported_by}
  //       </div>
  //     );
  //   }
  // },
  {
    field: "created_on", headerName: "Created Date", width: 200, type: "date",
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.created_on ? moment(params.row.created_on).format('YYYY-MM-DD') : ""}
        </div>
      );
    }
  },
  {
    field: "updated_on", headerName: "Updated Date", width: 200, type: "date",
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.updated_on ? moment(params.row.updated_on).format('YYYY-MM-DD') : ""}
        </div>
      );
    }
  },
  {
    field: "is_jira_issue", headerName: "Is Jira Issue", width: 200,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.is_jira_issue}`}>
          {params.row.is_jira_issue === true ? 'Active' : 'Inactive'}
        </div>
      );
    }
  },



]

//columns heading for month wise attendance report
export const MonthWiseAttendanceReportColumns = [
  { field: "emp_code", headerName: "Emp Code", sortable: false, filterable: false, width: 130 },
  { field: "display_name", headerName: "Name", sortable: false, filterable: false, width: 230 },
  { field: "attendance_month", headerName: "Month", sortable: false, filterable: false, width: 130 },
  { field: "total_working_days", headerName: "Total Working Days", sortable: false, filterable: false, width: 230 },
  { field: "actual_working_days", headerName: "Actual Working Days", sortable: false, filterable: false, width: 220 },
  { field: "total_working_hours", headerName: "Total Working Hours", sortable: false, filterable: false, width: 230 },
  { field: "break_duration_minutes", headerName: "Total Break Hours", sortable: false, filterable: false, width: 230 },
  { field: "total_effective_working_hours", headerName: "Total Effective Working Hours", sortable: false, filterable: false, width: 300 },
  { field: "expected_working_hours", headerName: "Expected Working Hours", sortable: false, filterable: false, width: 250 },
]

//columns heading for Menu List
export const menuListColumn = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "title", headerName: "Title", width: 230 },
  { field: "url_slug", headerName: "Slug", width: 230 },
  { field: "description", headerName: "Description", width: 230 },
  {
    field: "roles",
    headerName: "Permission",
    width: 300,
    filterable: false,
    renderCell: (params) => {
      let list = params.row.roles && params.row.roles.length > 0 ? params.row.roles : []
      var resArr = [];
      list.length > 0 && list.filter(function (item) {
        var i = resArr.findIndex(x => (x.role_id === item.role_id && x.menu_id === item && x.status === item.status));
        if (i <= -1) {
          resArr.push(item);
        }
        return null;
      });
      return (
        <div className={`cellWithStatus ellips-css-data`}>
          {resArr.length > 0 && resArr.map((e, index) => `${index ? ', ' : ''}${(e.role_name).trim()}`)}
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];
//columns heading for attendance report
export const AttendanceReportColumns = [
  {
    field: "punchin_date", headerName: 'Date', type: 'date', width: 150, sortable: false, filterable: false, renderCell: (params) => {
      return (
        <div style={params.row.punchin_date === 'No Of Days' ? { fontWeight: 'bold' } : {}} className={`cellWithStatus`}>
          {params.row.punchin_date}
        </div>
      );
    },
  },
  {
    field: "name", headerName: 'Name', width: 210, sortable: false, filterable: false, renderCell: (params) => {
      return (
        <div style={params.row.name === 'Total Break Hours' ? { fontWeight: 'bold' } : {}} className={`cellWithStatus`}>
          {params.row.name}
        </div>
      );
    }
  },
  {
    field: "break_hours", headerName: 'Break Hours', sortable: false, filterable: false, width: 240, renderCell: (params) => {
      return (
        <div style={params.row.break_hours === 'Total Effective Working Hours' ? { fontWeight: 'bold' } : {}} className={`cellWithStatus`}>
          {params.row.break_hours}
        </div>
      );
    }
  },
  {
    field: "effective_working_hours", headerName: 'Actual Working Hours', sortable: false, filterable: false, width: 240,
    renderCell: (params) => {
      return (
        <div style={params.row.effective_working_hours === 'Total Working Hours' ? { fontWeight: 'bold' } : {}} className={`cellWithStatus`}>
          {params.row.effective_working_hours}
        </div>
      );
    }
  },
  {
    field: "working_hours", headerName: 'Total Working Hours', sortable: false, filterable: false, width: 240,
    renderCell: (params) => {
      return (
        <div style={params.row.working_hours === 'Total Expected Working Hours' ? { fontWeight: 'bold' } : {}} className={`cellWithStatus`}>
          {params.row.working_hours}
        </div>
      );
    }
  },
  { field: "expected_working_hours", headerName: 'Expected Working Hours', sortable: false, filterable: false, width: 240 },
  { field: "emp_code", headerName: 'Emp Code', width: 150, sortable: false, filterable: false },
  { field: "punchin_time", headerName: 'Punch In Time', width: 200, sortable: false, filterable: false, },
  { field: "punch_out_time", headerName: 'Punch Out Time', width: 240, sortable: false, filterable: false, },
  { field: "expected_punch_in_date_time", headerName: 'Expected Punch-In Date-Time', sortable: false, filterable: false, width: 280 },
  { field: "expected_punch_out_date_time", headerName: 'Expected Punch-Out Date-Time', sortable: false, filterable: false, width: 280 },
]

//columns heading for third party
export const ThirdPartyColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "name", headerName: "Name", width: 230 },
  { field: "code", headerName: "Code", width: 230 },
  { field: "description", headerName: "Description", width: 300 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

//columns heading for third party
export const JobPostColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "job_title", headerName: "Job Title", width: 230 },
  {
    field: "job_description", headerName: "Job Description", width: 300,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus`}>
          <span dangerouslySetInnerHTML={{ __html: params.row.job_description }} />
        </div>
      );
    }
  },
  { field: "work_mode", headerName: "Work Mode", width: 300 },
  {
    field: "job_experience", headerName: "Work Experience", width: 150,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus`}>
          {params.row.job_experience && params.row.job_experience} {params.row.experience_type && params.row.experience_type}
        </div>
      );
    }
  },
  {
    field: "industry_id", headerName: "Industry", width: 230,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus`}>
          {params.row.industry && params.row.industry.industry_name}
        </div>
      );
    }
  },
  // { field: "education_qualification", headerName: "Qualification", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

export const SkillColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "skill_name", headerName: "Skills", width: 230 },
  { field: "skill_key", headerName: "Skill-Key", width: 230 },
  { field: "description", headerName: "Description", width: 300 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

export const IndustryColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "industry_name", headerName: "Industry Name", width: 230 },
  { field: "industry_type", headerName: "Industry Type", width: 230 },
  { field: "description", headerName: "Description", width: 300 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

export const QualificationColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "education_qualification_name", headerName: "Education Name", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

export const phoneCallDetailsColumn = [
  { field: 'id', headerName: 'ID', width: 10 },
  { field: 'destination_number', headerName: 'Destination Number', width: 160 },
  { field: 'agent_name', headerName: 'Agent Name', width: 160 },
  { field: 'call_duration', headerName: 'Call Duration', width: 130 },
  { field: 'answer_duration', headerName: 'Answer Duration', width: 130 },
  {
    field: "call_date", headerName: "Call Date", type: 'date', width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {moment(params.row.call_date).format('YYYY-MM-DD')}
        </div>
      );
    },
  },
  {
    field: 'call_time',
    headerName: 'Call Time',
    width: 150,
  },
  {
    field: "direction", headerName: "Call Type", width: 200,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.direction}`}>
          {params.row.direction === 1 ? 'Out-bound' : 'In-bound'}
        </div>
      );
    },
  },
  { field: 'state', headerName: 'State', width: 180 },
  {
    field: 'description',
    headerName: 'Description',
    width: 250,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.description ? params.row.description : ""} >
          <div className="cellWithStatus">
            {params.row.description}
          </div>
        </Tooltip >
      )
    }
  },

];

export const myPhoneCallDetailsColumn = [
  { field: 'id', headerName: 'ID', width: 10 },
  { field: 'destination_number', headerName: 'Destination Number', width: 190 },
  { field: 'call_duration', headerName: 'Call Duration', width: 130 },
  { field: 'answer_duration', headerName: 'Answer Duration', width: 150 },
  {
    field: "call_date", headerName: "Call Date", type: 'date', width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {moment(params.row.call_date).format('YYYY-MM-DD')}
        </div>
      );
    },
  },
  {
    field: 'call_time',
    headerName: 'Call Time',
    width: 150,
  },
  {
    field: "direction", headerName: "Call Type", width: 200,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.direction}`}>
          {params.row.direction === 1 ? 'Out-bound' : 'In-bound'}
        </div>
      );
    },
  },
  { field: 'state', headerName: 'State', width: 180 },
  {
    field: 'description',
    headerName: 'Description',
    width: 250,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.description ? params.row.description : ""} >
          <div className="cellWithStatus">
            {params.row.description}
          </div>
        </Tooltip >
      )
    }
  },
];

export const zoomCallDetailsColumn = [
  { field: 'id', headerName: 'ID', width: 10 },
  { field: 'zoom_meeting_id', headerName: 'Zoom Meeting Id', width: 200 },
  { field: 'topic', headerName: 'Topic', width: 250 },
  {
    field: 'agenda',
    headerName: 'Agenda',
    width: 250,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.agenda ? params.row.agenda : ""} >
          <div className="cellWithStatus">
            {params.row.agenda}
          </div>
        </Tooltip >
      )
    }
  },
  { field: 'duration', headerName: 'Duration (in min)', width: 210 },
  {
    field: 'host_email',
    headerName: 'Host Email',
    width: 250,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.host_email ? params.row.host_email : ""} >
          <div className="cellWithStatus">
            {params.row.host_email}
          </div>
        </Tooltip >
      )
    }
  },
  {
    field: 'uuid',
    headerName: 'UUID',
    width: 200,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.uuid ? params.row.uuid : ""} >
          <div className="cellWithStatus">
            {params.row.uuid}
          </div>
        </Tooltip >
      )
    }
  },
  { field: 'created_on', headerName: 'Created On', width: 200 },
  // {
  //   field: "created_on", headerName: "Created On", type: 'date', width: 200,
  //   renderCell: (params) => {
  //     return (
  //       <div className="cellWithStatus">
  //         {moment(params.row.created_on).format('YYYY-MM-DD')}
  //       </div>
  //     );
  //   },
  // },
  {
    field: 'project',
    headerName: 'Project Name',
    width: 250,
    // filterable: false,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.project && params.row.project.project_name}
        </div>
      )
    }
  },
];