import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./report.scss"
import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbarContainer, GridCsvExportMenuItem } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import LoaderForm from "../../components/common-function/LoaderForm";
import { STATUSES } from "../../redux/common-status/constant";
import { useNavigate } from "react-router-dom";
import { getConsolidateMonthlyList } from "../../redux/features/calendarSlice";
import { resourceList } from "../../redux/features/resourceSlice";
import moment from "moment";
import { Button, FormControl, TextField, Autocomplete, TablePagination, Tooltip } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ArrowBack, Download } from '@mui/icons-material';
import dayjs from 'dayjs';
import TablePaginationActions from "../../components/TablePaginationActions";
const { REACT_APP_API_BASEURL } = process.env;

const AttendanceReportStatus = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { status, error, consolidateMonthlyList, consolidateHeader } = useSelector(state => state.calender)
    const { data } = useSelector(state => state.resource)
    const [pageSize, setPageSize] = useState(10)
    //const [header, setHeader] = useState([])
    const [loading, setLoading] = useState(true)
    const [date, setDate] = useState(new Date());
    const [downliadFile, setDownloadFile] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [resourceId, setResourceId] = useState('select resource');
    const [filterQuery, setFilterQuery] = useState(`{"attendance_month":"${moment().format('YYYY-MM')}", "skip":0, "limit":10}`)
    const [limit, setLimit] = useState(10);
    
    useEffect(() => {
        dispatch(getConsolidateMonthlyList(filterQuery))
        dispatch(resourceList(`{"no_limit":"True","order_by":{"column":"name"}}`))
    }, [])



    const setYearAndMonth = (newValue) => {
        const d = new Date(newValue);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let id_date = `"${year}-${month}"`;
        if (resourceId !== 'select resource') {
            let filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "attendance_month":${id_date},"skip":0, "limit":10}`
            dispatch(getConsolidateMonthlyList(filter))
            setLoading(true)
            setDate(newValue)
        } else {
            let filter = `{"attendance_month":${id_date}, "skip":0, "limit":10}`
            dispatch(getConsolidateMonthlyList(filter))
            setLoading(true)
            setDate(newValue)
        }
    }

    if (loading === true && consolidateMonthlyList.data && consolidateMonthlyList.data) {
        setLoading(false)
    }

    const handleChange = (evt) => {
        const d = new Date(date);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let id_date = `"${year}-${month}"`;
        if (evt) {
            const { name, value } = evt.target || evt;
            if (name === 'resourceId') {
                let filter = `{"filter":{"display_name":{"value":"${value}","operation":"icontains"}}, "attendance_month":${id_date}, "skip":0, "limit":10}`
                dispatch(getConsolidateMonthlyList(filter))
                setResourceId(value);
                setLoading(true)
            }
        } else {
            setResourceId('select resource');
            let filter = `{"attendance_month":${id_date}, "skip":0, "limit":10}`
            dispatch(getConsolidateMonthlyList(filter))
            setLoading(true)
        }
    }

    // const handlePageSizeChange = (newPageSize) => {
    //     setPageSize(newPageSize)
    //     const d = new Date(date);
    //     let year = d.getFullYear();
    //     let month = d.getMonth() + 1;
    //     let id_date = `"${year}-${month}"`;
    //     let filter;
    //     if (resourceId !== 'select resource') {
    //         filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "attendance_month":${id_date}, "skip":0, "limit":${newPageSize}}`

    //     } else {
    //         filter = `{"attendance_month":${id_date}, "skip":0, "limit":${newPageSize}}`
    //     }
    //     dispatch(getConsolidateMonthlyList(filter))
    //     setLoading(true)
    // }
    const handleClick = (url) => {
        setDownloadFile(false)
        window.open(url);
    }
    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        const d = new Date(date);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let id_date = `"${year}-${month}"`;
        let filter;
        if (resourceId !== 'select resource') {
            filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "attendance_month":${id_date}, "skip":0, "limit":${limit}}`

        } else {
            filter = `{"attendance_month":${id_date}, "skip":0, "limit":${limit}}`
        }
        let newFilterData = JSON.parse(filter);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        dispatch(getConsolidateMonthlyList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        const d = new Date(date);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let id_date = `"${year}-${month}"`;
        let filter;
        if (resourceId !== 'select resource') {
            filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "attendance_month":${id_date}, "skip":0, "limit":${newPageSize.target.value}}`

        } else {
            filter = `{"attendance_month":${id_date}, "skip":0, "limit":${newPageSize.target.value}}`
        }
        let newFilterData = JSON.parse(filter);

        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        dispatch(getConsolidateMonthlyList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(consolidateMonthlyList.data && consolidateMonthlyList.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // pagination 
    // const handlePageChange = (newPage) => {
    //     setCurrentPage(newPage)
    //     let skip = pageSize
    //     if (newPage >= currentPage) {
    //         if (newPage === 0) {
    //             skip = (currentPage * pageSize)
    //         } else {
    //             skip = (currentPage * pageSize) + pageSize
    //         }
    //     } else if (newPage < currentPage) {

    //         if (skip < 0) {
    //             skip = pageSize
    //         } else {
    //             skip = currentPage * pageSize - pageSize
    //         }

    //     }
    //     else {
    //         skip = 0
    //     }
    //     const d = new Date(date);
    //     let year = d.getFullYear();
    //     let month = d.getMonth() + 1;
    //     let id_date = `"${year}-${month}"`;
    //     let filter;
    //     if (resourceId !== 'select resource') {
    //         filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "attendance_month":${id_date}, "skip":0, "limit":${pageSize}}`

    //     } else {
    //         filter = `{"attendance_month":${id_date}, "skip":0, "limit":${pageSize}}`
    //     }
    //     let newFilterData = JSON.parse(filter);

    //     newFilterData['skip'] = skip
    //     newFilterData['limit'] = pageSize
    //     dispatch(getConsolidateMonthlyList(JSON.stringify(newFilterData)))
    //     setLoading(true)
    // }


    if (downliadFile === true) {
        let api_url = consolidateMonthlyList && consolidateMonthlyList.data.download_api_url
        if (REACT_APP_API_BASEURL && api_url) {
            let url = REACT_APP_API_BASEURL + api_url;
            if (url) {
                handleClick(url.replace("api//", "api/"))
                dispatch(getConsolidateMonthlyList(filterQuery))
                setLoading(true)
            }
        }
    }

    const downloadCsvFile = () => {
        const d = new Date(date);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let id_date = `"${year}-${month}"`;
        let filter;
        if (resourceId !== 'select resource') {
            filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "for_download": True, "attendance_month":${id_date}, "no_limit":True}}`

        } else {
            filter = `{"for_download": True, "attendance_month":${id_date}, "no_limit":True}`
        }
        setDownloadFile(true)
        dispatch(getConsolidateMonthlyList(filter))
    }

    const onFilterChange = (filterModel) => {
        const d = new Date(date);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let id_date = `"${year}-${month}"`;
        let filterData;
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            if (resourceId !== 'select resource') {
                filterData = `{"or": {"display_name":{"value":"${resourceId}","operation":"icontains"}, "${[filterModel.items[0].columnField]}" : {"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}}, "attendance_month":${id_date}, "skip":0, "limit":${limit}}`

            } else {
                filterData = `{"filter" : {"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"attendance_month":${id_date}, "skip":0, "limit":${limit}}`
            }
            //const filterData = `{"filter":{"resource_manager_id":{"value":${user.id},"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${pageSize}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getConsolidateMonthlyList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            setPageSize(10)
            let filter;
            if (resourceId !== 'select resource') {
                filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "attendance_month":${id_date}, "skip":0, "limit":10}`

            } else {
                filter = `{"attendance_month":${id_date}, "skip":0, "limit":10}`
            }
            setFilterQuery(filter)
            dispatch(getConsolidateMonthlyList(filter))
        }
        setLoading(true)
    }

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridCsvExportMenuItem style={{ backgroundColor: '#3f51b5', color: '#fff' }} />
            </GridToolbarContainer>
        );
    }
    let i = 0
    return (
        <div className="listing">
            <Sidebar />
            <div className="listingContainer">
                <Navbar />
                <div className="datatable">
                    <div className="datatableTitle">
                        <h3>Attendance Report Status</h3>
                        <Tooltip title={"Back"}>
                            <button onClick={() => navigate(-1)} className="btn-add-new ">
                                <ArrowBack />
                            </button>
                        </Tooltip>
                    </div>
                    {(status === STATUSES.LOADING) && <LoaderForm />}

                    <div style={{ display: "flex", justifyContent: "space-betwwen", marginTop: "30px", marginBottom: "30px" }}>

                        {/*year and month section*/}
                        <LocalizationProvider dateAdapter={AdapterDayjs}style={{ flexBasis: "18%", marginTop: "8px", marginRight: "10px" }} >
                            <DatePicker
                            

                                views={['month', 'year']}
                                label="Year and Month"
                                minDate={dayjs('2012-03-01')}
                                value={date}
                                onChange={(newValue) => {
                                    setYearAndMonth(newValue);
                                }}
                                format="YYYY-MM"
                                renderInput={(params) => <TextField disabled={true} {...params} helperText={null} />}
                            />
                        </LocalizationProvider>

                        {/*select resource section*/}
                        <FormControl style={{ flexBasis: "18%", marginLeft: "8px" }}>
                            <Autocomplete
                                id="controllable-states-demo"
                                onChange={(event, newValue) => {
                                    handleChange(newValue);
                                }}
                                options={data && data.data && data.data.rows.map((e) => {
                                    return { name: "resourceId", value: e.display_name, label: e.name + "(" + e.email + ")" }
                                })}
                                renderInput={(params) => <TextField {...params} label="select resource" />}
                            />
                        </FormControl>
                        <Button startIcon={<Download />} style={{ backgroundColor: '#3f51b5', flexBasis: "14%",  color: '#fff', marginLeft: "10px", }} onClick={() => downloadCsvFile()}>CSV(server)</Button>

                    </div>
                    {loading === true && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={{
                            id: false
                        }}
                        rows={consolidateMonthlyList.data && consolidateMonthlyList.data.rows && consolidateMonthlyList.data.rows.length > 0 && consolidateMonthlyList.data.rows || []}
                        columns={consolidateHeader || []}
                        pageSize={pageSize}
                        //rowsPerPageOptions={[10, 25, 50]}
                        //onPageSizeChange={(pageSize) => handlePageSizeChange(pageSize)}
                        //paginationMode="server"
                        getRowId={(row) => row + i++}
                        //page={currentPage}
                        //rowCount={consolidateMonthlyList.data && consolidateMonthlyList.data.count || 0}
                        //onPageChange={(newPage) => handlePageChange(newPage)}
                        style={{ fontSize: 16 }}
                        components={{
                            Toolbar: CustomToolbar,
                            Pagination:CustomPagination
                        }}
                        onFilterModelChange={onFilterChange}
                    />
                </div>

            </div>

        </div>
    )
}
export default AttendanceReportStatus