import React from 'react'
// import { useLocation } from 'react-router-dom'
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
// import ResourceEdit from '../resources/ResourceEdit';

const EditProfile = () => {
  // const location=useLocation();
  return (
    // <div className='new'>
    //   {/* <ResourceEdit resId={location.state.id} /> */}
    // </div>

    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <h3 style={{ marginLeft: "20rem" }}>Access Denied! Contact your administrator.</h3>
        </div>
      </div>
    </div>
  )
}

export default EditProfile