import "./sidebar.scss"
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import FolderIcon from '@mui/icons-material/Folder';
import ContactsIcon from '@mui/icons-material/Contacts';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
//import { DarkModeContext} from "../../context/darkModeContext";
import { useDispatch, useSelector } from "react-redux";
import { logout } from '../../redux/features/authSlice'
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import BadgeIcon from '@mui/icons-material/Badge';
import LocalStorageDataService from '../common-function/GetLocalstorage';
import { Ballot, Assignment, MenuBook } from "@mui/icons-material";
import Logo from '../../images/fevicon.png'
import { getMenuListForSideBar } from "../../redux/features/sidebarSlice";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';



let user = LocalStorageDataService.userDetail();
let tmsRole = user && user.tms_role
const tmsRoleId = user && user.tms_role_id
const roleWiseFilter = tmsRole && `{"filter":{"status":{"value":"True","operation": "eq"},"role_id":{"value":${tmsRoleId},"operation": "eq"}},"no_limit":True}`

// const siderData =
//   (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) ? [

//     {
//       icon: 'DashboardIcon',
//       menu: 'Dashboard',
//       link: '/dashboard',
//       status: 'active',
//       submenu: [
//         {
//           icon: 'SupervisedUserCircleIcon',
//           title: 'Manage User Type',
//           status: 'active',
//           submenus: [
//             {
//               menu: 'All User Type',
//               link: '/all-user',
//               icon: '',
//               status: 'active',
//             },
//             {
//               menu: 'Add New User Type',
//               link: '/add-new-user',
//               status: 'active',
//             }
//           ]
//         },
//         {
//           icon: 'ControlCameraIcon',
//           title: 'Manage Roles',
//           status: 'active',
//           submenus: [
//             {
//               menu: 'All Roles',
//               link: '/roles',
//               icon: '',
//               status: 'active',
//             },
//             {
//               menu: 'Add New Role',
//               link: '/add-new-role',
//               icon: '',
//               status: 'active',
//             }
//           ]
//         },
//         {
//           icon: 'FolderIcon',
//           title: 'Manage Project',
//           status: 'active',
//           submenus: [
//             {
//               menu: 'All Projects',
//               link: '/all-projects',
//               icon: ''
//             },
//             {
//               menu: 'Add New Project',
//               link: '/add-new-project',
//               icon: '',
//               status: 'active',
//             }, {
//               menu: 'My Project',
//               link: '/my-projects',
//               icon: '',
//               status: 'active',
//             }
//           ]
//         },
//         {
//           icon: 'FolderIcon',
//           title: 'Manage Departments',
//           status: 'active',
//           submenus: [
//             {
//               menu: 'All Departments',
//               link: '/all-depratment',
//               icon: ''
//             },
//             {
//               menu: 'Add New Department',
//               link: '/add-new-department',
//               icon: '',
//               status: 'active',
//             }
//           ]
//         },
//         {
//           icon: 'ContactsIcon',
//           title: 'Resources',
//           status: 'active',
//           submenus: [
//             {
//               menu: 'All Resources',
//               link: '/all-resource',
//               icon: '',
//               status: 'active'
//             },
//             {
//               menu: 'Add New Resource',
//               link: '/all-resource/add',
//               icon: '',
//               status: 'active',
//             }
//           ]
//         },
//         {
//           icon: 'CardMembershipIcon',
//           title: 'Manage Shift',
//           status: 'active',
//           submenus: [
//             {
//               menu: 'All Shift',
//               link: '/all-shift',
//               icon: ''
//             },
//             {
//               menu: 'Add New shift',
//               link: '/add-new-shift',
//               icon: '',
//               status: 'active',
//             },
//             {
//               menu: 'Assign Shift',
//               link: '/assign-shift',
//               icon: '',
//               status: 'active',
//             }
//           ]
//         },
//         {
//           icon: 'BadgeIcon',
//           title: 'Manage Resource Credentials',
//           status: 'active',
//           submenus: [
//             {
//               icon: '',
//               title: 'Credential heads',
//               status: 'active',
//               submenus: [
//                 {
//                   menu: 'All Credential heads',
//                   link: '/all-credentials-head',
//                   icon: '',
//                 },
//                 {
//                   menu: 'Add New Credential heads',
//                   link: '/add-credentials-head',
//                   icon: '',
//                   status: 'active',
//                 }
//               ],
//             },
//             {
//               icon: '',
//               title: 'Resource credentials',
//               status: 'active',
//               p_id: 2,
//               submenus: [
//                 {
//                   menu: 'All Resource Credential',
//                   link: '/all-resource-credential',
//                   icon: '',
//                 },
//                 {
//                   menu: 'Add Resource Credential',
//                   link: '/add-resource-credential',
//                   icon: '',
//                   status: 'active',
//                 }
//               ]
//             }]
//         },
//         {
//           icon: 'AutorenewIcon',
//           title: 'Manage User Status',
//           status: 'active',
//           submenus: [
//             {
//               icon: '',
//               title: 'Jira Status',
//               status: 'active',
//               submenus: [
//                 {
//                   menu: 'All Jira Status',
//                   link: '/all-jira',
//                   icon: '',
//                 },
//                 {
//                   menu: 'Add New Jira Status',
//                   link: '/all-jira/add',
//                   icon: '',
//                   status: 'active',
//                 }
//               ]
//             }

//           ]
//         },
//         {
//           icon: 'CalendarMonthIcon',
//           title: 'Attendance',
//           status: 'active',
//           menuItem: [
//             {
//               menu: 'My Attendance',
//               link: '/calender',
//               icon: ''
//             },
//             {
//               menu: 'My Presence status',
//               link: '/my-presence',
//               icon: '',
//               status: 'active',
//             },
//             {
//               menu: 'Team-Regularization',
//               link: '/team-regularization',
//               icon: '',
//               status: 'active',
//             },
//             {
//               menu: 'Team Attendance',
//               link: '/team-attendance',
//               icon: '',
//               status: 'active',
//             },
//             {
//               menu: 'My Comp Off',
//               link: '/my-comp-off',
//               icon: '',
//               status: 'active',
//             },
//             {
//               menu: 'Team Comp Off',
//               link: '/team-comp-off',
//               icon: '',
//               status: 'active',
//             },
//           ],
//         },
//         //   submenus: [{
//         //     title: 'Manage my Tasks',
//         //     icon: '',
//         //     status: 'active',
//         //     submenus: [
//         //       {
//         //         menu: 'All tasks',
//         //         link: '/task',
//         //         icon: '',
//         //         status: 'active',
//         //       }
//         //     ]
//         //   }],
//         // },
//         {
//           icon: 'FolderIcon',
//           title: 'Sprint',
//           status: 'active',
//           submenus: [
//             {
//               menu: 'All Sprint',
//               link: '/sprint-list',
//               icon: ''
//             }
//           ]
//         },
//         {
//           icon: 'Assignment',
//           title: 'Task',
//           status: 'active',
//           submenus: [
//             {
//               menu: 'All Task',
//               link: '/task',
//               icon: ''
//             },
//             {
//               menu: 'My Task',
//               link: '/my-task',
//               icon: '',
//               status: 'active',
//             }
//           ]
//         },
//         {
//           icon: 'PictureAsPdfIcon',
//           title: 'Reports',
//           status: 'active',
//           submenus: [
//             {
//               menu: 'Daily Attendance',
//               link: '/attendance-report',
//               icon: '',
//               status: 'active',
//             },
//             {
//               menu: 'Monthly Attendance',
//               link: '/month-wise-report',
//               icon: '',
//               status: 'active',
//             },
//             {
//               menu: 'Attendance Report Status',
//               link: '/report-status',
//               icon: '',
//               status: 'active',
//             }

//           ]
//         },
//         {
//           icon: 'MenuBook',
//           title: 'Master',
//           status: 'active',
//           submenus: [
//             {
//               menu: 'Menu List',
//               link: '/master-menu-list',
//               icon: ''
//             },
//             {
//               menu: 'Third Party',
//               link: '/third-party',
//               icon: ''
//             }, {
//               menu: 'Domains',
//               link: '/domains',
//               icon: ''
//             }

//           ]
//         },
//       ],
//       menuItem: [
//         // {
//         //   icon: 'CalendarMonthIcon',
//         //   menu: 'My Attendance',
//         //   link: '/calender',
//         //   status: 'active',
//         // },
//         // {
//         //   icon: 'CalendarMonthIcon',
//         //   menu: 'My Presence',
//         //   link: '/my-presence',
//         //   status: 'active',
//         // },
//         // {
//         //   icon: 'Assignment',
//         //   menu: 'My Tasks',
//         //   link: '/task',
//         //   status: 'active',
//         // },
//         {
//           icon: 'FolderIcon',
//           menu: 'My Credentials',
//           link: '/my-resource-credential',
//           status: 'active',
//         },
//         // {
//         //   icon: 'EngineeringIcon',
//         //   menu: 'Setting',
//         //   link: '/setting',
//         //   status: 'active',
//         // },
//         {
//           icon: 'CalendarMonthIcon',
//           menu: 'Holidays',
//           link: '/holidays',
//           status: 'active',
//         },
//         {
//           icon: 'CalendarMonthIcon',
//           menu: 'Holiday-Calender',
//           link: '/holiday-calender',
//           status: 'active',
//         },
//         {
//           icon: 'AccountCircleIcon',
//           menu: 'Profile',
//           link: '/profile',
//           status: 'active',
//         },
//         {
//           icon: 'LogoutIcon',
//           menu: 'Logout',
//           link: '/',
//           status: 'active',
//         }]
//     }
//   ]
//     :
//     [{
//       icon: 'DashboardIcon',
//       menu: 'Dashboard',
//       link: '/dashboard',
//       status: 'active',
//       submenu: [
//         {
//           icon: 'CalendarMonthIcon',
//           title: 'Attendance',
//           status: 'active',
//           menuItem: [
//             {
//               menu: 'My Attendance',
//               link: '/calender',
//               icon: ''
//             },
//             {
//               menu: 'My Presence status',
//               link: '/my-presence',
//               icon: '',
//               status: 'active',
//             },
//             {
//               menu: 'Team-Regularization',
//               link: '/team-regularization',
//               icon: '',
//               status: 'active',
//             },
//             {
//               menu: 'Team Attendance',
//               link: '/team-attendance',
//               icon: '',
//               status: 'active',
//             },
//             {
//               menu: 'My Comp Off',
//               link: '/my-comp-off',
//               icon: '',
//               status: 'active',
//             },
//             {
//               menu: 'Team Comp Off',
//               link: '/team-comp-off',
//               icon: '',
//               status: 'active',
//             },
//           ],
//         },
//       ],
//       menuItem: [
//         // {
//         //   icon: 'CalendarMonthIcon',
//         //   menu: 'My Attendance',
//         //   link: '/calender',
//         //   status: 'active',
//         // },
//         // {
//         //   icon: 'CalendarMonthIcon',
//         //   menu: 'My Presence',
//         //   link: '/my-presence',
//         //   status: 'active',
//         // },
//         {
//           icon: 'Ballot',
//           menu: 'My Projects',
//           link: '/my-projects',
//           status: 'active',
//         },
//         {
//           icon: 'Assignment',
//           menu: 'My Tasks',
//           link: '/my-task',
//           status: 'active',
//         },
//         {
//           icon: 'FolderIcon',
//           menu: 'My Credentials',
//           link: '/my-resource-credential',
//           status: 'active',
//         },
//         // {
//         //   icon: 'EngineeringIcon',
//         //   menu: 'Settings',
//         //   link: '/setting',
//         //   status: 'active',
//         // },
//         {
//           icon: 'AccountCircleIcon',
//           menu: 'Profile',
//           link: '/profile',
//           status: 'active',
//         },
//         {
//           icon: 'LogoutIcon',
//           menu: 'Logout',
//           link: '/',
//           status: 'active',
//         }]
//     }]


const Sidebar = () => {
  const { logoutUser, authLoggedIn } = useSelector((state) => state.authUser)
  const { sidebarMenuList } = useSelector((state) => state.sidebar)

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const logout_user = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  // const nothing = () => {
  //   ///for null action 
  // }

  if (authLoggedIn === false && logoutUser && logoutUser.message) {
    navigate('/')
  }

  const { data } = useSelector((state) => state.navbartoggle);

  useEffect(() => {
    dispatch(getMenuListForSideBar(roleWiseFilter))
  }, [])

  // console.log(sidebarMenuList?.data?.rows, "sidebarMenuList")

  // const subItemsMapping = (item, index) => {
  //   // console.log(item,"iiii")
  //   return
  //   <MenuItem key={index}>
  //     {item.title}
  //     {item.link && <Link to={"/" + item.link}></Link>}
  //   </MenuItem>
  // }


  return (
    // custom side bar menu code
    // <div className={data === true ? 'sidebar-collapse sidebar' : 'sidebar'} >
    //   <div className="top">
    //     <Link to="/dashboard" style={{ textDecoration: "none" }}>

    //       <span className="logo"><img src={Logo} alt={Logo} /><span className="logo-text">RTMS</span></span>
    //     </Link>
    //   </div>
    //   <hr />
    //   <ProSidebar>
    //     {siderData.map((item, index) => {
    //       return (
    //         <Menu key={index}>
    //           <MenuItem icon={<DashboardIcon />}>
    //             {item.menu}
    //             <Link to={item.link} />
    //           </MenuItem>
    //           {item.submenu && item.submenu.map((subItem, subIndex) => {
    //             return (
    //               <SubMenu key={subIndex} title={subItem.title}
    //                 icon={subItem.icon === "SupervisedUserCircleIcon" ? <SupervisedUserCircleIcon /> :
    //                   subItem.icon === "ControlCameraIcon" ? <ControlCameraIcon /> :
    //                     subItem.icon === "FolderIcon" ? <FolderIcon /> :
    //                       subItem.icon === "ContactsIcon" ? <ContactsIcon /> :
    //                         subItem.icon === "CardMembershipIcon" ? <CardMembershipIcon /> :
    //                           subItem.icon === "AutorenewIcon" ? <AutorenewIcon /> :
    //                             subItem.icon === "CalendarMonthIcon" ? <CalendarMonthIcon /> :
    //                               subItem.icon === "BadgeIcon" ? <BadgeIcon /> :
    //                                 subItem.icon === "Assignment" ? <Assignment /> :
    //                                   subItem.icon === "MenuBook" ? <MenuBook /> :
    //                                     <PictureAsPdfIcon />}>
    //                 {subItem.icon === 'SupervisedUserCircleIcon' &&
    //                   subItem.submenus && subItem.submenus.map((subItems, subIndexs) => {
    //                     return subItemsMapping(subItems, subIndexs)
    //                   })
    //                 }
    //                 {subItem.icon === 'MenuBook' &&
    //                   subItem.submenus && subItem.submenus.map((subItems, subIndexs) => {
    //                     return subItemsMapping(subItems, subIndexs)
    //                   })
    //                 }
    //                 {subItem.icon === 'Assignment' &&
    //                   subItem.submenus && subItem.submenus.map((subItems, subIndexs) => {
    //                     return subItemsMapping(subItems, subIndexs)
    //                   })
    //                 }
    //                 {subItem.icon === 'ControlCameraIcon' &&
    //                   subItem.submenus && subItem.submenus.map((subItems, subIndexs) => {
    //                     return subItemsMapping(subItems, subIndexs)

    //                   })
    //                 }

    //                 {subItem.icon === 'FolderIcon' &&
    //                   subItem.submenus && subItem.submenus.map((subItems, subIndexs) => {
    //                     return subItemsMapping(subItems, subIndexs)
    //                   })
    //                 }
    //                 {subItem.icon === 'ContactsIcon' &&
    //                   subItem.submenus && subItem.submenus.map((subItems, subIndexs) => {
    //                     return subItemsMapping(subItems, subIndexs)
    //                   })
    //                 }
    //                 {subItem.icon === 'CardMembershipIcon' &&
    //                   subItem.submenus && subItem.submenus.map((subItems, subIndexs) => {
    //                     return subItemsMapping(subItems, subIndexs)
    //                   })
    //                 }
    //                 {subItem.icon === 'AutorenewIcon' &&
    //                   subItem.submenus && subItem.submenus.map((subItems, subIndexs) => {
    //                     return (
    //                       <SubMenu key={subIndexs} title={subItems.title}>
    //                         {subItems.submenus && subItems.submenus.map((l, i) => {
    //                           return subItemsMapping(l, i)
    //                         })}
    //                       </SubMenu>
    //                     )
    //                   })
    //                 }
    //                 {subItem.icon === 'BadgeIcon' &&
    //                   subItem.submenus && subItem.submenus.map((subItems, subIndexs) => {
    //                     return (
    //                       <SubMenu key={subIndexs} title={subItems.title}>
    //                         {subItems.submenus && subItems.submenus.map((l, i) => {
    //                           return subItemsMapping(l, i)
    //                         })}
    //                       </SubMenu>
    //                     )
    //                   })
    //                 }

    //                 {subItem.icon === 'CalendarMonthIcon' &&
    //                   subItem.menuItem && subItem.menuItem.map((subItems, subIndexs) => {
    //                     return subItemsMapping(subItems, subIndexs)
    //                   })
    //                 }

    //                 {subItem.icon === 'CalendarMonthIcon' &&
    //                   subItem.submenus && subItem.submenus.map((l, i) => {
    //                     return (
    //                       <SubMenu key={i} title={l.title} >
    //                         {l.submenus && l.submenus.map((lx, ix) => {
    //                           return subItemsMapping(lx, ix)
    //                         })}
    //                       </SubMenu>
    //                     )
    //                   })}

    //                 {subItem.icon === 'PictureAsPdfIcon' &&
    //                   subItem.submenus && subItem.submenus.map((subItems, subIndexs) => {
    //                     return subItemsMapping(subItems, subIndexs)
    //                   })
    //                 }

    //               </SubMenu>
    //             )
    //           })}
    //           {item.menuItem && item.menuItem.map((itm, i) => {
    //             return (
    //               <MenuItem onClick={itm.icon === "LogoutIcon" ? logout_user : nothing} key={i} icon={itm.icon === "EngineeringIcon" ? <EngineeringIcon /> :
    //                 itm.icon === "AccountCircleIcon" ? <AccountCircleIcon /> :
    //                   itm.icon === "CalendarMonthIcon" ? <CalendarMonthIcon /> :
    //                     itm.icon === "FolderIcon" ? <FolderIcon /> :
    //                       itm.icon === "Assignment" ? <Assignment /> :
    //                         itm.icon === "Ballot" ? <Ballot /> :
    //                           <LogoutIcon />}>
    //                 {itm.menu}
    //                 <Link to={itm.link} />
    //               </MenuItem>
    //             )
    //           })}
    //         </Menu>
    //       )
    //     })}
    //   </ProSidebar>

    // </div>

    // dynamic side bar menu code using api 
    <div className={data === true ? 'sidebar-collapse sidebar' : 'sidebar'} >
      <div className="top">
        <Link to="/dashboard" style={{ textDecoration: "none" }}>
          <span className="logo"><img src={Logo} alt={Logo} /><span className="logo-text">RTMS</span></span>
        </Link>
      </div>

      <ProSidebar>
        <Menu>
          {sidebarMenuList && sidebarMenuList.length > 0 && sidebarMenuList.map((item, index) => {
            return (
              item.submenu.length === 0 ?
                <MenuItem key={index} icon={
                  item.icon === "DashboardIcon" ? <DashboardIcon /> :
                    item.icon === "SupervisedUserCircleIcon" ? <SupervisedUserCircleIcon /> :
                      item.icon === "ControlCameraIcon" ? <ControlCameraIcon /> :
                        item.icon === "FolderIcon" ? <FolderIcon /> :
                          item.icon === "ContactsIcon" ? <ContactsIcon /> :
                            item.icon === "CardMembershipIcon" ? <CardMembershipIcon /> :
                              item.icon === "AutorenewIcon" ? <AutorenewIcon /> :
                                item.icon === "CalendarMonthIcon" ? <CalendarMonthIcon /> :
                                  item.icon === "BadgeIcon" ? <BadgeIcon /> :
                                    item.icon === "Assignment" ? <Assignment /> :
                                      item.icon === "MenuBook" ? <MenuBook /> :
                                        item.icon === "AccountCircleIcon" ? <AccountCircleIcon /> :
                                          item.icon === "Ballot" ? <Ballot /> :
                                            item.icon === "EngineeringIcon" ? <EngineeringIcon /> :
                                              item.icon === "LibraryBooksIcon" ? <LibraryBooksIcon /> :
                                                <PictureAsPdfIcon />}>

                  {item.title}
                  <Link to={"/" + item.link} />
                </MenuItem>
                : item.submenu.length > 0 &&
                <SubMenu key={index} title={item.title} icon={
                  item.icon === "DashboardIcon" ? <DashboardIcon /> :
                    item.icon === "SupervisedUserCircleIcon" ? <SupervisedUserCircleIcon /> :
                      item.icon === "ControlCameraIcon" ? <ControlCameraIcon /> :
                        item.icon === "FolderIcon" ? <FolderIcon /> :
                          item.icon === "ContactsIcon" ? <ContactsIcon /> :
                            item.icon === "CardMembershipIcon" ? <CardMembershipIcon /> :
                              item.icon === "AutorenewIcon" ? <AutorenewIcon /> :
                                item.icon === "CalendarMonthIcon" ? <CalendarMonthIcon /> :
                                  item.icon === "BadgeIcon" ? <BadgeIcon /> :
                                    item.icon === "Assignment" ? <Assignment /> :
                                      item.icon === "MenuBook" ? <MenuBook /> :
                                        item.icon === "AccountCircleIcon" ? <AccountCircleIcon /> :
                                          item.icon === "Ballot" ? <Ballot /> :
                                            item.icon === "EngineeringIcon" ? <EngineeringIcon /> :
                                              item.icon === "LibraryBooksIcon" ? <LibraryBooksIcon /> :
                                                <PictureAsPdfIcon />}>

                  {item.submenu.map((subItem, subIndex) => {
                    if (!subItem.link.match("edit") && !subItem.link.match("view")) {
                      //   console.log(subItem.link.match("edit"), "subbbbbbbb")
                      return (
                        subItem.menuItem.length === 0 ?

                          <MenuItem key={subIndex}>
                            {subItem.title}
                            <Link to={"/" + subItem.link} />
                          </MenuItem>

                          : subItem.menuItem.length > 0 &&

                          <SubMenu key={subIndex} title={subItem.title}>

                            {subItem.menuItem.map((submenusItem, submenusIndex) => {
                              if (!submenusItem.link.match("edit") && !submenusItem.link.match("view")) {
                                return (
                                  <MenuItem key={submenusIndex} >
                                    {submenusItem.title}
                                    {submenusItem.link && <Link to={"/" + submenusItem.link}></Link>}
                                  </MenuItem>
                                )
                              }
                            })
                            }

                          </SubMenu>

                        // <MenuItem key={subIndex}>
                        //   {subItem.title}
                        //   <Link to={"/" + subItem.link} />
                        // </MenuItem>
                      )
                    }
                  })}
                </SubMenu>
            )
          }
          )}
          <MenuItem icon={<LogoutIcon />} onClick={logout_user}>
            Logout
            <Link to={"/"} />
          </MenuItem>
        </Menu>
      </ProSidebar>
    </div>
  )
}

export default Sidebar
