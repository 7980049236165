import { Button, Dialog, DialogContent, DialogTitle, TablePagination, Tooltip } from '@mui/material'
import "./master.scss"
import { DataGrid } from '@mui/x-data-grid'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import LoaderForm from '../../components/common-function/LoaderForm'
import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import TablePaginationActions from '../../components/TablePaginationActions'
import { STATUSES } from "../../redux/common-status/constant";
import { getDomainList, getThirdPartyDomainList } from '../../redux/features/thirdPartySlice'
import { errorHandling } from "../../redux/features/authLogoutSlice";
import UcActionComponent from '../../components/common-function/UcActionComponent'
import { ArrowBack, Close } from '@mui/icons-material'
import Multiselect from 'multiselect-react-dropdown';
import dataService from '../../redux/services/data.service'

const ThirdPartyDomainMapping = () => {

    const { REACT_APP_ENV } = process.env;
    const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
    let userId = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { status, error, thirdParty_Domain, domainList } = useSelector(state => state.thirdParty)
    const [reload, setReload] = useState(false)

    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(`{"skip":0, "limit":10,"order_by":{"column":"-id"}}`)
    const [pageSize, setPageSize] = useState(10);
    const [limit, setLimit] = useState(10);
    // const [fieldName, setFieldName] = useState("")

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [openSingle, setOpenSingle] = useState(false)
    const [domainArray, setDomainArray] = useState([])
    const [domainsIds, setDomainsIds] = useState([])
    const [selectedValuesDomain, setSelectedValuesDomain] = useState([])
    const [thirdPartyData, setThirdPartyData] = useState({})

    if (status === `${STATUSES.SUCCESS}_domainList` && domainArray && domainArray.length === 0 && domainList) {
        let array = []
        domainList && domainList.map(e => { return array.push({ value: e.id, label: e.name, id: e.id }) })
        array.length > 0 && setDomainArray(array)
        // array.length > 0 && setOptions({ ...options, credentails_head_id: array })
    }

    useEffect(() => {
        dispatch(getThirdPartyDomainList(filterQuery))
        dispatch(getDomainList())
        if (status === STATUSES.FAILURE && error) {
            setReload(false)
            errorHandling(error, navigate)
        }
    }, [])

    //  Open Modal
    const onOpenModal = () => {
        setOpenSingle(true)
    }

    //  Close Modal
    const onCloseModal = () => {
        setThirdPartyData({});
        setOpenSingle(false)
        setSelectedValuesDomain([])
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getThirdPartyDomainList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getThirdPartyDomainList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(thirdParty_Domain && thirdParty_Domain.data && thirdParty_Domain.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    const onAdditionalBtn = (data) => {
        setSelectedValuesDomain(data.domains)
        const domains_ids = data.domains.map(e => (e.id))
        // console.log(domains_ids)
        domains_ids.length > 0 && setDomainsIds(domains_ids);
        onOpenModal()
        setThirdPartyData({
            name: data.name,
            third_party: data.id,
            domains: data.domains,
            status: data.status
        })
    }

    const handleChangeMulti = (event) => {
        const optArray = event.map((e) => e.id);
        setDomainsIds(optArray)
    };

    const onSubmitSingle = async (e) => {
        e.preventDefault()

        const request = {
            ...thirdPartyData,
            created_by: userId,
            domains: domainsIds
        }
        // validation
        if (domainsIds.length === 0) {
            return toast.error('Please select domain')
        }
        delete request.name;
        delete request.status;
        const response = await dataService.assign_domain_thirdparty(request);
        if (response && response.data && response.data.status === 200) {
            toast.success(response.data.message)
            dispatch(getThirdPartyDomainList(filterQuery))
            onCloseModal();
        } else {
            toast.error(response.data.message)
        }
    }

    const ThirdPartyColumns = [
        // { field: "id", headerName: "ID", width: 70, filterable: false, hideable: false },
        { field: "name", headerName: "Name", width: 230 },
        { field: "code", headerName: "Code", width: 230 },
        {
            field: "domains",
            headerName: "Domains",
            width: 380,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithImg">
                        {params.row.domains && params.row.domains.length ?
                            params.row.domains.map((domain, index) => {
                                return (
                                    <p key={index} style={{}}>
                                        {index === 0 && domain.name} {index === 0 && params.row.domains.length > 1 && ","}
                                        {params.row.domains.length > 1 && index === 1 && domain.name} {index === 1 && params.row.domains.length > 2 && ","}
                                    </p>
                                )
                            })
                            : ""
                        }
                        {(params.row.domains && params.row.domains.length > 2) ? `..[+${params.row.domains.length - 2}]` : ""}
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 160,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName=""
                            moduleName="third-party-domain"
                            rowid={params.row.id}
                            addMoreData={params.row}
                            additionalAction={[{ id: 1, key: "assign_domain", title: "Assign Domain" }]}
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={false}
                            editButton={false}
                            viewButton={false}
                            deleteButton={false}
                            extraButton={() => onAdditionalBtn(params.row)}
                        />
                    </div>
                );
            },
        },
    ]

    // filter
    const onFilterChange = (filterModel) => {
        // if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
        //     setFieldName(filterModel.items[0].columnField)
        // }
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                            filterModel.items[0].operatorValue === "is" ? 'eq' :
                                filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getThirdPartyDomainList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            setPageSize(10)
            setFilterQuery(`{"skip":0, "limit":10,"order_by":{"column":"-id"}}`)
            dispatch(getThirdPartyDomainList(`{"skip":0, "limit":10,"order_by":{"column":"-id"}}`))
        }
    }

    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Third Party Domains</h3>
                        <Tooltip title="Back">
                            <button onClick={() => navigate(-1)} className="btn-add-new ">
                                <ArrowBack />
                            </button>
                        </Tooltip>
                    </div>

                    {(status === STATUSES.LOADING || reload === true) && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={thirdParty_Domain && thirdParty_Domain.data && thirdParty_Domain.data.rows ? thirdParty_Domain.data.rows : []}
                        columns={ThirdPartyColumns}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        components={{
                            Pagination: CustomPagination,
                        }}
                    />
                </div>

                {/* model for single assignment */}
                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={openSingle}
                    onClose={onCloseModal}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                    sx={{ '& .MuiDialog-paper': { height: "450px" } }}
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={onCloseModal}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>

                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>Assign Domain To Third Party</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <div className="formInput">
                                    <label>
                                        <span style={{ fontWeight: "bold" }}>Third Party Name :</span> {thirdPartyData.name}
                                    </label>
                                </div>
                                <div className="formInput">
                                    <label >
                                        <span style={{ fontWeight: "bold" }}>Status :</span>
                                        {thirdPartyData.status === true ? ' Active' : " InActive"}
                                    </label>
                                </div>
                            </div>
                            <div className='add-new-user-form'>
                                <div className="formInput" style={{ flexBasis: "100%" }}>
                                    <label>Select Domains*</label>
                                    <Multiselect
                                        isObject={true}
                                        onRemove={handleChangeMulti}
                                        onSelect={handleChangeMulti}
                                        displayValue={"label"}
                                        selectedValues={selectedValuesDomain.map(e => ({ id: e.id, label: e.name, value: e.id }))}
                                        options={domainArray}
                                    />
                                </div>
                            </div>

                            <button className="submit-modal" style={{ marginTop: "40px" }} onClick={onSubmitSingle}>Assign</button>
                        </form>
                    </DialogContent>
                </Dialog>

            </div>
        </div >
    )
}
export default ThirdPartyDomainMapping