import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";


export const jiraStatusList = createAsyncThunk("jira-status/list", async (data) => {
    const res = await DataService.jira_status_list(data);
    return res.data;
}
);


export const jiraStatusCreate = createAsyncThunk("jira-status/create", async (data) => {
    const res = await DataService.jira_status_create(data);
    return res.data;
}
);

export const jiraStatusEdit = createAsyncThunk("jira-status/edit", async (id) => {
    const res = await DataService.jira_status_edit(id);
    return res.data;
}
);


export const jiraStatusUpdate = createAsyncThunk("jira-status/update", async (data) => {
    const res = await DataService.jira_status_update(data.ID, data.addvalues);
    return res.data;
});

export const jiraStatusArchive = createAsyncThunk("jira-status/archive", async (id) => {
    const res = await DataService.jira_status_archive(id);
    return res.data;
});


export const statusSyncByJira = createAsyncThunk("jira-status/sync", async () => {
    const res = await DataService.jira_status_sync();
    return res.data;
});

const jiraStatusySlice = createSlice({
    name: "jiraStatus",
    initialState: {
        jira_status_list: [],
        jira_status_create: [],
        jira_status_edit: [],
        jira_status_update: [],
        jira_status_archive:[],
        syncJiraStatus:[],
        status: STATUSES.IDLE,
        error: ''
    },
    reducers: {},
    extraReducers(builder) {
        builder
            ///jira status list
            .addCase(jiraStatusList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jiraStatusList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.jira_status_list = action.payload;
            })
            .addCase(jiraStatusList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///jira status create
            .addCase(jiraStatusCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jiraStatusCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_jira_status_create`;
                state.jira_status_create = action.payload;
            })
            .addCase(jiraStatusCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///jira status edit
            .addCase(jiraStatusEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jiraStatusEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.jira_status_edit = action.payload;
            })
            .addCase(jiraStatusEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///jira status Update
            .addCase(jiraStatusUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jiraStatusUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_jira_status_update`;
                state.jira_status_update = action.payload;
            })
            .addCase(jiraStatusUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

              ///jira status archive
              .addCase(jiraStatusArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jiraStatusArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_jira_status_archive`;
                state.jira_status_archive = action.payload;
            })
            .addCase(jiraStatusArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

               ///jira status sync
               .addCase(statusSyncByJira.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(statusSyncByJira.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_syncJiraStatus`;
                state.syncJiraStatus = action.payload;
            })
            .addCase(statusSyncByJira.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


    },
});

export default jiraStatusySlice.reducer;