import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";

var mappingArray=null
  export const getProjectResourceMapping = 
    async () => {
        const qParam = { "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": "True" };
        const res =await DataService.project_resource_mapping_list();
        const resp = res.data;
        mappingArray=resp.data.rows;
        return resp;
    }

export const projectByIdWithResources = createAsyncThunk(
    "get/projectByIdWithResources",
    async (id) => {
        const mappingRes=await getProjectResourceMapping()
        if(mappingRes.status){
            const res = await DataService.project_by_id(id);
            const resp = res.data;
            return resp;
        }
    }
);


const projectSlice = createSlice({
    name: "project",
    initialState: {

        project: {},
        project_status: STATUSES.IDLE,
        project_error: '',

        projects_res_mapping:[],
        projects_res_mapping_status: STATUSES.IDLE,
        projects_res_mapping_error: '',
        projects_res_mappings_count:0,
        
    },
    reducers: {},
    extraReducers(builder) {
        builder
             
        //  PROJECT By ID with Resources
        .addCase(projectByIdWithResources.pending, (state, action) => {
            getProjectResourceMapping();
            state.project_status = STATUSES.LOADING;
        })
        .addCase(projectByIdWithResources.fulfilled, (state, action) => {
            state.project_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
            state.project_error = !action.payload.data ? false : true ;
            const data=action.payload.data;
                state.project = !data.id? {} : 
                {
                    ...data,
                    is_jira_project_text: data.is_jira_project ? "Yes" : "No",
                    status_text: data.status ? "Active" : "Inactive",
                    status_value: data.status ? "True" : "False",
                    project_type_name: data.project_type.project_type_name,
                    is_jira_project: data.is_jira_project === true ? "Yes" : "No",
                    status: data.status === true ? "Active" : "Inactive",
                    is_private: data.is_private === true ? "Yes" : "No",
                    resource: null,
                    projectsResources: mappingArray.length && mappingArray.map((element) => { return element.project.id === data.id && element.resource }).filter(e => e !== false),
                    projectsResourcesNames: mappingArray.length && mappingArray.map((element) => { return element.project.id === data.id && element.resource.name }).filter(e => e !== false),
      
                  };
        })
        .addCase(projectByIdWithResources.rejected, (state, action) => {
            state.project_status = STATUSES.FAILURE;
            state.project_error = action.error
        })
    },
});


export default projectSlice.reducer;