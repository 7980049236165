import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

export const getUserType = createAsyncThunk(
    "get/employmentType",
    async (data) => {
        const res = await dataService.userTypeList(data);
        const resp = res.data;
        return resp;
    }
);

export const getUserTypeById = createAsyncThunk(
    "byId",
    async (data) => {
        const res = await dataService.userTypeById(data);
        const resp = res.data;
        return resp;
    }
);

const userTypeSlice = createSlice({
    name: "userType",
    initialState: {
        data: [],
        status: 'idle',
        error: ''
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get 
            .addCase(getUserType.pending, (state) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getUserType.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.data = action.payload;
                state.error = action.error
            })
            .addCase(getUserType.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })
    },
});


export default userTypeSlice.reducer;