import React from 'react'
import Navbar from '../navbar/Navbar'
import Sidebar from '../sidebar/Sidebar'

const BasicLayout = ({children}) => {
  return (
    // <div>
    // </div>
    <div className="new">
        <Sidebar />
        <div className="newContainer">
          <Navbar />
  
                { children  }

        </div>
      </div>
  )
}

export default BasicLayout