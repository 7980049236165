import React, { useState, useEffect } from "react";
import "./departments.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import dataService from "../../redux/services/data.service";
//import { getDepartment } from "../../redux/features/departmentSlice";
import { toast, ToastContainer } from "react-toastify";
import LoaderForm from "../../components/common-function/LoaderForm";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function AddDepartment({ inputs, title }) {

    const { id } = useParams()
    const navigate = useNavigate();
    const [showloader, setShowLoader] = useState(true)

    const [formData, setFormData] = useState({
        name: "",
        code: "",
        description: "",
        status: "",
        created_by: LocalStorageDataService.userDetail().id,
    })
    const [formErrors, setFormErrors] = useState('');

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setFormData({ ...formData, [name]: value });
    }

    const loadDataById = async () => {
        const response = await dataService.departmentById(id)
        response && response.data && setFormData(response.data.data)
    }

    useEffect(() => {
        id && loadDataById()
    }, [id])

    useEffect(() => {
        setTimeout(() => {
            setShowLoader(false)
        }, 800)
    }, [])

    const onSubmit = async (e) => {
        e.preventDefault();
        const request = formData

        if (!formData.name) {
            return setFormErrors({ 'nameErr': 'Department Name is Required' })
        } else if (!formData.code) {
            return setFormErrors({ 'codeErr': 'Code is Required' })
        } else if (!formData.description) {
            return setFormErrors({ 'descErr': 'Description is Required' })
        } else if (formData.status === "") {
            return setFormErrors({ 'statusErr': 'Status is Required' })
        }

        const response = !id ?
            await dataService.addDepartment(request)
            : await dataService.updateDepartment(id, request);

        if (response && response.data && response.data.data) {
            toast.success(response.data.message);
            setTimeout(() => {
                // dispatch(getDepartment(`{"skip":0, "limit":10}`))
                navigate('/all-depratment')
            }, 800)
        }

        if (response && response.data.status === 400) {
            const res = response.data.message
            toast.error(res)
            setFormErrors({
                "nameErr": res.name,
                "codeErr": res.code,
                "descErr": res.description,
                "statusErr": res.status,
            })
        }
    }

    return (
        <div className="new">
            <Sidebar />

            <div className="newContainer">
                <Navbar />

                <div className="top-headings">
                    <h3>{title}</h3>
                    <Tooltip title="Back">
                    <Link to={"/all-depratment"} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>
                  
                </div>

                {
                    showloader ? <LoaderForm /> :
                        <form onSubmit={onSubmit}>
                            <ToastContainer />
                            <div className="add-new-user-form">

                                {inputs.map((input) => (
                                    <div className="formInput" key={input.id}>
                                        <label>{input.label}</label>
                                        <input
                                            name={input.name}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            value={formData[input.name]}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <div className="errorMessage">{
                                            formErrors && input.name === "name" ?
                                                <p>{formData.name === "" ? formErrors.nameErr : ""}</p>
                                                : input.name === "code" ?
                                                    <p>{formData.code === "" ? formErrors.codeErr : ""}</p>
                                                    : input.name === "description" ?
                                                        <p>{formData.description === "" ? formErrors.descErr : ""}</p>
                                                        : ""
                                        }
                                        </div>
                                    </div>
                                ))}

                                <div className="formInput">
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="status"
                                            value={formData.status}
                                            onChange={(e) => handleChange(e)}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                        {formErrors && formErrors.statusErr && <p style={{ color: "#e33417", fontSize: 14 }}>{formData.status === "" ? formErrors.statusErr : ""}</p>}

                                    </FormControl>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-submit">Submit</button>

                        </form>
                }

            </div>

        </div>
    )
}
export default AddDepartment