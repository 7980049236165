import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./report.scss"
import { ToastContainer } from "react-toastify";
// import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
// import { useState } from "react";
import { STATUSES } from "../../redux/common-status/constant";
// import LoaderForm from "../../components/common-function/LoaderForm";
import { useNavigate } from "react-router-dom";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { resourceList } from "../../redux/features/resourceSlice";
// import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
// import { resourceReportColumns } from "../../components/datatable-coulms/tableCoulmns";



const WorkLogReport = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    // const [projectId, setProjectId] = useState(0)
    // const [loader, setLoader] = useState(false)
    const { status, error } = useSelector(state => state.resource)

    useEffect(() => {
        if (status === STATUSES.IDLE) {
            dispatch(resourceList(`{"no_limit":True}`))
        }

        if (status === STATUSES.FAILURE) {
            errorHandling(error, navigate)
        }

    }, [status, dispatch, error, navigate])


    // const actionColumn = [
    //     {
    //         field: "action",
    //         headerName: "Action",
    //         width: 200,
    //         renderCell: (params) => {
    //             return (
    //                 <div className="cellAction">
    //                     <div className="viewButton"><RemoveRedEyeIcon /></div>
    //                 </div>
    //             );
    //         },
    //     },
    // ];

    return (
        <div className="report">
            <Sidebar />
            <div className="reportContainer">
                <Navbar />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Work Log</h3>
                        <button className="btn-add-new ">
                            Back
                        </button>
                    </div>

                    {/* {loader === true && <LoaderForm />} */}
                    <ToastContainer />
                    {/* {status === STATUSES.SUCCESS || status === STATUSES.FAILURE ?
                        <DataGrid
                            className="datagrid"
                            rows={data && data.data && data.data.rows ? data.data.rows : []}
                            columns={resourceReportColumns.concat(actionColumn)}
                            pageSize={9}
                            rowsPerPageOptions={[9]}
                            checkboxSelection
                        />
                        :
                        <LoaderForm />
                    } */}
                </div>
            </div>
        </div>
    )
}

export default WorkLogReport