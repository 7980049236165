import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

export const getTeamAttendance = createAsyncThunk("get/team-attendance", async (data) => {
    const res = await dataService.team_attendance_list(data);
    return res.data;
}
);

export const allResources = createAsyncThunk("resources/list", async () => {
    const res = await dataService.all_resources_by_manager();
    return res.data;
});

export const attandanceDetailById = createAsyncThunk("attendance-details/by-id", async (id) => {
    if (id !== false) {
        const res = await dataService.attandance_detail_by_id(id);
        return res.data;
    } else {
        return {}
    }
});

const teamAttendanceSlice = createSlice({
    name: "teamAttendance",
    initialState: {
        teamAttendanceList: [],
        resourceList: [],
        attendaceDetailsById: [],
        status: STATUSES.IDLE,
        dataLoader: STATUSES.IDLE,
        error: ''
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get team attendance list
            .addCase(getTeamAttendance.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getTeamAttendance.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.dataLoader = STATUSES.SUCCESS;
                // state.teamAttendanceList = action.payload;

                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let res = result && result.data && result.data.rows
                let count = result && result.data && result.data.count
                res.map(e => {
                   return newArray.push({
                        id: e.id,
                        resource_id: e.resource_id,
                        resource: e.resource && e.resource.name,
                        emp_code: e.resource && e.resource.emp_code,
                        punchin_date: e.punchin_date,
                        punchin_time: e.punchin_time,
                        break_duration_minutes: e.break_duration_minutes,
                        work_duration_minutes: e.work_duration_minutes,
                        punch_out_date: e.punch_out_date,
                        punch_out_time: e.punch_out_time,
                        status: e.status
                    })
                })
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.teamAttendanceList = newArray2 && newArray2.data ? newArray2 : action.payload;
                state.error = action.error
            })
            .addCase(getTeamAttendance.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            // get all resource by reporting manager for filtering
            .addCase(allResources.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(allResources.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_resourceList`;
                state.resourceList = action.payload;
            })
            .addCase(allResources.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get attendace details by id 
            .addCase(attandanceDetailById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(attandanceDetailById.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_attendaceDetailsById`;
                state.attendaceDetailsById = action.payload;
            })
            .addCase(attandanceDetailById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
    },
});

export default teamAttendanceSlice.reducer;