import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";


// export const DEMO_ListOption = createAsyncThunk(
//     "get/DEMO_dropDown",
//     async (params) => {
//         const res = await DataService.DEMO_dropDownAPI_FUNCTION(params);
//         const resp = res.data;
//         return resp;
//     }
// );

export const resourceListOption = createAsyncThunk(
    "get/resources_dropDown",
    async (params) => {
        const res = await DataService.resources_dropDown();
        const resp = res.data;
        return resp;
    }
);

export const domainListOption = createAsyncThunk(
    "get/domain_dropdown",
    async (params) => {
        const res = await DataService.domain_list(params);
        const resp = res.data;
        return resp;
    }
);

export const projectsListOption = createAsyncThunk(
    "get/projects_dropdown",
    async (params) => {
        const res = await DataService.projects_list(params);
        const resp = res.data;
        return resp;
    }
);


const optionsSlice = createSlice({
    name: "domain",
    initialState: {

        // DEMOS: [],
        // DEMOS_count:0,
        // DEMOS_status: STATUSES.IDLE,
        // DEMOS_error: '',

        resources: [],
        resources_count:0,
        resources_status: STATUSES.IDLE,
        resources_error: '',

        domains: [],
        domains_count:0,
        domains_status: STATUSES.IDLE,
        domains_error: '',

        projects: [],
        projects_count:0,
        projects_status: STATUSES.IDLE,
        projects_error: '',
    },
    reducers: {},
    extraReducers(builder) {
        builder
                // // GET demo LISTOption
                // .addCase(DEMO_ListOption.pending, (state, action) => {
                //     state.DEMOS_status = STATUSES.LOADING;
                // })
                // .addCase(DEMO_ListOption.fulfilled, (state, action) => {
                //     state.DEMOS_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                //     state.DEMOS_error = !action.payload.data ? false : true ;
                //     state.DEMOS= !action.payload.data.rows ? [] : action.payload.data.rows.map((e)=>({...e, label:`${e.name} (${e.email})`, value:e.id }));
                //     state.DEMOS_count = action.payload.data.count;
                // })
                // .addCase(DEMO_ListOption.rejected, (state, action) => {
                //     state.DEMOS_status = STATUSES.FAILURE;
                //     state.DEMOS_error = action.error
                // })
                
                // GET resource LISTOption
                .addCase(resourceListOption.pending, (state, action) => {
                    state.resources_status = STATUSES.LOADING;
                })
                .addCase(resourceListOption.fulfilled, (state, action) => {
                    state.resources_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                    state.resources_error = !action.payload.data ? false : true ;
                    state.resources= !action.payload.data.rows ? [] : action.payload.data.rows.map((e)=>({...e, label:`${e.name} (${e.email})`, value:e.id }));
                    state.resources_count = action.payload.data.count;
                })
                .addCase(resourceListOption.rejected, (state, action) => {
                    state.resources_status = STATUSES.FAILURE;
                    state.resources_error = action.error
                })
    
        // GET DOMAIN LISTOption
            .addCase(domainListOption.pending, (state, action) => {
                state.domains_status = STATUSES.LOADING;
            })
            .addCase(domainListOption.fulfilled, (state, action) => {
                state.domains_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.domains_error = !action.payload.data ? false : true ;
                state.domains = !action.payload.data.rows ? [] : action.payload.data.rows;
                // state.domains= !action.payload.data.rows ? [] : action.payload.data.rows.map((e)=>({...e, label:`${e.name} (${e.email})`, value:e.id }));
                state.domains_count = action.payload.data.count;
            })
            .addCase(domainListOption.rejected, (state, action) => {
                state.domains_status = STATUSES.FAILURE;
                state.domains_error = action.error
            })

        //  PROJECT LISTOption
        .addCase(projectsListOption.pending, (state, action) => {
            state.projects_status = STATUSES.LOADING;
        })
        .addCase(projectsListOption.fulfilled, (state, action) => {
            state.projects_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
            state.projects_error = !action.payload.data ? false : true ;
            state.projects = !action.payload.data.rows ? [] : action.payload.data.rows;
            // state.projects= !action.payload.data.rows ? [] : action.payload.data.rows.map((e)=>({...e, label:`${e.name} (${e.email})`, value:e.id }));
            state.projects_count = action.payload.data.count;
        })
        .addCase(projectsListOption.rejected, (state, action) => {
            state.projects_status = STATUSES.FAILURE;
            state.projects_error = action.error
        })
    },
});


export default optionsSlice.reducer;