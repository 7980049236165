import React, { useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
// import JiraContentParser from './JiraContentParser';
// import { bodyText } from './JoditEditor';
import { ContentParser } from './ContentParser';
// import { URTM_61 } from './DemoData';


    
const RichTextEditor = ({data, onBlur, onChange}) => {
        const editor = useRef(null);
        const bodyContent=ContentParser(data)
        const [content, setContent] = useState(bodyContent);
    const handleOnChange=(newContent) => { 
        console.log(newContent)
        onChange && onChange(newContent);
     }

    const handleOnBlur=(newContent) => { 
        console.log(newContent)
        onBlur && onBlur(newContent);
     }

    return (
		<JoditEditor
			ref={editor}
			// value={"<p><strong>A gentle brown fox quickly </strong>jumped highly over a lazy dog.</p>"}
			value={content}
			tabIndex={1} // tabIndex of textarea
			onBlur={(newContent) => handleOnBlur(newContent)} // preferred to use only this option to update the content for performance reasons
			onChange={(newContent) => handleOnChange(newContent)}
		/>
	);
}

export default RichTextEditor;