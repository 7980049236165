
import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'

// import FullCalendar from '@fullcalendar/react' // must go before plugins
// import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
// import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
// import timeGridPlugin from '@fullcalendar/timegrid'

import { useDispatch, useSelector } from "react-redux"
import { holidayCalenderList, holidayList,
  //  saveHoliday 
  } from "../../redux/features/holidaySlice"
import RegularizeModal from '../../components/common-function/RegularizeModal'
import dataService from '../../redux/services/data.service'
import Confirmation from '../../components/popup/Confirmation'
import { useNavigate } from 'react-router-dom'
import DynamicTable from '../../components/dynamic-table/DynamicTable'
import { STATUS, STATUSES } from '../../redux/common-status/constant'
import Button from '../../components/button/Button'
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const { REACT_APP_ENV } = process.env;
const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
const userId=rtm_user?.user?.data?.id || null;
const userRole=rtm_user?.user?.data?.tms_role.role_key || null;

const HolidayList = () => {
  
  const navigate=useNavigate()

const [pageSize, setPageSize] = useState(10);
const [currentPage, setCurrentPage] = useState(0);
const [skip, setSkip] = useState(0);
const [params, setParams] = useState({})
const [fieldName, setFieldName] = useState("")
const [defaultValue, setDefaultValue] = useState({})
 
// Modal Related data
const [open, setOpen] = useState(false)
const [formLoading, setFormLoading] = useState(false)
const [updating, setUpdating] = useState(false);
const [editable, setEditable] = useState(false);

// Confirmation Modal
const [confirmOpen, setConfirmOpen] = useState(false)
const [deletingId, setDeletingId] = useState("")
const whereClause={...params, "no_limit":"True"}

const [holidaysData, setHolidaysData] = useState({})

const dispatch=useDispatch();
const { holidays, holidaysCalender,
   status, error, apiResponse, row_count 
  } = useSelector(state => state.holiday)

    const modalDetail = {
        title: `Holiday : ${holidaysData.holiday_date}`,
        editModalTitle:`Edit Holiday Date ${holidaysData.holiday_date}`,
        addModalTitle:`Add Holiday Date :  ${holidaysData.holiday_date}`,
        formInputs: [
          {
            id: 1, name: "holiday_date", label: "Holiday Date", placeholder: "", type: "date",
            //  defaultValue:"25/01/2022", 
             format:"yyyy-MM-DD" ,
            required: true, disabled: false
          },
          {
            id: 2, name: "holiday_list_id", label: "Holiday", placeholder: "", type: "select",
            options: holidays.map(e=> ({id:e.id, label:e.name, value:e.id})) ,
            required: true, disabled: false
          },    
          {
            id: 2, name: "status", label: "Status", placeholder: "", type: "select",
            options: [
              { id: 1, label: "Active", value: true },
              { id: 2, label: "Inactive", value: false },
            ],
            // options: holidays.map(e=> ({id:e.id, label:e.name, value:e.id})) ,
            required: true, disabled: false
          },    
          { id: 2, name: "description", label: "Descriptions", placeholder: "Enter Descriptions", type: "textarea", required: false, disabled: false },
        ],
        additionalActions:[
            { id: 1, name: "edit", label: "Edit", className: "btn-submit", disabled: false },
            { id: 2, name: "delete", label: "Delete", className: "btn-cancel", disabled: false },
          ]
    }
    const holidaysColumns = [
        { field: "id", headerName: "ID", width: 70 },
        {
          field: "holiday_list",
          headerName: "Name",
          width: 275,
          renderCell: (params) => {
                return (
                  <div className="">
                    {params.row.holiday_list.name}
                  </div>)
          }
        },
        {
          field: "holiday_date",
          headerName: "Holiday Date",
          type: "date",
          width: 130,
        },
        {
            field: "description",
            headerName: "Description",
            width: 400,
            renderCell: (params) => {
                  return (
                    <div className="">
                      {params.row.holiday_list.description}
                    </div>)
            }
          },
    
        // {
        //   field: "approved_by",
        //   headerName: "Approved by",
        //   width: 200,
        //   type: 'singleSelect',
        //   valueOptions: resourcesOptions,
        //   renderCell: (params) => {
        //     return (
        //       <div className={`cellWithStatus `}>
        //         {/* <select defaultValue={params.row.approved_by}>
        //           </select> */}
        //         {
        //           resourcesOptions.map((resource, index) => {
        //             return (
        //               params.row.approved_by === resource.value && <p key={index}>{resource.label} </p>
        //             )
        //           })
        //         }
        //       </div>
        //     );
        //   },
        // },
        {
          field: "status",
          headerName: "Status",
          width: 120,
    
          type: 'singleSelect',
          valueOptions: [
            { label: "Active", value: true },
            { label: "Inactive", value: false },
          ],
          renderCell: (params) => {
            return (
              <div className={`cellWithStatus ${params.row.status}`} >
                <p>{params.row.status === true ? 'Active' : 'Inactive'}</p>
              </div>
                    );
          },
        },
      ];

    const handleDateClick = (arg=null) => { 
        if(userRole==='admin' || userRole==='super-admin'){
          setUpdating(false)
          setEditable(true);
          setHolidaysData({holiday_date:arg ? arg.dateStr : ""})
          onOpenModal()
        }
    }

    const viewHolidayDetail=(holidayData) => { 
        setHolidaysData(holidayData);
        setUpdating(false)
        setEditable(false);
        onOpenModal()
     }

    const renderEventContent = (eventInfo, cell=null) => {
      const holidayDate={...eventInfo.event._def.extendedProps, id:eventInfo.event._def.publicId}
        return (
            <div style={{ }}>
              {
                (userRole==='admin' || userRole==='super-admin') ?
                <h3 onClick={()=>viewHolidayDetail(holidayDate)} 
                  className={holidayDate.status ?"btn-full-width " : "btn-full-width-inactive" } 
                >{holidayDate.holiday_list.name}</h3>
                :<h3> {holidayDate.holiday_list.name} </h3>
              }
            </div>
        )
    }



  const onSubmit=async (data) => { 
    // dispatch(saveHoliday({...holidaysData, ...data, "created_by": 1}))
    const finalData= updating ? 
    { ...holidaysData, ...data,
       holiday_date:data.holiday_date ? data.holiday_date : holidaysData.holiday_date, 
       holiday_list:data.holiday_list_id ?  data.holiday_list_id :holidaysData.holiday_list_id , updated_by:userId} 
    : {...data, holiday_list:data.holiday_list_id , holiday_date:data.holiday_date ? data.holiday_date : holidaysData.holiday_date, 
      created_by:userId}
    const res =await dataService.save_holiday_date(finalData);
    if(res.data && res.status ===200){
      toast.success(res.data.message)
      dispatch(holidayCalenderList(whereClause))
      onCloseModal();
    }
    else{
      toast.error(res.data.message)
    }
  }

  const handleAdditionalAction=(data, actionName) => { 
    if(actionName==="edit"){
      setUpdating(true)
      setEditable(true);
    }
    else
    if(actionName==="delete"){
      setConfirmOpen(true);
      setDeletingId(data.id)
    }
  }

// Delete Holiday Date
const deleteHolidayDate=async (id) => { 
  const res= await dataService.delete_holiday_date(deletingId || id)
  if(res && res.data && res.data.status ===200){
    toast.success(res.data.message)
    dispatch(holidayList(whereClause))
    dispatch(holidayCalenderList(whereClause))
  } else{
    toast.error(res.data.message)
  }
  onCloseModal();
 }

//  Open Modal
 const onOpenModal=() => { 
  setOpen(true)
}

//  Close Modal
 const onCloseModal=() => { 
  setOpen(false)
  setUpdating(false);
  setEditable(false);
  setHolidaysData({});
  setConfirmOpen(false);
  setDeletingId("")
}

    useEffect(() => {
        dispatch(holidayCalenderList())
        dispatch(holidayList())
    }, [])
    

    return (
        <div className="new">
          <Sidebar />
          <div className="newContainer">
            <Navbar />
    
            <div className="top-headings">
              <h3 >Holiday List </h3>
              <div>
              { 
                (userRole==='admin' || userRole==='super-admin') &&
                <Button  title={" "} 
                  preAddIcon 
                  onClick={() => handleDateClick()} className="btn-add-new "
                  tooltip="Add Holiday"
                />
              }
             
              <Tooltip title="Back">
                <button onClick={() => navigate(-1)} className="btn-add-new "><ArrowBackIcon /></button>
                    </Tooltip>
              </div>
            </div>
    
            <ToastContainer />

                    <>
                        {
                            <DynamicTable
                            moduleName="holidaysList"
                            tableColumns={holidaysColumns}
                            tableRows={holidaysCalender || []}
                            currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
                            setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
                            setFieldName={setFieldName} setDefaultValue={setDefaultValue}
                            paginationMode={"server"}
                            rowCount={row_count}
                            loading={status===STATUSES.LOADING}
                            columnVisibilityModel={{
                                id: false,
                            }}
                            onViewDetail={viewHolidayDetail}
                            // onEdit={handleEdit}
                            deleteRow={deleteHolidayDate} 
                            />
                        }
                    </>

                    {
                        <RegularizeModal
                        modalDetail={modalDetail}
                        open={open}
                        onOpen={onOpenModal}
                        onClose={onCloseModal}
                        // onCancel={onCancel}
                        onSubmit={onSubmit}
                        hideBackdrop
                            fullWidth
                            maxWidth
                            updating={updating}
                            editData={holidaysData}
                            editable={editable}
                            formLoading={formLoading}
                            setFormLoading={setFormLoading}
                            additionalActionOnView={true}
                            additionalActions={modalDetail.additionalActions}
                            onAdditionalAction={handleAdditionalAction}
                            />
                        }
                    {
                      <Confirmation
                        open={confirmOpen}
                        handleClose={() => { setConfirmOpen(!confirmOpen) }}
                        handleCancel={() => { setConfirmOpen(!confirmOpen) }}
                        onConfirm={deleteHolidayDate}
                        dialogTitle={"Delete!"}
                        dialogQuestion={"Sure to Delete"}
                      />
                    }
          </div>
        </div>
      )
}

export default HolidayList