import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./task.scss"


export const TaskView = () => {
  return (
    <div className="task">
      <Sidebar />
      <div className="taskContainer">
        <Navbar />
      </div>

    </div>
  )
}

