import './phoneCallDetails.scss';
import { ArrowBack } from '@mui/icons-material';
import { TablePagination, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import LoaderForm from '../../components/common-function/LoaderForm';
import { myPhoneCallDetailsColumn } from '../../components/datatable-coulms/tableCoulmns'
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import TablePaginationActions from '../../components/TablePaginationActions';
import { STATUSES } from '../../redux/common-status/constant';
import { getCallLogDetails } from '../../redux/features/callingSlice';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { useLocation, useNavigate } from 'react-router-dom';

const user = LocalStorageDataService.userDetail();
const userId = user && user.id
export const MyCallDetails = () => {

    const localState = useLocation().state;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const date = moment().format('YYYY-MM-DD')
    // const roleWiseFilter = (localState && localState === 'progress-bar') ? `{"filter":{"resource":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation":"eq"}},"skip":0,"limit":10,"order_by":{"column":"-id"}}` :
    //     `{"filter":{"resource":{"value":${userId},"operation": "eq"}},"skip":0,"limit":10,"order_by":{"column":"-id"}}`

    const { callDetails, status } = useSelector(state => state.callingSlice)
    const { data: list } = callDetails

    let callTypeWiseFilter;
    if (localState) {
        if (localState.key === "in-bound") {
            callTypeWiseFilter = `{"filter":{"resource":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation":"eq"},"direction":{"value":0,"operation":"eq"}},"skip":0,"limit":10,"order_by":{"column":"-id"}}`
        } else if (localState.key === "out-bound") {
            callTypeWiseFilter = `{"filter":{"resource":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation":"eq"},"direction":{"value":1,"operation":"eq"}},"skip":0,"limit":10,"order_by":{"column":"-id"}}`
        } else {
            callTypeWiseFilter = `{"filter":{"resource":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation":"eq"}},"skip":0,"limit":10,"order_by":{"column":"-id"}}`
        }
    } else {
        callTypeWiseFilter = `{"filter":{"resource":{"value":${userId},"operation": "eq"}},"skip":0,"limit":10,"order_by":{"column":"-id"}}`
    }

    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(`{"filter":{"resource":{"value":${userId},"operation": "eq"}},"skip":0,"limit":10,"order_by":{"column":"-id"}}`)
    const [pageSize, setPageSize] = useState(10);
    const [limit, setLimit] = useState(10);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    useEffect(() => {
        setFilterQuery(callTypeWiseFilter)
        dispatch(getCallLogDetails(callTypeWiseFilter))
    }, [localState?.key])

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        dispatch(getCallLogDetails(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        dispatch(getCallLogDetails(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(list && list.rows && list.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                                filterModel.items[0].operatorValue


            let filterData = `{"filter":{"resource_id":{"value":${userId},"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
            if (localState) {
                if (localState.key === "in-bound") {
                    filterData = `{"filter":{"resource_id":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation": "eq"},"direction":{"value":0,"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
                } else if (localState.key === "out-bound") {
                    filterData = `{"filter":{"resource_id":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation": "eq"},"direction":{"value":1,"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
                } else {
                    filterData = `{"filter":{"resource_id":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
                }
            } else {
                filterData = `{"filter":{"resource_id":{"value":${userId},"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
            }
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getCallLogDetails(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            setPageSize(10)
            setFilterQuery(callTypeWiseFilter)
            dispatch(getCallLogDetails(callTypeWiseFilter))
        }
    }
    const actions = [
        {
            field: 'project',
            headerName: 'Project Name',
            width: 200,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus" onClick={() => { params.row.project ? navigate("/view-project", { state: { id: params.row.project.id } }) : navigate('') }}>
                        <Tooltip title="View Project">
                            <p>
                                {params.row.project && params.row.project.project_name ? params.row.project.project_name : "-"}
                            </p>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            field: 'story',
            headerName: 'Story Name',
            filterable: false,
            width: 290,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus" onClick={() => params.row.story ? navigate(`/story-task/${params.row.story.id}`) : navigate('')}>
                        <Tooltip title="View Story">
                            <p>
                                {params.row.story && params.row.story.ticket_key ? params.row.story.ticket_key : "-"}
                            </p>
                        </Tooltip>
                    </div>
                );
            }
        },
    ]

    return (
        <div className="phoneCallListing">
            <Sidebar />
            <div className="phoneCallListingContainer">
                <Navbar />
                <div className="datatable">
                    <div className="datatableTitle">
                        <h3>My Phone Call Details</h3>
                        <Tooltip title="Back">
                            <button className='btn-add-new' onClick={() => navigate(-1)}><ArrowBack /></button>
                        </Tooltip>
                    </div>

                    <ToastContainer />
                    {(status === STATUSES.LOADING) && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={list && list.rows ? list.rows : []}
                        columns={myPhoneCallDetailsColumn.concat(actions)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        components={{
                            Pagination: CustomPagination,
                        }}
                    />
                </div>

            </div>
        </div>
    )
}
