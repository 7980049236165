import React, { useMemo } from "react";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./resource.scss";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { resourceColumns } from "../../components/datatable-coulms/tableCoulmns";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CONSTANTS from "../../components/constant/constantComponents";
import { useDispatch, useSelector } from "react-redux";
import { resourceList, resourceDeleteById, resourceSyncByJira, allRoleList, allDepartmentList, allEmpTypeList, allResourcesList } from "../../redux/features/resourceSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import { Dialog, DialogContent, TablePagination } from "@mui/material";
import LoaderForm from "../../components/common-function/LoaderForm";
import Sync from '@mui/icons-material/Sync';
import { toast, ToastContainer } from "react-toastify";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import Filter from "../../components/Filter";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
const user = LocalStorageDataService.userDetail();
const tmsRole = user && user.tms_role
const roleWiseFilter = tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin') ? `{"skip":0, "limit":10,"order_by":{"column":"-id"}}` : `{"filter":{"id":{"value":${user.id},"operation": "eq"}},"skip":0,"limit":10,"order_by":{"column":"-id"}}`

const ResourcesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { data, status, error, deleteResource, syncResource, roleList, departmentList, empTypeList, activeResourceFilter } = useSelector(state => state.resource)
  const { data: list } = data;
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null)
  const [syncLoading, setSyncLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0);
  const [filterQuery, setFilterQuery] = useState(roleWiseFilter)
  const [filterRoleQuery, setFilterRoleQuery] = useState([])
  const [filterDepartmentQuery, setFilterDepartmentQuery] = useState([])
  const [filterEmpTypeQuery, setFilterEmpTypeuery] = useState([])
  const [filterActiveResourcesQuery, setFilterActiveResourcesQuery] = useState([])
  const [fieldName, setFieldName] = useState("")
  const [options, setOptions] = useState({})
  const [defaultValue, setDefaultValue] = useState({})
  const [pageSize, setPageSize] = useState(10);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(resourceList(filterQuery))
    dispatch(allRoleList())
    dispatch(allDepartmentList())
    dispatch(allEmpTypeList())
    dispatch(allResourcesList())
    if (status === STATUSES.FAILURE) {
      errorHandling(error, navigate)
    }

  }, [])

  const handleDelete = (id) => {
    setDeleteId(id)
    setOpen(true)
  }

  if (syncLoading === true && status === STATUSES.SUCCESS && deleteResource && deleteResource.message) {
    setSyncLoading(false)
    if (deleteResource.message === "Success") {
      toast(deleteResource.message)
    } else {
      toast(syncResource.message)
    }
    setTimeout(() => {
      dispatch(resourceList(roleWiseFilter))
    }, 500);
  }

  if (syncLoading === true && status === `${STATUSES.SUCCESS}_syncResource` && syncResource && syncResource.message) {
    setSyncLoading(false)
    if (syncResource.message === "Success") {
      toast(syncResource.message)
    } else {
      toast(syncResource.message)
    }
    setTimeout(() => {
      dispatch(resourceList(roleWiseFilter))
    }, 500);
  }

  if (status === `${STATUSES.SUCCESS}_roleList` && filterRoleQuery && filterRoleQuery.length === 0 && roleList && roleList.data && roleList.data.rows) {
    let array = []
    roleList.data && roleList.data.rows.map(e => { return array.push({ value: e.id, label: e.role_name, id: e.id }) })
    array.length > 0 && setFilterRoleQuery(array)
    array.length > 0 && setOptions({ ...options, tms_role: array })
  }


  if (status === `${STATUSES.SUCCESS}_departmentList` && filterDepartmentQuery && filterDepartmentQuery.length === 0 && departmentList && departmentList.data && departmentList.data.rows) {
    let array = []
    departmentList.data && departmentList.data.rows.map(e => { return array.push({ label: e.name, value: e.id, id: e.id }) })
    array.length > 0 && setFilterDepartmentQuery(array)
    array.length > 0 && setOptions({ ...options, department: array })
  }

  if (status === `${STATUSES.SUCCESS}_empTypeList` && filterEmpTypeQuery && filterEmpTypeQuery.length === 0 && empTypeList && empTypeList.data && empTypeList.data.rows) {
    let array = []
    empTypeList.data && empTypeList.data.rows.map(e => { return array.push({ value: e.id, label: e.employment_type, id: e.id }) })
    array.length > 0 && setFilterEmpTypeuery(array)
    array.length > 0 && setOptions({ ...options, employment_type: array })
  }

  if (status === `${STATUSES.SUCCESS}_resourceList` && filterActiveResourcesQuery && filterActiveResourcesQuery.length === 0 && activeResourceFilter && activeResourceFilter.data && activeResourceFilter.data.rows) {
    let array = []
    activeResourceFilter.data && activeResourceFilter.data.rows.map(e => { return array.push({ value: e.id, label: e.display_name + "(" + e.email + ")", id: e.id }) })
    array.length > 0 && setFilterActiveResourcesQuery(array)
    array.length > 0 && setOptions({ ...options, reporting_manager: array })
  }

  const handleClickOpen = (id) => {
    const request = { status: 3 }
    setSyncLoading(true)
    if (open === true) {
      dispatch(resourceDeleteById(id, request))
      setOpen(false)
    }
  }

  const syncResources = () => {
    setSyncLoading(true)
    dispatch(resourceSyncByJira())
  }

  const actionColumn = [
    {
      field: "department",
      headerName: "Department",
      // type: 'singleSelect',
      // valueOptions: filterDepartmentQuery,
      width: 150,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.department && params.row.department.name ? params.row.department.name : params.row.department_name}
          </div>
        );
      },
    },
    {
      field: "tms_role",
      headerName: "Role",
      width: 150,
      // type: 'singleSelect',
      // valueOptions: filterRoleQuery,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.tms_role && params.row.tms_role.role_name ? params.row.tms_role.role_name : params.row.tms_role_name}
          </div>
        );
      },
    },
    {
      field: "employment_type",
      headerName: "Employment Type",
      width: 250,
      // type: 'singleSelect',
      // valueOptions: filterEmpTypeQuery,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.employment_type && params.row.employment_type.employment_type ? params.row.employment_type.employment_type : params.row.employment_type_name}
          </div>
        );
      },
    },
    {
      field: "reporting_manager",
      headerName: "Manager",
      width: 250,
      type: 'singleSelect',
      valueOptions: filterActiveResourcesQuery,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.reporting_manager_name}
          </div>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status === true ? 'Active' : 'Inactive'}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent moduleName="resource" rowid={params.row.id} addMoreData={false} editLinkUrl={`edit/${params.row.id}`} viewLinkUrl={`view/${params.row.id}`} isDelete={true} deleteIconName="ArchiveIcon" editButton={false} viewButton={false} deleteButton={handleDelete} />
          </div>
        );
      },
    },
  ];

  const onFilterChange = (filterModel) => {
    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
      setFieldName(filterModel.items[0].columnField)
    }
    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
      let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
          filterModel.items[0].operatorValue === "is" ? 'eq' :
            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
      const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
      setFilterQuery(filterData)
      setCurrentPage(0)
      dispatch(resourceList(filterData))
    }
    if (filterModel.items && filterModel.items.length === 0) {
      setCurrentPage(0)
      setPageSize(10)
      setFilterQuery(roleWiseFilter)
      dispatch(resourceList(roleWiseFilter))
      setDefaultValue("")
    }
  }

  // Custom filter
  function CustomFilterInputValue(props) {
    const { item, applyValue } = props;
    const handleFilterChange = (evt) => {
      if (evt) {
        setDefaultValue(evt)
        const { value } = evt;
        applyValue({ ...item, value: value });
      }
    };
    return (
      <Filter options={options[fieldName]} onChange={(e) => handleFilterChange(e)} defaultValue={defaultValue} />
    );
  }

  const columns = useMemo(() =>
    actionColumn.map((col) => {
      if (col.field === "status") {
        return col;
      }
      return {
        ...col,
        filterOperators: getGridSingleSelectOperators()
          .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
          .map((operator) => ({
            ...operator,
            InputComponent: operator.InputComponent
              ? CustomFilterInputValue
              : undefined
          }))
      };
    }),
    [actionColumn]
  );

  //custom pagination
  const handlePagination = (data) => {
    setCurrentPage(data)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = data * pageSize
    newFilterData['limit'] = limit
    dispatch(resourceList(JSON.stringify(newFilterData)))
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.target.value)
    setSyncLoading(false)
    setCurrentPage(0)
    setLimit(newPageSize.target.value)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0
    newFilterData['limit'] = newPageSize.target.value
    dispatch(resourceList(JSON.stringify(newFilterData)))
  }

  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={(list && list.rows && list.count) || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) =>
          <TablePaginationActions {...subProps}
            handlePagination={handlePagination}
          />}
      />
    );
  }

  return (
    <div className="resource">
      <Sidebar />
      <div className="resourceContainer">
        <Navbar />
        <ToastContainer />
        <div className="datatable">
          <div className="top-headings">
            <h3>{CONSTANTS.RESOURCE.LIST}</h3>
            <div>
            <Tooltip title="Jira Status ">
              <button className="btn-add-new" onClick={() => syncResources()} >
                <Sync />
              </button>
              </Tooltip>
              <Tooltip title="Add new Resource">
              <Link to="add" className="btn-add-new">
                <AddIcon />
              </Link>
              </Tooltip>

              {/* <Tooltip title="Add New Resourse">
            <Link to="add" className="btn-add-new ">
              <AddIcon />
            </Link>
            </Tooltip> */}
              
            </div>
          </div>

          {(syncLoading === true || status === STATUSES.LOADING) && <LoaderForm />}
          <DataGrid
            columnVisibilityModel={{
              tms_role_name: false
            }}
            className="datagrid"
            rows={list && list.rows ? list.rows : []}
            columns={resourceColumns.concat(columns)}
            pageSize={pageSize}
            // paginationMode="server"
            // page={currentPage}
            // rowsPerPageOptions={[10, 25, 50, 100]}
            // rowCount={(list && list.rows && list.count) || 10}
            // onPageChange={(newPage) => handlePageChange(newPage)}
            // onPageSizeChange={(pageSize) => handlePageSizeChange(pageSize)}
            style={{ fontSize: 16 }}
            onFilterModelChange={onFilterChange}
            components={{
              Pagination: CustomPagination,
            }}
          />
          {error && <p style={{ color: "#96332c", fontSize: 12 }}> {error.message}</p>}
        </div>


        {/* for delete popup */}
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">

          <DialogContent>
            <div className="popup-confirming">
              <h3>Delete</h3>
              <p>Are You Sure To Remove this Resource</p>
              <button className="yes" onClick={() => handleClickOpen(deleteId)}>Yes</button>
              <button className="no" onClick={() => setOpen(false)} autoFocus>
                Cancel
              </button>
            </div>
          </DialogContent>
        </Dialog>

      </div>

    </div>
  )
}

export default ResourcesList