import "./loaderpickers.scss";
import React from 'react'
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";


const DateTime = () => {
    
    
   
    return (
        <div className="new">
            <Sidebar />

            <div className="newContainer">
                <Navbar />

                <div className="top-headings">
                    <h3>Date and time pickers</h3>

                </div>
                <div className="loader-pickers-col">
                    
               
                </div>


            </div>
        </div>
    )
}

export default DateTime