import axios from "axios";
// const devEnv = process.env.NODE_ENV !== "production";

// const { REACT_APP_DEV_API, REACT_APP_PROD_API } = process.env;

// const API = axios.create({
//   baseURL: `${devEnv ? REACT_APP_DEV_API : REACT_APP_PROD_API}`,
// });

console.log('React App API BaseUrl:', process.env);
const {  REACT_APP_API_BASEURL,REACT_APP_ENV } = process.env;

// console.log(REACT_APP_ENV,"branch")

const rtms = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
export default axios.create({
    baseURL: REACT_APP_API_BASEURL,
    headers: {
      "Content-type": "application/json",
      "Authorization": rtms ? rtms.token :'',
      // "Cookie":`token=${localStorage.getItem('token')}`
     }
  });
