import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./resource.scss";
import { resourceViewById } from "../../redux/features/resourceSlice";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ResourceView = () => {
    const dispatch = useDispatch()
    // const location = useLocation()
    const { resourceid } = useParams()

    const { view_data , status, error } = useSelector(state => state.resource)

    // useEffect(() => {
    //     if (location && location.state && location.state.resourceid) {
    //         const resourceid = location.state.resourceid
    //         dispatch(resourceViewById(resourceid))
    //     }
    // }, [dispatch, location])
   
    useEffect(() => {
        if (resourceid) {
            dispatch(resourceViewById(resourceid))
        }
    }, [dispatch])
    return (
        <div className="resource">
            <Sidebar />
            <div className="resourceContainer">
                <Navbar />
                <div className="top-headings">
                    <h3>Resources View</h3>
                   
                    <Tooltip title="Back">
                        <Link to={-1} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>
                </div>
                    {status === STATUSES.SUCCESS ?
                    <div className="view-details-row">
                        <div className="detailItem">
                            <span className="itemKey">Name :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.display_name}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Date of Birth :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.date_of_birth}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Email :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.email}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Contact Number :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.contact_no}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Gender :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.gender}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Address :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.address}</span>
                        </div>

                        <h5 className="title">Department Info</h5>
                        <div className="detailItem"></div>
                        <div className="detailItem"></div>
                       
                        <div className="detailItem">
                            <span className="itemKey">Department Name:</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.department && view_data.data.department.name}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Date of Joining  :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.doj}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Employee Code :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.emp_code}</span>
                        </div>


                        <div className="detailItem">
                            <span className="itemKey">Jira Account  :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.jira_account}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Manager  :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.reporting_manager_name}</span>
                        </div>
                    </div>
                    :
                    status === STATUSES.FAILURE  ?
                    <div>{error.message}</div>
                    :
                    <LoaderForm/>
                }
            </div>
        </div >
    )

}

export default ResourceView