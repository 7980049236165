import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from './features/authSlice';
import LogoutReducer from './features/authLogoutSlice';
import UiReducer from './features/uislice';
import countryReducer from "./features/countrySlice";
import resourceReducer from "./features/resourceSlice";
import departmentReducer from "./features/departmentSlice";
import roleReducer from "./features/roleSlice";
import userTypeReducer from "./features/userTypeSlice";
import cedentialHeadReducer from "./features/cedentialHeadSlice";
import calendarReducer from "./features/calendarSlice";
import taskReducer from "./features/taskSlice";
import jiraStatusReducer from "./features/jiraStatusSlice";
import sprintReducer from "./features/sprintSlice";
import dashboardCardSlice from "./features/dashboardSlice";
import compoffSlice from "./features/compoffSlice";
import holidaySlice from "./features/holidaySlice";
import teamAttendanceSlice from "./features/teamAttendanceSlice";
import masterReducer from "./features/masterSlice";
import thirdPartyReducer from "./features/thirdPartySlice";
import domainSlice from "./features/domainSlice";
import sidebarReducer from "./features/sidebarSlice";
import workflowSlice from "./features/workflowSlice";
import jobPostReducer from "./features/jobPostSlice";
import callingSlice from "./features/callingSlice";
import emailSlice from "./features/emailSlice";
import optionsSlice from "./features/optionsSlice";
import zoomSlice from "./features/zoomSlice";
import projectSlice from "./features/projectSlice";

const store = configureStore({
    reducer: {
        authUser: AuthReducer,
        logout: LogoutReducer,
        navbartoggle: UiReducer,
        changeCurrentMenu: UiReducer,
        countryList: countryReducer,
        resource: resourceReducer,
        department: departmentReducer,
        role: roleReducer,
        userType: userTypeReducer,
        compOff:compoffSlice,
        holiday:holidaySlice,
        domain:domainSlice,
        workflow:workflowSlice,
        task: taskReducer,
        credentialHead: cedentialHeadReducer,
        calender: calendarReducer,
        jira_status: jiraStatusReducer,
        sprint: sprintReducer,
        dashboardCard: dashboardCardSlice,
        teamAttendance: teamAttendanceSlice,
        master: masterReducer,
        thirdParty:thirdPartyReducer,
        sidebar:sidebarReducer,
        jobpost:jobPostReducer,
        callingSlice:callingSlice,
        emailSlice:emailSlice,
        dropdownOptions:optionsSlice,
        zoomSlice:zoomSlice,
        projects:projectSlice,
    },
})
export default store;