import React from 'react'
import "./notification.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const Notification = () => {

    const rows = [
        {
            id: 1,
            notification: "Post like by someone bored last friday",
            date: "26/09/2022 09:01 AM",
            status: "Approved",
        },

        {
            id: 2,
            notification: "Post like by someone bored last friday",
            date: "26/09/2022 09:01 AM",
            status: "Pending",
        },

        {
            id: 3,
            notification: "Post like by someone bored last friday",
            date: "26/09/2022 09:01 AM",
            status: "Approved",
        },

        {
            id: 4,
            notification: "Post like by someone bored last friday",
            date: "26/09/2022 09:01 AM",
            status: "Pending",
        },

        {
            id: 5,
            notification: "Post like by someone bored last friday",
            date: "26/09/2022 09:01 AM",
            status: "Approved",
        },

    ];
    return (
        <div className="notification">
            <Sidebar />
            <div className="newContainer">
                <Navbar />


                <div className="listContainer">
                    <div className="listTitle">Latest Notifications</div>
                    <TableContainer component={Paper} className="table">
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="tableCell">Sr. no</TableCell>
                                    <TableCell className="tableCell">Notification</TableCell>
                                    <TableCell className="tableCell">Date</TableCell>
                                    <TableCell className="tableCell">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.id} >
                                        <TableCell className="tableCell" align="right">{row.id}</TableCell>

                                        <TableCell className="tableCell">{row.notification}</TableCell>
                                        <TableCell className="tableCell">{row.date}</TableCell>
                                        <TableCell className="tableCell">
                                            <span className={`status ${row.status}`}>{row.status}</span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>

        </div>
    )
}

export default Notification
