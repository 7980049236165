import { Autocomplete, TextField } from '@mui/material';

export default function Filter({ options, onChange, defaultValue }) {

    // console.log(defaultValue, "defaultValue")
    return (
        options &&
        <Autocomplete
            disablePortal
            id="auto-complete"
            options={options}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            disableClearable={true}
            defaultValue={defaultValue.value ? defaultValue : ""}
            renderInput={(params) => <TextField variant="standard" {...params} label="Value" />}
            key={options => options.id}
        />
    )
}