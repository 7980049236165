import { DialogContent, Dialog, DialogTitle, Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { InputButton, InputFileFields, InputSelectField } from '../../components/common-function/InputFields';
import "./task.scss";
import { DataGrid } from "@mui/x-data-grid";


export const TaskModal = ({ openModal, setOpenModal, list, title, formtype, formDataJson, formButton, columns }) => {
    return (
        <Dialog
            fullWidth
            maxWidth={"md"}
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <Button
                edge="start"
                color="inherit"
                onClick={() => setOpenModal(false)}
                aria-label="close"
                style={{ position: "absolute", top: "10px", right: "10px" }}>
                <Close />
            </Button>
            <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{title}</DialogTitle>
            {formtype === 'list' && <TaskModalContentList list={list}  />}
            {formtype === 'data-grid' && <TaskModalDataGridList list={list} columns={columns} />}
            {formtype === 'form-data' && <TaskModalContentForm form={formDataJson} buttons={formButton} />}
        </Dialog>
    )
}

export const TaskModalContentList = ({ list }) => {
    return (
        <DialogContent>
            {list && list.map((e, i) => {
                return (
                    <div key={i}>
                        <p>{e.epic_details && e.epic_details.rtms_key} - <description>{e.epic_details && e.epic_details.summary}</description></p>
                    </div>
                )
            })}
        </DialogContent>
    )
}


export const TaskModalContentForm = ({ form, buttons }) => {
    return (
        <DialogContent>
            <form>
                <div className="add-new-user-form">
                    {form.map((item, index) =>

                        item.fieldType === "InputSelectField" ?
                            <InputSelectField
                                label={item.label}
                                name={item.name}
                                disabled={item.disabled}
                                defaultValue={item.defaultValue}
                                optionList={item.optionList}
                                optionLevel={item.optionLevel}
                                optionValue={item.optionValue}
                                onChange={item.onChange}

                            />
                            :
                            item.fieldType === "InputFileFields" &&
                            <InputFileFields
                                label={item.label}
                                name={item.name}
                                type={item.type}
                                placeholder={item.placeholder}
                                onChange={item.onChange}
                                formErr={item.fileErr}
                            />
                    )}
                </div>
                {buttons.map((item, index) =>
                    item.fieldType === "InputButton" &&
                    <InputButton onSubmit={item.onSubmit} label={item.label} />
                )}
            </form>
        </DialogContent>
    )
}

export const TaskModalDataGridList = ({ list, columns }) => {
    return (
        <DialogContent>
            <DataGrid
                className="datagrid"
                rows={list}
                columns={columns}
                style={{ fontSize: 16 }}
            //onRowClick={(row)=>{navigate(`edit/${row.id}`)}}
            //onCellClick={(cell) => { cell.field !== "action" && navigate(`edit/${cell.id}`) }}
            />
        </DialogContent>
    )
}