import React, { useState, useEffect, useRef } from 'react'
import "./forgotpassword.scss";
import Logo from "../../images/logo.png"
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import dataService from '../../redux/services/data.service';
import LoaderForm from '../../components/common-function/LoaderForm';
const { REACT_APP_ENV } = process.env;

const ForgotPassword = () => {

  const navigate = useNavigate('')
  const formRef = useRef();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false)

  const validate = (email) => {
    const errors = {}
    if (!email) {
      errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors.email = 'Invalid email address'
    } else {
      errors.email = true
    }

    return errors
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") {
      toast.error("Email is required")
    }
    
    if (email !== "") {
      setLoading(true)
      const isValidate = validate(email)
      if (isValidate.email === true) {
        const url = window.location.origin + "/reset-password"
        const request = { "email": email, "reset_link": url }
        const response = await dataService.forgotPassword(request)
        if (response && response.data && response.data.status === 200) {
          // console.log(response.data)
          toast.success(response.data.message)
          formRef.current.reset();
          setLoading(false)
        }

        //api end error response
        if (response && response.data && response.data.status === 400) {
          toast.error(response.data.message)
          setLoading(false)
        }

      } else {
        setLoading(false)
        return toast.error(isValidate.email)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    const user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
    if (user && user.token) {
      navigate('/');
    }
  }, [])

  return (
    <div className="signin">

      <div className="sign-in-row">

        <div className="signin-col-1">
          <img src={Logo} alt={Logo} className="left-logo-icon" />
        </div>

        <div className="signin-col-2">
          <img src={Logo} alt={Logo} />

          <h4>Forgot Password</h4>
          <ToastContainer />

          <form onSubmit={handleSubmit} ref={formRef}>
            {loading && <LoaderForm />}

            <div className="formInput">
              <label >
                Registered Email Address
              </label>
              <input
                type="text"
                placeholder="Email Address"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <p className="text-right"><Link to={"/"} className="forgot-password-link">Back</Link></p>
            <button type="submit" className="btn btn-submit" disabled={loading}>Submit</button>
          </form>

        </div>

      </div>
    </div>
  )
}

export default ForgotPassword
