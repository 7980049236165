import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// import { useSelector } from 'react-redux';

import { history } from './history';

export { PrivateRoute };

// function PrivateRoute({ children }) {
//    // const { data: authUser } = useSelector(state => state.AuthUser);
//    const authUser = localStorage.getItem('rtm_user')  ? 'access' : '';

//     if (!authUser) {
//         // not logged in so redirect to login page with the return url
//         return <Navigate to="/" state={{ from: history.location }} />
//     }

//     // authorized so return child components
//     return children;
// }
const { REACT_APP_ENV } = process.env;

// function PrivateRoute({ children }) {
//   const auth = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? true : false;
//   return auth ? children : <Navigate to="/" state={{ from: history.location }} />;
// }


function PrivateRoute({ children, path }) {

  const location = useLocation()
  const [menuData, setMenuData] = useState([])

  const localStorageVar = localStorage.getItem('menuData');
  const localMenuData = localStorageVar && JSON.parse(localStorageVar);

  if (menuData && menuData.length === 0) {
    if (localMenuData && localMenuData.data && localMenuData.data.rows) {
      setMenuData(localMenuData?.data?.rows)
      // console.log(localMenuData?.data?.rows,"menuData")
    }
  }

  const auth = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? true : false;
  // return auth ? children : <Navigate to="/" state={{ from: history.location }} />;

  return auth ? location.pathname === '/dashboard' ? children
    : menuData && menuData.find(ele => ele.menu === path)
      ? children
      : <Navigate replace to={"/access-denied"} />
    : <Navigate to="/" state={{ from: history.location }} />;
}