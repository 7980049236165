import { Close } from '@mui/icons-material'
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import DatePicker from 'react-datepicker';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { createZoomMeeting, getZoomAccountList } from '../../redux/features/zoomSlice';
import moment from 'moment';
import { toast } from 'react-toastify';

const formErrData = Object.freeze({
    agendaErr: 'Agenda is required',
    durationErr: "Duration always positive numeric number is requird (exp:10)",
    startTimeErr: "Start Time is required",
    topicErr: "Topic is required",
    timeZoneErr: "TimeZone is requird",
    zoomAccountIdErr: "Please select zoom account list"
});

export const ZoomCreate = ({ setShowZoomModel, title, story_id }) => {

    const initialFormData = Object.freeze({
        agenda: "",
        duration: "",
        password: "",
        start_time: "",
        topic: "",
        story_id: story_id,
        timezone: "Asia/Kolkata"
    });

    const dispatch = useDispatch();
    const { zoomAccountList, create_zoom_meeting, } = useSelector(state => state.zoomSlice)
    const [open, setOpen] = useState(true);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [validateErr, setValidateErr] = useState("")
    const [errStatus, setErrStatus] = useState(false);
    const [reload, setReload] = useState(false)
    const [zoom_account_id, setZoomAccountId] = useState("")
    const [zoomData, setZoomData] = useState([])
    const [start_time, setStartTime] = useState('')
    const [html_zoom_details, setHtmlZoomDetails] = useState('')
    const [showView, setShowView] = useState(false)

    useEffect(() => {
        dispatch(getZoomAccountList(`"no_limit":"True"`));
    }, [])

    const validate = (duration) => {
        const errors = {}
        if (!duration) {
            errors.duration = 'Required'
        } else if (!/^[0-9]*$/i.test(duration)) {
            errors.duration = 'Invalid Duration'
        } else {
            errors.duration = true
        }
        return errors
    }

    const handleClose = () => {
        setOpen(false);
        setFormValues(initialFormData);
        setShowZoomModel(false);
        setShowView(false);
        setHtmlZoomDetails("")
    }
    const zoomListChange = (evt) => {
        if (evt) {
            const { value } = evt.target || evt;
            setZoomAccountId(value)
            const filterZoomData = zoomAccountList && zoomAccountList.data && zoomAccountList.data.rows.find((ele) => ele.id === value)
            setZoomData(filterZoomData)
        } else {
            setZoomAccountId("")
            setFormValues(initialFormData);
        }
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setFormValues({ ...formValues, [name]: value });
    }

    if (reload === "submit" && create_zoom_meeting && create_zoom_meeting.message) {
        setReload(false)
        if (create_zoom_meeting.status === 200) {
            toast.success(create_zoom_meeting.message)
            // handleClose()
            if (create_zoom_meeting && create_zoom_meeting.data) {
                setShowView(true)
                setHtmlZoomDetails(create_zoom_meeting.data.html_zoom_details)
            }
        } else {
            toast.error(create_zoom_meeting.message)
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (formValues.agenda === "" || formValues.duration <= 0 || start_time === "" || formValues.topic === "" || zoom_account_id === "") {
            setErrStatus(true)
        }
        if (formValues.agenda !== "" && formValues.duration > 0 && start_time !== "" && formValues.topic !== "" && zoom_account_id !== "") {
            const isValidate = validate(formValues.duration)
            if (isValidate.duration === true) {
                console.log(isValidate, "correct")

                let date = moment(start_time).format('YYYY-MM-DD');
                let time = moment(start_time).format('HH:mm:SS');
                const formatted_start_time = date + 'T' + time + 'Z';
                let addvalues = { ...formValues, start_time: formatted_start_time }
                setErrStatus(false)
                setValidateErr("")
                const ID = zoomData && zoomData.id
                dispatch(createZoomMeeting({ ID, addvalues }))
                setReload("submit")

            } else {
                console.log(isValidate.duration, "vavvavavv")
                setErrStatus(true)
                setValidateErr(isValidate.duration)
            }
        }
    }

    return (
        <div>
            <Dialog
                hideBackdrop
                fullWidth
                maxWidth={"lg"}
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                sx={{ '& .MuiDialog-paper': { minHeight: "600px" } }}
            >
                <Button
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    style={{ position: "absolute", top: "10px", right: "10px" }}>
                    <Close />
                </Button>
                <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>Create Zoom ({title})</DialogTitle>

                <DialogContent>
                    {
                        !showView ? <form>
                            <div className="add-new-user-form">
                                <div className="formInput">
                                    <label >
                                        Zoom Account List
                                    </label>
                                    <Select
                                        className="task-dropdown"
                                        classNamePrefix="select"
                                        placeholder="Select Zoom Acount list"
                                        isSearchable={true}
                                        isClearable
                                        options={
                                            zoomAccountList && zoomAccountList.data && zoomAccountList.data.rows.map((option) => {
                                                return { label: option.account_name, value: option.id, name: "zoom_account_id" }
                                            })
                                        }
                                        onChange={zoomListChange}
                                    />
                                    {errStatus === true && formValues && zoom_account_id === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.zoomAccountIdErr}</p>}
                                </div>

                                <div className="formInput">
                                    <label >
                                        Agenda
                                    </label>
                                    <input
                                        name="agenda"
                                        defaultValue={formValues.agenda}
                                        type="text"
                                        placeholder="Agenda"
                                        onChange={handleChange}
                                    />
                                    {errStatus === true && formValues && formValues.agenda === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.agendaErr}</p>}
                                </div>

                                <div className="formInput">
                                    <label >
                                        Duration (in min)
                                    </label>
                                    <input
                                        name="duration"
                                        defaultValue={formValues.duration}
                                        type="number"
                                        min="1"
                                        placeholder="Duration (in min)"
                                        onChange={handleChange}
                                        pattern="[1-9]*"
                                        nputmode="numeric"
                                    />
                                    {errStatus === true && formValues && formValues.duration <= 0 && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.durationErr}</p>}
                                    {errStatus === true && validateErr && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr}</p>}
                                </div>

                                <div className="formInput">
                                    <label >
                                        Start Time
                                    </label>
                                    <DatePicker
                                        minDate={moment().toDate()}
                                        name="start_time"
                                        onChange={(time) => setStartTime(time)}
                                        dropdownMode="select"
                                        timeInputLabel="Time:"
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        showTimeInput
                                        selected={start_time}
                                        peekNextMonth
                                        placeholderText='Start Time'
                                    />
                                    {errStatus === true && formValues && start_time === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.startTimeErr}</p>}
                                </div>

                                <div className="formInput" style={{ flexBasis: "100%" }}>
                                    <label >
                                        Topic
                                    </label>
                                    <input
                                        name="topic"
                                        defaultValue={formValues.topic}
                                        type="text"
                                        placeholder="Topic"
                                        onChange={handleChange}
                                    />
                                    {errStatus === true && formValues && formValues.topic === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.topicErr}</p>}
                                </div>

                                <div className="formInput" style={{ flexBasis: "100%" }}>
                                    <button className="btn btn-submit" style={{ width: "200px" }} onClick={onSubmit}>Create Zoom Meeting</button>
                                </div>
                            </div>
                        </form>
                            : <div>
                                <p style={{ marginBottom: 20 }} > <span dangerouslySetInnerHTML={{ __html: html_zoom_details ? html_zoom_details : "" }} /></p>
                                {/* {html_zoom_details ? html_zoom_details : ""} */}
                            </div>
                    }
                </DialogContent>
            </Dialog>
        </div >
    )
}
