import './emailTemplate.scss';
import { AttachFile, Close } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material'
import { CKEditor } from 'ckeditor4-react';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { getCategoryListEmail, getEmailTemplateMaster, sendEmail, updateSubject, updateBodyData } from '../../redux/features/emailSlice';
import Select from "react-select";
import LocalStorageDataService from "../common-function/GetLocalstorage";
import LoaderForm from '../common-function/LoaderForm';
import { STATUSES } from '../../redux/common-status/constant';
import { ZoomCreate } from '../zoom-create/ZoomCreate';
const { REACT_APP_ENV, REACT_APP_COMPANY_HR_MAIL, REACT_APP_COMPANY_NAME } = process.env;

const formErrData = Object.freeze({
    toErr: 'To is required',
    // ccErr: 'CC is required',
    subjectErr: "Subject is required",
    bodyErr: "Body is required",
});

const EmailTemplet = ({ setShowEmailModel, to_email, project_id, data, story_id }) => {
    const loginUserName = LocalStorageDataService.userDetail().name;

    const initialFormData = Object.freeze({
        mail_type: "html",
        // to: to_email || [],
        to: [to_email] || [],
        cc: [""],
        subject: "",
        body: "",
        // file: [],
    });

    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { status, error, send_email, categoryListEmail, emailTemplateMaster, subject, body } = useSelector(state => state.emailSlice)
    const [reload, setReload] = useState(false)
    const [validateErr, setValidateErr] = useState("")
    const [bodyData, setBodyData] = useState("")

    const [showSubmitBtn, setShowSubmitBtn] = useState(true)
    const [emailTemplatesId, setEmailTemplatesId] = useState(0)
    const [categoryId, setCategoryId] = useState(0)
    const [subjectData, setSubjectData] = useState("")

    const [showZoomModel, setShowZoomModel] = useState(false)
    // file uploading states
    const MAX_COUNT = 10;
    const uploadInputRef = useRef(null);
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [fileLimit, setFileLimit] = useState(false);
    const [fileErr, setFileErr] = useState("")


    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        let limitExceeded = false;
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                if (uploaded.length === MAX_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_COUNT) {
                    setErrStatus(true)
                    setFileErr(`You can only add a maximum of ${MAX_COUNT} files`)
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) setUploadedFiles(uploaded)
    }

    const onFileChange = (event) => {
        const chosenFiles = Array.prototype.slice.call(event.target.files)
        handleUploadFiles(chosenFiles);
    }

    function deleteFile(e) {
        const s = uploadedFiles.filter((item, index) => index !== e);
        setUploadedFiles(s);
    }

    useEffect(() => {
        dispatch(getCategoryListEmail(project_id));
    }, [])

    useEffect(() => {
        setSubjectData(subject)
        // setBodyData(body)
        setBodyData((prevState) => {
            return body;
        });

    }, [emailTemplatesId])

    const handleClose = () => {
        setOpen(false);
        setFormValues(initialFormData);
        setBodyData("")
        setSubjectData("")
        setShowEmailModel(false)
    }

    if (reload === "submit" && send_email && send_email.message) {
        setReload(false)
        if (send_email.status === 200) {
            toast.success(send_email.message)
            handleClose()
        } else {
            toast.error(send_email.message)
        }
        setShowSubmitBtn(true)
    }

    const replaceBodyFunction = (body, array) => {
        let replacedBodyData = body;
        array.forEach((ele) => {
            replacedBodyData = replacedBodyData.replaceAll(ele.name, ele.var_name)
        })
        dispatch(updateBodyData(replacedBodyData))

        setBodyData((prevState) => {

            return replacedBodyData;
        });
        // setBodyData(replacedBodyData)
        setReload(false)
    }

    const categoryChange = (evt) => {
        if (evt) {
            const { value } = evt.target || evt;
            setCategoryId(value)
            dispatch(getEmailTemplateMaster(value))
        } else {
            setCategoryId(0)
            setEmailTemplatesId(0)
            setFormValues(initialFormData);
            dispatch(updateBodyData(""))
            dispatch(updateSubject(""))
        }
    }

    const changeEmailTempalte = (evt) => {
        setReload(true)
        if (evt) {
            const { value } = evt.target || evt;
            setEmailTemplatesId(value)
            const filterEmailTemplate = emailTemplateMaster && emailTemplateMaster.data && emailTemplateMaster.data.rows.filter((ele) => ele.id === value)
            const candidate_name = data.candidate_info.map((ele) => {
                return (ele.candidate_name)
            })

            replaceBodyFunction(filterEmailTemplate[0].body, [
                { name: '##CANDIDATE_NAME##', var_name: candidate_name[0] },
                { name: '##JD_TITLE##', var_name: filterEmailTemplate[0].subject },
                { name: '##RESOURCE_NAME##', var_name: loginUserName },
                { name: '##COMPANY_NAME##', var_name: REACT_APP_COMPANY_NAME },
                { name: '##COMPANY_HR_MAIL##', var_name: REACT_APP_COMPANY_HR_MAIL },
                { name: '##INETRVIEW_DATE##', var_name: "INETRVIEW_DATE", },
                { name: '##INETRVIEW_TIME##', var_name: "INETRVIEW_TIME", },
                { name: '##INETRVIEW_ZOOM_LINK##', var_name: "INETRVIEW_ZOOM_LINK" }
            ])
            dispatch(updateSubject(filterEmailTemplate[0].subject))
        } else {
            setEmailTemplatesId(0)
            setFormValues(initialFormData);
            dispatch(updateBodyData(""))
            dispatch(updateSubject(""))
        }
    }

    const inputHandler = (evt) => {
        var newContent = evt.editor.getData();
        setBodyData(newContent)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        if (name === "cc" || name === "to") {
            var array = value.split(",");
            setFormValues({ ...formValues, [name]: array });
        } else {
            setFormValues({ ...formValues, [name]: value });
            setSubjectData(value)
        }
    }

    // const validate = (to, cc) => {
    //     const errors = {}
    //     if (!to) {
    //         errors.to = 'Required'
    //     }
    //     // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(to)) {
    //     //     errors.to = 'Invalid email address'
    //     // }
    //     else {
    //         errors.to = true
    //     }

    //     return errors
    // }
    // console.log(formValues.cc.length, "statusss")

    const onSubmit = (e) => {
        e.preventDefault()
        if (formValues.to === "" || formValues.to[0] === "" || subjectData === "" || bodyData === "") {
            setErrStatus(true)
        }
        if (formValues.to !== "" && subjectData !== "" && bodyData !== "") {
            // const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            // let userId = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id
            let addvalues = { ...formValues, body: bodyData, subject: subjectData }

            const formData = new FormData();
            uploadedFiles.forEach((file, i) => {
                formData.append(`file`, file, file.name);
            });

            // formData.append("file", JSON.stringify(uploadedFiles));
            formData.append("data", JSON.stringify(addvalues));
            setErrStatus(false)
            setValidateErr("")
            setShowSubmitBtn(false)
            dispatch(sendEmail(formData))
            setReload("submit")
        }
    }

    if (reload === "submit" && status === STATUSES.FAILURE && error && error.message) {
        setReload(false)
        toast.error(error.message);
    }
    return (
        <div>
            <Dialog
                hideBackdrop
                fullWidth
                maxWidth={"xl"}
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                sx={{ '& .MuiDialog-paper': { minHeight: "400px" } }}
            >
                <Button
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    style={{ position: "absolute", top: "10px", right: "10px" }}>
                    <Close />
                </Button>
                <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "10px" }}>Mail ({data && data.summary})

                    <Tooltip title="Create Zoom Meeting">
                        <button onClick={() => setShowZoomModel(true)} className="btn-add-zoom">Create Zoom Meeting</button>
                    </Tooltip>
                    {showZoomModel && <ZoomCreate title={data && data.summary} setShowZoomModel={setShowZoomModel} story_id={story_id} />
                    }

                </DialogTitle>
                {(reload === "submit") && <LoaderForm />}
                <DialogContent>
                    <form>
                        <div className="add-new-user-form">

                            {/* <div className="formInput">
                                <label >
                                    Category
                                </label>
                                <Select
                                    className="task-dropdown"
                                    classNamePrefix="select"
                                    placeholder="Select Category"
                                    isSearchable={true}
                                    isClearable
                                    options={
                                        categoryListEmail && categoryListEmail.data && categoryListEmail.data.template_masters.map((option) => {
                                            return { label: option.template_type_name, value: option.id, name: "category" }
                                        })
                                    }
                                    onChange={categoryChange}
                                />
                            </div> */}
                            {/* {
                                categoryId > 0 &&
                                <div className="formInput">
                                    <label >
                                        Email Template
                                    </label>
                                    <Select
                                        className="task-dropdown"
                                        classNamePrefix="select"
                                        placeholder="Select Template"
                                        isSearchable={true}
                                        isClearable
                                        options={
                                            emailTemplateMaster && emailTemplateMaster.data && emailTemplateMaster.data.rows.map((option) => {
                                                return { label: option.template_key, value: option.id, name: "email_templates" }
                                            })
                                        }
                                        onChange={changeEmailTempalte}
                                    />
                                </div>
                            } */}

                        </div>
                        {/* {
                            emailTemplatesId > 0 &&
                            <> */}
                        <div className="add-new-user-form">
                            <div className="formInput">
                                <label >
                                    To
                                </label>
                                <input
                                    name="to"
                                    defaultValue={to_email}
                                    type="text"
                                    placeholder="To"
                                    onChange={handleChange}
                                />
                                {errStatus === true && formValues && (formValues.to === "" || formValues.to[0] === "") && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.toErr}</p>}
                                {errStatus === true && validateErr && validateErr.to !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.to}</p>}

                            </div>
                            <div className="formInput">
                                <label >
                                    CC
                                </label>
                                <input
                                    name="cc"
                                    defaultValue={formValues.cc}
                                    type="text"
                                    placeholder="CC"
                                    onChange={handleChange}
                                />
                                {/* {errStatus === true && formValues && (formValues.cc === "" || formValues.cc.length === 0) && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.ccErr}</p>} */}
                                {/* {errStatus === true && validateErr && validateErr.cc !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.cc}</p>} */}
                            </div>
                        </div>
                        <div className="add-new-user-form">
                            <div className="formInput" style={{ flexBasis: "100%" }}>
                                <label >
                                    Subject
                                </label>
                                <input
                                    name="subject"
                                    defaultValue={subjectData}
                                    type="text"
                                    placeholder="Subject"
                                    onChange={handleChange}
                                />
                                {errStatus === true && formValues && subjectData === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.subjectErr}</p>}
                            </div>
                        </div>
                        <div className="add-new-user-form">
                            <div className="formInputDescription">
                                <label >
                                    Body
                                </label>

                                <CKEditor
                                    name={"body"}
                                    initData={bodyData}
                                    onChange={(e) => inputHandler(e)}
                                    config={{
                                        removeButtons: 'Superscript,Subscript,Cut,Copy,Paste,Anchor,Paste as plain text,Table,Unlink,Paste from Word',
                                    }}
                                />
                                {errStatus === true && formValues && bodyData === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.bodyErr}</p>}
                            </div>
                            <div className='add-new-user-form'>

                                <div className="uploaded-files-list">
                                    {uploadedFiles.length > 0 && uploadedFiles.map((file, i) => {
                                        return <div key={i}>
                                            <ul>
                                                <li>
                                                    {file.name}
                                                    <button onClick={() => deleteFile(i)}>
                                                        <Close />
                                                    </button>
                                                </li>
                                            </ul>

                                        </div>
                                    })
                                    }
                                </div>

                                <div className="file-attchment-icon-btn">
                                    <input
                                        id='fileUpload'
                                        ref={uploadInputRef}
                                        type='file'
                                        multiple
                                        accept='application/pdf, image/png,image/jpeg,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                        style={{ display: "none" }}
                                        onChange={onFileChange}
                                        name="file"
                                    />

                                    <Tooltip title="Attach File">
                                        <span>
                                            <AttachFile
                                                className={`attach_con`}
                                                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                                            />
                                        </span>
                                    </Tooltip>
                                </div>
                                {errStatus === true && fileErr && <p style={{ color: "#96332c", fontSize: 13 }}>{fileErr}</p>}
                            </div>
                        </div>
                        {
                            showSubmitBtn && <DialogActions>
                                <button className="submit-modal" onClick={onSubmit}>Send</button>
                            </DialogActions>
                        }
                        {/* </>
                        } */}
                    </form>
                </DialogContent>
            </Dialog>
        </div >
    )
}

export default EmailTemplet