import Select from 'react-select';
import Multiselect from 'multiselect-react-dropdown';
import { Radio, FormControl, FormControlLabel, RadioGroup, FormLabel, MenuItem } from '@mui/material';
import { CKEditor } from 'ckeditor4-react';


export const InputFields = ({ name, placeholder, onChange, defaultValue, disabled, type, label, formErr, errStatus }) => {
  return (
    <div className="formInput">
      <label >
        {label}
      </label>
      <input
        name={name}
        defaultValue={defaultValue}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      />
      {errStatus === true && defaultValue === "" && <InputErrorTag error={formErr} />}

    </div>
  )
}


export const InputSelectField = ({ name, placeholder, onChange, defaultValue, disabled, type, label, formErr, errStatus, optionList, optionLevel, optionValue }) => {
  return (
    <div className="formInput">
      <label >
        {label}
      </label>
      <Select
        disabled={disabled ? disabled : false}
        className="basic-single"
        classNamePrefix="select"
        placeholder={placeholder}
        defaultValue={defaultValue}
        isSearchable={true}
        name={name}
        onChange={onChange}
        options={
          optionList.length > 0 && optionList.map((option) => {
            return { label: optionLevel[0] === "option" ? option : optionLevel[1] ? option[optionLevel[0]] + "(" + option[optionLevel[1]] + ")" : option[optionLevel[0]], value: optionValue === "option" ? option : option[optionValue], name: name }
          })
        }
      />
      {errStatus === true && defaultValue === "" && <InputErrorTag error={formErr} />}

    </div>
  )
}

export const InputSelectValueField = ({ name, placeholder, onChange, defaultValue, disabled, type, label, formErr, errStatus, formValues, optionList, optionLevel, optionValue }) => {
  return (
    <div className="formInput">
      <label >
        {label}
      </label>
      <Select
        className="basic-single"
        classNamePrefix="select"
        placeholder={placeholder}
        value={defaultValue}
        isSearchable={true}
        name={name}
        onChange={onChange}
        options={
          optionList.length > 0 && optionList.map((option) => {
            return { label: optionLevel[0] === "option" ? option : optionLevel[1] ? option[optionLevel[0]] + "(" + option[optionLevel[1]] + ")" : option[optionLevel[0]], value: optionValue === "option" ? option : option[optionValue], name: name }
          })
        }
      />
      {errStatus === true && defaultValue === "" && <InputErrorTag error={formErr} />}

    </div>
  )
}

export const InputTextareaField = ({ rows, name, placeholder, onChange, defaultValue, disabled, label, formErr, errStatus, formValues }) => {
  return (

    <div className="formInput" style={{ flexBasis: '100%' }}>
      <label >
        {label}
      </label>

      <textarea defaultValue={defaultValue} name={name} onChange={onChange} rows={rows} placeholder={placeholder}></textarea>

      {errStatus === true && defaultValue === "" && <InputErrorTag error={formErr} />}
    </div>
  )
}

export const InputMultiSelectField = ({ divstyle, name, isObject, onRemove, onSelect, defaultValue, disabled, label, formErr, errStatus, optionList, optionLevel, optionValue }) => {
  return (
    <div className="formInput" style={divstyle ? divstyle : {}}>
      <label >
        {label}
      </label>
      <Multiselect
        isObject={true}
        onRemove={onRemove}
        onSelect={onSelect}
        displayValue={optionLevel}
        selectedValues={defaultValue}
        options={optionList}
      />
      {errStatus === true && defaultValue === "" && <InputErrorTag error={formErr} />}
    </div>
  )
}

export const InputFileFields = ({ name, placeholder, onChange, disabled, type, label, formErr, errStatus }) => {
  return (
    <div className="formInput">
      <label >
        {label}
      </label>
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      />
      {formErr === "" && <InputErrorTag error={formErr} />}

    </div>
  )
}

export const InputRadioGroupFields = ({ defaultValue, name, placeholder, onChange, disabled, type, label, formErr, errStatus }) => {
  return (
    <div className="formInput">
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name={name}
          value={defaultValue}
          onChange={onChange}
        >
          <FormControlLabel value={true} control={<Radio />} label="Active" />
          <FormControlLabel value={false} control={<Radio />} label="Inactive" />
        </RadioGroup>
        {errStatus === true && defaultValue === "" && <InputErrorTag error={formErr} />}
      </FormControl>
    </div>
  )
}

export const InputEditorField = ({ label, name, defaultValue, onChange, errStatus, formErr }) => {
  return (
    <div className="formInput" style={{ flexBasis: '100%' }}>
      <label >
        {label}
      </label>
      <CKEditor
        name={name}
        initData={defaultValue}
        onChange={(e) => onChange(e)}
      />
      {errStatus === true && defaultValue === "" && <InputErrorTag error={formErr} />}

    </div>
  )
}

export const InputButton = ({ onSubmit, label }) => {
  return (
    <button onClick={onSubmit} className="btn btn-submit">{label}</button>
  )
}

export const InputErrorTag = ({ error }) => {
  return (
    <p style={{ color: "#96332c", fontSize: 10 }}>{error}</p>
  )
}

export const ViewField = ({ fieldType, value, type, optionLevel, label,length }) => {
  return (
    <div className="table-row-regular">
      <div className="table-row-cell-regular"><p><span style={{ fontWeight: "bold" }}>{label}</span></p></div>
      <div className="table-row-cell-regular">
        {type === 1 ?

          <p style={{ textTransform: 'capitalize' }}>{fieldType === 'editor' ? <span dangerouslySetInnerHTML={{ __html: value && value.label ? value.label : value }} /> : value && value.label ? value.label : (value === true || value === 'true') ? 'Active' : (value === false || value === 'false') ?'Inactive': value}</p>
          :
           type ===  3 ?
            <p style={{ textTransform: 'capitalize' }}>{value.map((e, index) => `${index ? '-' : ''}${e.value ? e.value : e}`)}</p>
            :
            value && value.map((item, index) => {
              return (
                <p key={index} style={{ textTransform: 'capitalize' }}>
                  {
                  length == 3 ? item[optionLevel[0]] + "(" + item[optionLevel[1]] + "-" + item[optionLevel[2]] + ")"  :
                  length === 2 ? item[optionLevel[0]] + "-" + item[optionLevel[1]] :
                   item[optionLevel[0]]
                }
                </p>
              )
            })
        }
      </div>
    </div>
  )
}

