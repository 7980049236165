import React, { useEffect, useState } from "react";
import "./manageCredential.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import dataService from "../../../redux/services/data.service";
import { ToastContainer, toast } from 'react-toastify';
import LoaderForm from "../../../components/common-function/LoaderForm";
import LocalStorageDataService from "../../../components/common-function/GetLocalstorage";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function AddCredentialHead({ inputs, title }) {

    const { id } = useParams()
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false)

    const [formData, setFormData] = useState({
        head_name: "",
        description: "",
        status: "false"
    })
    const [formErrors, setFormErrors] = useState('');

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setFormData({ ...formData, [name]: value });
    }

    const loadDataById = async () => {
        setShowLoader(true)

        const response = await dataService.credentialHeadById(id).catch(error => {
            setShowLoader(false)
            return error;
        });
        if (response && response.data && response.data.status === 200) {
            setFormData(response.data.data)
            setShowLoader(false)
        } else {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        id && loadDataById()
    }, [id])


    const onSubmit = async (e) => {
        e.preventDefault();
        const request = { ...formData, created_by: LocalStorageDataService.userDetail().id, };

        if (!formData.head_name) {
            return setFormErrors({ 'nameErr': 'Head Name is Required' })
        } else if (!formData.description) {
            return setFormErrors({ 'descErr': 'Description is Required' })
        } else if (formData.status === "") {
            return setFormErrors({ 'statusErr': 'Status is Required' })
        }
        setShowLoader(true)
        const response = !id ? await dataService.addCredentialHead(request) : await dataService.updateCredentialHead(id, request);

        if (response && response.data && response.data.data) {
            toast.success(response.data.message);
            setShowLoader(false)
            setTimeout(() => {
                // dispatch(getCredentialHead(`{"skip":0, "limit":10}`))
                navigate('/all-credentials-head')
            }, 800)
        }
        if (response && response.data.status === 400) {
            setShowLoader(false)
            const res = response.data.message
            toast.error(res)
            setFormErrors({
                "nameErr": res.head_name,
                "descErr": res.description,
                "statusErr": res.status,
            })
        }
        setShowLoader(false)
    }

    return (
        <div className="new">
            <Sidebar />

            <div className="newContainer">
                <Navbar />

                <div className="top-headings">
                    <h3>{title} </h3>
                 
                    <Tooltip title="Back">
                        <Link to={"/all-credentials-head"} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>
                </div>

                {
                    showLoader ? <LoaderForm /> :
                        <form onSubmit={onSubmit}>

                            <ToastContainer />
                            <div className="add-new-user-form">

                                {inputs.map((input) => (
                                    <div className="formInput" key={input.id}>
                                        <label>{input.label}</label>
                                        <input
                                            name={input.name}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            value={formData[input.name]}
                                            onChange={(e) => handleChange(e)}
                                        />

                                        <div className="errorMessage">{
                                            formErrors && input.name === "head_name" ?
                                                <p>{formData.head_name === "" ? formErrors.nameErr : ""}</p>
                                                : input.name === "description" ?
                                                    <p>{formData.description === "" ? formErrors.descErr : ""}</p>
                                                    : ""
                                        }
                                        </div>
                                    </div>
                                ))}

                                <div className="formInput">
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="status"
                                            value={formData.status}
                                            onChange={(e) => { handleChange(e) }}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                        {formErrors && formErrors.statusErr && <p style={{ color: "#e33417", fontSize: 14 }}>{formData.status === "" ? formErrors.statusErr : ""}</p>}

                                    </FormControl>
                                </div>

                            </div>
                            <button className="btn btn-submit">Submit</button>

                        </form>
                }


            </div >

        </div >
    )
}
export default AddCredentialHead